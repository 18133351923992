import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../common/scss/pages/home.scss";
import "../common/scss/pages/request.scss";
import Header from "../common/header";
import { useParams } from "react-router-dom";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import PostCardSeller from "./Post-card-seller";
import PostCardBuyer from "./post-card-buyer";
import PostCardSocial from "./post-card-social";
import Auth from "../libs/auth";

const PostDetail = () => {
  const user = Auth.getCurrentUser();

  const [product, setProduct] = useState({});
  const [userRole, setUserRole] = useState({});
  const [loading, setLoading] = useState(false);
  const [loggedUserDetails, setLoggedUserDetails] = useState(null);
  // const settingsPost = {
  //   arrows: true,
  //   dots: false,
  //   infinite: false,
  //   autoplay: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const params = useParams();
  const postId = params?.id;
  const postType = params?.type;

  const getPost = async () => {
    try {
      setLoading(true);
      if (postType === "social_post") {
        await get(`/social_post/details/${postId}`, getAuthConfig()).then(
          (res) => {
            setLoading(false);
            setProduct(res.data.data);
          }
        );
      } else {
        await get(`/product/detail/${postId}`, getAuthConfig()).then((res) => {
          setLoading(false);
          // Merge product, interactions, and credit_policy
          const mergedProduct = {
            ...res.data.data.product, // Assuming product is always present
            interactions: res.data.data.interactions,
            credit_policy: res.data.data.credit_policy,
          };

          setProduct(mergedProduct);
          // setProduct(res.data.data);
          setUserRole(res.data.data.product);
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error?.response?.data || "some error occurred");

      setLoading(false);
    }
  };

  UseEffectOnce(() => {
    getPost();
  });

  const decapitalize = ([first, ...rest], upperRest = false) => {
    const lowercaseString =
      first.toLowerCase() +
      (upperRest ? rest.join("").toUpperCase() : rest.join(""));
    return lowercaseString.replaceAll(" ", "");
  };

  const fetchAllLoggedUserDetails = async () => {
    if (user?.slug) {
      const deCapsSlug = decapitalize(user?.slug);

      await get(`/user/profile/details/${deCapsSlug}`, getAuthConfig())
        .then((res) => {
          setLoggedUserDetails(res?.data?.data);
        })
        .catch((err) => {});
    }
  };

  UseEffectOnce(() => {
    if (user?.slug) {
      fetchAllLoggedUserDetails();
    }
  });

  return (
    <>
      <Header home />

      <div className="grey-bg">
        <div className="container-fluid">
          <div className="layout-grid-box">
            <LeftSideBar loggedUserDetails={loggedUserDetails} />
            <div className="layout-grid">
              <div className="posts">
                <div className="posts-header"></div>

                <div className="posts-content collapse show" id="collapupdate">
                  {userRole.type === "post_to_sell" ? (
                    <PostCardSeller productdata={product} loading={loading} />
                  ) : userRole.type === "post_to_buy" ? (
                    <PostCardBuyer productdata={product} loading={loading} />
                  ) : (
                    <PostCardSocial productdata={product} loading={loading} />
                  )}
                </div>
              </div>
            </div>
            <RightSideBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default PostDetail;
