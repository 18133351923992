import { useEffect, useState } from "react";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { get, getAuthConfig, postwithOu } from "../libs/http-hydrate";
import Skeleton from "react-loading-skeleton";
import swal from "sweetalert";
import { ReactComponent as ConnectIcon } from "../assets/images/connect-2-new.svg";
import { ReactComponent as CaretDownIcon } from "../assets/images/caret-down.svg";
import BusiMeetAIComingSoon from "../assets/images/bizAI.png";
import { Link } from "react-router-dom";
import Auth from "../libs/auth";
import Modal from "react-bootstrap/Modal";
import { useRef } from "react";
import VerifyModal from "./verify-modal";

function RightSideBar(props) {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }

  const [isVisible, setIsVisible] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsVisible(window.innerWidth > 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [suggest, setSuggest] = useState({
    items: [],
    has_more: false,
  });
  const [sent, setSent] = useState({
    items: [],
  });
  const [loading, setLoading] = useState(false);

  const user = Auth.getCurrentUser();
  const gstRef = useRef();

  const [suggested_usersPage, setsuggested_usersPage] = useState(0);
  UseEffectOnce(() => {
    if (props?.Suggested != undefined) {
      setSuggest((p) => ({ ...p, items: props?.Suggested?.items }));
    } else {
      suggest_usersPages();
    }
  });
  useEffect(() => {
    if (suggested_usersPage === 0) {
    } else {
      suggest_usersPages();
    }
  }, [suggested_usersPage]);

  async function suggest_usersPages() {
    setLoading(true);
    await get(
      `/home/suggested_users?page=${suggested_usersPage + 1}`,
      getAuthConfig()
    ).then((response) => {
      setLoading(false);
      setSuggest((p) => ({
        ...p,
        items: suggest.items.concat(response?.data?.data?.items),
      }));
      setSuggest((p) => ({ ...p, has_more: response?.data?.data?.has_more }));
    });
    setLoading(false);
  }

  async function sentConnectionRequest(User_id) {
    const formdata = new FormData();
    formdata.append("user_id", User_id);
    await postwithOu("/connection/send", getAuthConfig(), formdata)
      .then((datta) => {
        if (datta.status === 200) {
          setSent((p) => ({
            ...p,
            items: sent.items.concat(User_id),
          }));
          // swal(
          //   "Success",
          //   "Your Connection Request Successfully Sent ",
          //   "success"
          // );
        }
      })
      .catch((e) => {
        if (e.response.status === 400) {
          swal("Warning", "Request Already sent to this user ", "warning");
        }
      });
  }

  return (
    <>
      <div className="layout-grid layout-grid--right">
        <div className="card">
          <div className="card-inner">
            <div className="card-header">
              <h6>Suggested Users</h6>
              {suggest.has_more === true || props?.Suggested?.count > 5 ? (
                <span
                  className="p-0 m-0 seemore-link"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    let p = suggested_usersPage + 1;
                    setsuggested_usersPage(p);
                  }}
                >
                  Show More <CaretDownIcon />
                </span>
              ) : (
                ""
              )}
            </div>
            <div
              className="pt-0"
              style={{ "max-height": "50vh", overflow: "auto" }}
            >
              <div className="card-lists">
                {loading ? (
                  <div>
                    <Skeleton count={3} width={220} height={67.16} />
                  </div>
                ) : (
                  suggest?.items &&
                  suggest?.items.length > 0 &&
                  suggest?.items.map((item, index) => {
                    return (
                      <>
                        <div className="card-lists-item user-profile">
                          <div className="user-profile-image">
                            <Link to={`/edit-profile-seller/${item?.slug}`}>
                              <img
                                src={item?.profile_image}
                                className="profile-pic"
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="user-profile-content">
                            <Link to={`/edit-profile-seller/${item?.slug}`}>
                              <div className="user-profile-name">
                                <h6>{item?.name} </h6>
                                <p>
                                  <img
                                    alt=""
                                    src={item?.company_details?.logo}
                                  />
                                  {item?.company_details?.name}
                                </p>
                              </div>
                            </Link>
                            {sent?.items.filter((word) => word === item?.id)
                              ?.length > 0 ? (
                              <button className="btn btn-success button-connect">
                                <ConnectIcon />
                                Sent
                              </button>
                            ) : (
                              <button
                                className="button button-primary button-connect"
                                style={{
                                  borderRadius: "5px",
                                  background: darkMode ? "#1C2226" : "#fff",
                                  color: darkMode ? "#fff" : "#000",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (
                                    user?.verified_at === null &&
                                    user.is_seller === 1
                                  ) {
                                    handleShowModal();
                                  } else {
                                    sentConnectionRequest(item?.id);
                                  }
                                }}
                              >
                                <ConnectIcon />
                                Connect
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>

        {isVisible && (
          <div
            style={{
              position: "fixed",
              bottom: "50px", // Distance from bottom
              right: "20px", // Distance from right
              zIndex: 1000, // Ensure it stays on top
            }}
          >
            <img
              src={BusiMeetAIComingSoon}
              style={{ width: "150px", height: "auto" }}
            />
          </div>
        )}
      </div>
      <VerifyModal
        gstRef={gstRef}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
      />
      {/* <Modal
        size="lg"
        show={showModal}
        onHide={handleCloseModal}
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          height: "fit-content",
        }}
      >
        <Modal.Body>
          <div className="">
            <div className="alert alert--highlight text-center">
              <p>
                For authenticate users we take the correct company details and
                payment details.
              </p>
            </div>
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="img-block">
                  <img alt="" src="/static/media/gst.723d5a8e7bfab714e36c.png"  />
                </div>

                <Link
                  ref={gstRef}
                  className="button button-primary"
                  to={"/settings"}
                  state={{ gst: "true", PreFilled: "true" }}
                >
                  Verify GST Number
                </Link>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="img-block">
                  <img alt="" src="/static/media/bank.3d3a35d49c4e1099a2c0.png" />
                </div>
                <Link
                  className="button button-primary"
                  to={"/settings"}
                  state={{ pay: "true", PreFilled: "true" }}
                >
                  Verify Bank Details
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default RightSideBar;
