import React, { useCallback, useEffect, useRef, useState } from "react";
import "../common/scss/pages/short-order.scss";
import Header from "../common/header";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import InCompleteIcon from "../assets/images/short-incomplete.svg";
import RejectedOfferIcon from "../assets/images/rejeted-offer.svg";
import DraftOfferIcon from "../assets/images/draft-offer.svg";
import { ReactComponent as LinkIcon } from "../assets/images/link-icon.svg";
import { ReactComponent as AcceptIcon } from "../assets/images/offer-accept.svg";
import { ReactComponent as RejectIcon } from "../assets/images/short-reject.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close-icon.svg";
import CompanyIconPlaceholder from "../assets/images/company-placeholder.svg";
import ProductImgPlaceholder from "../assets/images/product-image-placeholder.png";
import ShortOngoingIcon from "../assets/images/short-ongoing-icon.svg";
import ShortCompletedIcon from "../assets/images/short-comleted.svg";
import { Link, useLocation } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import moment from "moment";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import Auth from "../libs/auth";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import orderStatus from "../constants/orderConstants";
import { PuffLoader } from "react-spinners";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";

const sliderSettings = {
  arrows: true,
  dots: false,
  infinite: true,
  autoplay: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function Transaction() {
  const [orderListing, setOrderListing] = useState({
    has_more: false,
    items: [],
  });
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState("latest");
  const [filtering, setFiltering] = useState("all");
  const [typeFilter, setTypeFilter] = useState("");
  const [searching, setSearching] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImgs, setSelectedImgs] = useState([]);

  const handleClick = (action) => {
    setPage(0);
    setFiltering(action);
  };

  const handleClickFilter = (action) => {
    setTypeFilter(action);
  };

  const user = Auth.getCurrentUser();

  const shortOrderListing = async () => {
    setLoading(true);

    if (searching !== "") {
      setPage(0);
    }

    try {
      const response = await get(
        `/short_order/listing?page=${
          page + 1
        }&type=${filtering}&order_by=${sorting}&search=${
          searching || ""
        }&from=${typeFilter}`,
        getAuthConfig()
      );

      setLoading(false);

      if (page === 0) {
        setOrderListing((p) => ({
          ...p,
          items:
            response?.data?.data?.data.length > 0
              ? response?.data?.data?.data
              : [],
        }));
      } else {
        setOrderListing((p) => ({
          ...p,
          items:
            page === 0
              ? response?.data?.data?.data || []
              : orderListing.items.concat(response?.data?.data?.data || []),
        }));
      }

      setOrderListing((p) => ({
        ...p,
        has_more: response?.data?.data?.has_more || false,
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const handleOpenImgGallery = useCallback((products) => {
    let imgList = [];
    const thumbImage = products?.thumb_image;
    if (thumbImage) {
      const files = thumbImage?.file;
      if (typeof files === "string") {
        imgList.push(files);
      } else if (Array.isArray(files)) {
        imgList = files;
      }
    }
    setSelectedImgs(imgList);
  }, []);

  const handleCloseImgGallery = () => {
    setSelectedImgs([]);
  };

  const location = useLocation();

  const onTop = () => {
    window.scrollTo(0, 0);
  };
  UseEffectOnce(() => {
    onTop();
  }, [location]);

  const searchShortOrder = (e) => {
    setSearching(e.target.value);
  };

  useEffect(() => {
    shortOrderListing();
  }, [sorting, filtering, searching, typeFilter, page]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && orderListing?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [orderListing?.has_more]
  );

  const handleAcceptOrReject = async (action, id, index) => {
    const formData = new FormData();
    formData.append("type", action);
    formData.append("order_id", id);
    await post("/short_order/action_on_transaction", formData, getAuthConfig())
      .then((res) => {
        if (action === "reject") {
          toast.success("Offer Rejected Successfully ");
        }

        let arr = [];
        arr = orderListing?.items;
        arr[index].status = orderStatus.OFFER_REJECTED;

        setOrderListing((p) => ({ ...p, items: arr }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
    shortOrderListing();
  };

  useEffect(() => {
    if (
      location?.state?.filtering != undefined ||
      location?.state?.filtering != null
    ) {
      setTimeout(() => {
        setFiltering(location?.state?.filtering);
      }, 300);
    }
  }, [location?.state?.filtering]);

  const parentToChild = (slug) => {
    if (page === 0) {
      shortOrderListing();
    } else {
      setPage(0);
    }
  };

  const stepName = (item) => {
    let step;
    switch (item?.step?.current_step) {
      case "1":
        step = "Short Order";
        break;
      case "2":
        step = "Company Details";
        break;
      case "3":
        step = "Purchase Order";
        break;
      case "4":
        step = "Invoice";
        break;
      case "5":
        step = item?.payment_type === "advance" ? "Bank Details" : "E-way Bill";
        break;
      case "6":
        step =
          item?.payment_type === "advance" ? "Payment" : "Material Dispatch";
        break;
      case "7":
        step = item?.payment_type === "advance" ? "E-way Bill" : "Delivered";
        break;
      case "8":
        step =
          item?.payment_type === "advance"
            ? "Material Dispatch"
            : "Bank Details";
        break;
      case "9":
        step = item?.payment_type === "advance" ? "Delivered" : "Payment";
        break;
      case "10":
        step = "Rating";
        break;
      default:
        step = "";
        break;
    }
    return step;
  };

  const stepRoute = (item) => {
    let navigate;
    switch (item?.status) {
      case orderStatus.PURCHASE_ORDER:
        navigate = "/product-purchase-order";
        break;
      case orderStatus.INVOICE:
        navigate = "/product-purchase-invoice";
        break;
      case orderStatus.BANK_DETAILS:
        navigate = "/product-bank-detail";
        break;
      case orderStatus.PAYMENT:
        navigate = "/product-payment";
        break;
      case orderStatus.E_WAY_BILL:
        navigate = "/product-e-bill";
        break;
      case orderStatus.MATERIAL_DISPATCH:
        navigate = "/product-material-dispatch";
        break;
      case orderStatus.DELIVERED:
        navigate = "/product-delivered";
        break;
      case orderStatus.OFFER_ACCEPTED:
        navigate = "/product-company-detail";
        break;
      case orderStatus.OFFER_REJECTED:
        navigate = "/product-short-order";
        break;
      case orderStatus.OFFER_SENT:
        navigate = "/product-short-order";
        break;
      case orderStatus.COMPLETED:
        navigate = "/product-rating";
        break;
      case orderStatus.CANCELLED:
        navigate = "/product-short-order";
        break;
      default:
        navigate = "/transaction";
        break;
    }
    return navigate;
  };

  const stepStatus = (item) => {
    let status;
    switch (item?.status) {
      case orderStatus.COMPLETED:
        status = (
          <li className="active bg-green">Transaction Status: Complete</li>
        );
        break;
      case orderStatus.CANCELLED:
        status = (
          <li className="active bg-sky">Transaction Status: Incomplete</li>
        );
        break;
      case orderStatus.OFFER_REJECTED:
        status = (
          <li className="active bg-red">Transaction Status: Rejected</li>
        );
        break;
      case orderStatus.DRAFT:
        status = <li className="active">Transaction Status: Draft</li>;
        break;
      case orderStatus.TEMPORARY:
      case orderStatus.OFFER_SENT:
      case orderStatus.OFFER_ACCEPTED:
      case orderStatus.PURCHASE_ORDER:
      case orderStatus.INVOICE:
      case orderStatus.BANK_DETAILS:
      case orderStatus.PAYMENT:
      case orderStatus.E_WAY_BILL:
      case orderStatus.MATERIAL_DISPATCH:
      case orderStatus.DELIVERED:
        status = <li className="active">Transaction Status: Ongoing</li>;
        break;
      default:
        status = <li className="active">Transaction Status: {item?.status}</li>;
        break;
    }
    return status;
  };

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join(".");
  };

  return (
    <>
      <ToastContainer />
      <Header home parentToChild={parentToChild} />

      <div className="grey-bg">
        <div className="container-fluid support-page">
          <div className="short-order">
            <div className="short-order-head">
              <div className="page-title">
                <h6>Transactions</h6>
              </div>
            </div>
            <div className="inner-container">
              <div className="tabs tabs--transparent">
                <ul>
                  <li
                    className={`${filtering === "all" ? "active" : ""}`}
                    onClick={() => handleClick("all")}
                  >
                    <Link to={""}>All</Link>
                  </li>
                  <li
                    className={`${filtering === "on_going" ? "active" : ""}`}
                    onClick={() => handleClick("on_going")}
                  >
                    <Link to={""}>Ongoing</Link>
                  </li>
                  <li
                    className={`${filtering === "completed" ? "active" : ""}`}
                    onClick={() => handleClick("completed")}
                  >
                    <Link to={""}>Completed</Link>
                  </li>
                  <li
                    className={`${filtering === "in_complete" ? "active" : ""}`}
                    onClick={() => handleClick("in_complete")}
                  >
                    <Link to={""}>Incomplete</Link>
                  </li>
                  <li
                    className={`${filtering === "draft" ? "active" : ""}`}
                    onClick={() => handleClick("draft")}
                  >
                    <Link to={""}>Draft</Link>
                  </li>
                  <li
                    className={`${filtering === "rejected" ? "active" : ""}`}
                    onClick={() => handleClick("rejected")}
                  >
                    <Link to={""}>Rejected</Link>
                  </li>
                </ul>
              </div>
              <div className="short-order-head additional-filters">
                <div className="filters--wrapper">
                  <div className="order-filter">
                    <div className="tabs tabs--solid">
                      <ul>
                        <li
                          className={`${typeFilter === "" ? "active" : ""}`}
                          onClick={() => handleClickFilter("")}
                        >
                          <Link to={""}>All</Link>
                        </li>
                        <li
                          className={`${typeFilter === "post" ? "active" : ""}`}
                          onClick={() => handleClickFilter("post")}
                        >
                          <Link to={""}>From Post</Link>
                        </li>
                        <li
                          className={`${
                            typeFilter === "portfolio" ? "active" : ""
                          }`}
                          onClick={() => handleClickFilter("portfolio")}
                        >
                          <Link to={""}>From Portfolio</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-input"
                        placeholder="Search product name"
                        value={searching}
                        onChange={searchShortOrder}
                        onBlur={(e) => {
                          e.preventDefault();
                          clearInterval();
                        }}
                      />
                      <button className="search-button">
                        <SearchIcon />
                      </button>
                    </div>
                  </div>
                  <div className="short">
                    <select
                      className="form-input"
                      value={sorting}
                      onChange={(e) => setSorting(e.target.value)}
                    >
                      <option value="">Sort By</option>
                      <option value="latest">Newest</option>
                      <option value="oldest">Oldest</option>
                    </select>
                  </div>
                </div>
                <span className="total-nagotiations">
                  Total Transactions : {orderListing?.items?.length}
                </span>
              </div>
              {loading && (
                <div className="d-flex justify-content-center">
                  <PuffLoader size={100} />
                </div>
              )}

              {!orderListing?.items?.length && !loading ? (
                <>No Data Found</>
              ) : (
                <>
                  {orderListing?.items?.map((item, iindex) => (
                    <div
                      className="orders"
                      ref={lastBookElementRef}
                      key={iindex}
                    >
                      <div className="order-item">
                        <div className="image-block">
                          {item?.products?.[0]?.thumb_image?.file ? (
                            <>
                              {typeof item.products[0].thumb_image.file ===
                              "string" ? (
                                <img
                                  src={item.products[0].thumb_image.file}
                                  alt={item?.products?.[0]?.name}
                                  className="product-image-nagotiation"
                                  onClick={() =>
                                    handleOpenImgGallery(item.products[0])
                                  }
                                />
                              ) : (
                                <Slider
                                  option={sliderSettings}
                                  arrows={true}
                                  className="product-slider"
                                >
                                  {item.products[0].thumb_image.file?.map(
                                    (img) => (
                                      <img
                                        src={item.products[0].thumb_image.file}
                                        alt={item?.products?.[0]?.name}
                                        className="product-image-nagotiation"
                                        onClick={() =>
                                          handleOpenImgGallery(item.products[0])
                                        }
                                      />
                                    )
                                  )}
                                </Slider>
                              )}
                            </>
                          ) : (
                            <img
                              src={ProductImgPlaceholder}
                              alt="product image"
                              className="product-image-nagotiation"
                            />
                          )}
                        </div>
                        <div className="content-block">
                          <div className="left">
                            <Link
                              to={`/edit-profile-seller/${item.user.slug}`}
                              className="profile-link"
                            >
                              <div className="company-name">
                                <img
                                  src={
                                    user?.id === item?.to_user?.id
                                      ? item?.user?.company?.logo ||
                                        CompanyIconPlaceholder
                                      : item?.to_user?.company?.logo ||
                                        CompanyIconPlaceholder
                                  }
                                  alt="companyLogo"
                                />
                                {user?.id === item?.to_user?.id
                                  ? item?.user?.company?.name
                                  : item?.to_user?.company?.name}
                              </div>
                              <h6>
                                {item?.products?.length > 1 ? (
                                  <Link
                                    to={"/product-short-order"}
                                    state={{ id: item?.id }}
                                  >
                                    {item?.products[0]?.name +
                                      " " +
                                      ` & view ${
                                        item?.products?.length - 1
                                      } more `}
                                  </Link>
                                ) : (
                                  <>
                                    {item?.user?.name}&nbsp;
                                    {item?.products?.length > 1
                                      ? ` & view ${
                                          item?.products?.length - 1
                                        } more `
                                      : ""}
                                  </>
                                )}
                              </h6>
                            </Link>
                            <ul>
                              {/* <li>
                                <label>Description</label>:
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item?.products[0]?.description
                                      ? item?.products[0]?.description
                                      : "N/A",
                                  }}
                                ></span>
                              </li> */}
                              <li>
                                <label>Product Name</label> :
                                <span>{item?.products?.[0]?.name}</span>
                              </li>
                              <li>
                                <label>Products Category</label> :
                                <span>
                                  {item?.products?.[0]?.category?.parent?.name}
                                </span>
                              </li>
                              <li>
                                <label>Sub Category</label> :
                                <span>
                                  {item?.products?.[0]?.category?.name}
                                </span>
                              </li>
                              <li>
                                <label>Payment Terms</label> :
                                <span>{item?.payment_type}</span>
                              </li>
                              <li>
                                <label>PO id</label> :
                                <span>{item?.po_id || "-"}</span>
                              </li>
                              <li>
                                <label>Invoice Number</label> :
                                <span>{item?.invoice_number || "-"}</span>
                              </li>
                              <li>
                                <label>Nos of items</label> :
                                <span>{item?.total_qty},&nbsp;</span>
                                <label>Nos of Qty</label> :
                                <span>{item?.total_qty} pcs</span>
                              </li>
                            </ul>
                          </div>
                          <div className="right">
                            <div className="time mb-1">
                              <span>Transaction Start Date: </span>
                              {convert(item?.created_at)}
                            </div>
                            <div className="time mb-1">
                              <span>Trans. ID: </span>
                              {item?.transaction_id || "-"}
                            </div>
                            {item.status === orderStatus.COMPLETED && (
                              <div className="time mb-1 text-green">
                                <span>Transaction Completed date: </span>
                                {convert(item?.updated_at)}
                              </div>
                            )}
                            {item.status === orderStatus.CANCELLED && (
                              <div className="time mb-1 text-red">
                                <span>Transaction Cancelled date: </span>
                                {convert(item?.updated_at)}
                              </div>
                            )}
                            {item.status === orderStatus.OFFER_REJECTED && (
                              <div className="time mb-1 text-red">
                                <span>Transaction Rejected date: </span>
                                {convert(item?.updated_at)}
                              </div>
                            )}
                            {typeFilter !== "portfolio" &&
                              item?.type !== "product" && (
                                <Link
                                  to={`${window.location.origin}/post-detail/${item?.products[0]?.id}/${item?.type}`}
                                  state={{ id: item?.id }}
                                  className="view-btn"
                                  target="_blank"
                                >
                                  <LinkIcon />
                                  View Original Post
                                </Link>
                              )}
                            <Link
                              to={stepRoute(item)}
                              state={{ id: item?.id, route: "transaction" }}
                              className="view-btn"
                            >
                              <LinkIcon /> View Details
                            </Link>
                            {/* <div className="status">{stepStatus(item)}</div> */}
                            <div className="button-wrap">
                              {/* {item.status !== orderStatus.COMPLETED &&
                                item.status !== orderStatus.DRAFT && (
                                  <div className="step-label">
                                    <span className="counter">
                                      {item?.step?.current_step}
                                    </span>
                                    Step - {item?.step?.current_step}
                                    {stepName(item)}
                                  </div>
                                )} */}

                              {item?.status === orderStatus.OFFER_SENT &&
                                user?.id === item?.to_user?.id && (
                                  <div style={{ display: "flex" }}>
                                    <button
                                      className="button button-green button-green--bordered btn-release"
                                      onClick={(e) => {
                                        swal({
                                          title: "Are you sure?",
                                          text: "Are you sure you want to accept this offer  ?",
                                          icon: "warning",
                                          dangerMode: true,
                                          buttons: ["Cancel", "Ok"],
                                        }).then((willDelete) => {
                                          setLoading(true);
                                          if (willDelete) {
                                            handleAcceptOrReject(
                                              "accept",
                                              item?.id,
                                              iindex
                                            );
                                            setLoading(false);
                                          } else {
                                          }
                                        });
                                      }}
                                    >
                                      <AcceptIcon />
                                      Accept
                                    </button>
                                    <button
                                      className="button button-red button-red--bordered btn-reject"
                                      onClick={(e) => {
                                        swal({
                                          title: "Are you sure?",
                                          text: "Are you sure you want to reject this offer  ?",
                                          icon: "warning",
                                          dangerMode: true,
                                          buttons: ["Cancel", "Ok"],
                                        }).then((willDelete) => {
                                          setLoading(true);
                                          if (willDelete) {
                                            handleAcceptOrReject(
                                              "reject",
                                              item?.id,
                                              iindex
                                            );
                                            setLoading(false);
                                          } else {
                                          }
                                        });
                                      }}
                                    >
                                      <RejectIcon />
                                      Reject
                                    </button>
                                  </div>
                                )}
                            </div>
                            {item.status === orderStatus.DRAFT ? (
                              <div className="btn-wrap">
                                <Link
                                  to={`/product-short-order`}
                                  state={{ id: item?.id }}
                                  className="button button-primary"
                                >
                                  View Draft
                                </Link>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="order-item-footer">
                          <ul className="inner-step-list">
                            <li>
                              Transaction Step :
                              <span className="counter">
                                {item?.step?.current_step}
                              </span>
                              {stepName(item)}
                            </li>
                            <li>Transaction Value : ₹{item?.grand_total}</li>
                            {stepStatus(item)}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="md"
        show={selectedImgs.length > 0}
        onHide={handleCloseImgGallery}
        className="nagotiation-gallery"
      >
        <div className="modal-body">
          <button className="modal-close" onClick={handleCloseImgGallery}>
            <CloseIcon />
          </button>
          <div className="container">
            <Slider {...sliderSettings} arrows={true} className="galley-slider">
              {selectedImgs.map((item) => (
                <img
                  src={item}
                  alt={"product-image"}
                  height="350px"
                  width="350px"
                />
              ))}
            </Slider>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Transaction;
