import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import Breadcrumb from "../common/breadcrumb";
import CompanyLogo from "../assets/images/company-logo.png";
import { ReactComponent as TickIcon } from "../assets/images/tick-icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { get, getAuthConfig } from "../libs/http-hydrate";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { ReactComponent as DeleteIcon } from "../assets/images/deleteBlck.svg";
import Skeleton from "react-loading-skeleton";
import InvoiceModal from "./InvoiceModal";
import Auth from "../libs/auth";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import DeleteTransaction from "../components/ShortOrder/DeleteTransaction";
import { toast, ToastContainer } from "react-toastify";

function ProductPurchaseInvoice() {
  const user = Auth?.getCurrentUser();
  const location = useLocation();
  const [ShortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [poid, setPOID] = useState("");
  const [error, setError] = useState(false);
  const [isPOChecked, setisPOChecked] = useState(false);
  const [isPOType, setIsPOType] = useState("");
  const [buttonName, setButtonName] = useState("Submit");
  const [checkboxValues, setCheckboxValues] = useState({
    auto: true,
    manually: false,
  });

  const navigate = useNavigate();

  let currentStep = 4;

  useEffect(() => {
    getProductCartSO();
  }, [location?.state?.id]);

  const userData = localStorage.getItem("buyerAccount");

  const getProductCartSO = async () => {
    if (location?.state?.id) {
      setLoading(true);

      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
          setProducts(res?.data?.data?.items);
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate("/");
          }
        });
    }
  };

  async function updateData() {
    getProductCartSO();
  }

  if (!localStorage.getItem("paymentType")) {
    localStorage.setItem("paymentType", ShortOrderDetails.payment_type);
  }

  const payment = localStorage.getItem("paymentType");

  async function POChecker() {
    await get(
      `/short_order/po_id_checker?order_id=${location?.state?.id}&po_id=${poid}`,
      getAuthConfig()
    )
      .then((res) => {
        if (res?.data?.status === 200) {
          setButtonName("Submitted");
        }
        setisPOChecked(true);
      })
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err?.response?.data?.message === "Short order not found"
        ) {
          toast.error(err?.response?.data?.message);
        }
      });
  }

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />

      <div className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb shortOrderDetails={ShortOrderDetails} />
          <div className="steps">
            <ShortOrderStepper
              currentStep={currentStep}
              isCompleted={ShortOrderDetails?.step?.current_step}
              orderId={ShortOrderDetails?.id}
            />
          </div>
          <div className="order product-order purchase-order-page">
            <div style={{ height: "50px" }} className="page-title">
              <h6>Invoice</h6>
              {Number(ShortOrderDetails?.step?.current_step) >= 4 ? (
                <a
                  href={ShortOrderDetails?.links?.invoice}
                  className="button button-primary"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "black" }}
                >
                  View Invoice
                </a>
              ) : userData === "false" ? (
                <button
                  className="button button-primary"
                  data-toggle="modal"
                  data-target="#invoiceModal"
                  style={{ color: "black" }}
                >
                  Generate Invoice
                </button>
              ) : (
                <button className="button button-primary">
                  Wait while seller Generate a Invoice
                </button>
              )}
            </div>
            <div className="company-detail purchase-order">
              <div style={{ display: "flex", gap: "50px" }}>
                <div
                  style={{ fontSize: "16px", marginBottom: "5px" }}
                  className="flex-item"
                >
                  <strong>PO ID: #{ShortOrderDetails?.po_id}</strong>
                </div>

                <div>
                  {Number(ShortOrderDetails?.step?.current_step) === 3 && (
                    <div className={userData === "true" ? "d-none" : ""}>
                      <div
                        style={{ paddingBottom: "8px" }}
                        className="purchase-order-head"
                        disabled={
                          user?.id === ShortOrderDetails?.to_user?.id
                            ? true
                            : false
                        }
                      >
                        <span className="po-id">Auto Generate Invoice: </span>
                        {Number(ShortOrderDetails?.step?.current_step) ===
                          3 && (
                          <>
                            <div className="form-field">
                              <input
                                type="checkbox"
                                name="po_ref"
                                id="auto"
                                checked={checkboxValues.auto}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setError(false);
                                    setisPOChecked(true);
                                    setIsPOType("auto");
                                  }

                                  setCheckboxValues((prev) => ({
                                    ...prev,
                                    auto: e.target.checked,
                                    manually: false,
                                  }));
                                }}
                              />
                              <label htmlFor="auto">Manual Invoice</label>
                            </div>
                            <div className="form-field">
                              <input
                                type="checkbox"
                                name="po_ref"
                                id="manual"
                                checked={checkboxValues.manually}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setIsPOType("manual");
                                    setisPOChecked(false);
                                  }

                                  setCheckboxValues((prev) => ({
                                    ...prev,
                                    manually: e.target.checked,
                                    auto: false,
                                  }));
                                }}
                              />
                              <label htmlFor="manual">
                                <input
                                  type="text"
                                  className="form-input"
                                  placeholder="Enter Manually Invoice Number"
                                  onChange={(e) => {
                                    e.preventDefault();
                                    setError(false);
                                    setisPOChecked(false);
                                    setPOID(e.target.value);
                                  }}
                                  disabled={
                                    checkboxValues?.auto ||
                                    (!checkboxValues.manually && true)
                                  }
                                />

                                <button
                                  className={`button button-primary`}
                                  disabled={
                                    checkboxValues?.auto ||
                                    (!checkboxValues.manually && true)
                                  }
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (checkboxValues.manually) {
                                      POChecker();
                                    } else {
                                      setError(true);
                                    }
                                  }}
                                >
                                  {buttonName}
                                </button>
                              </label>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {error === true && (
                    <span
                      className="text-danger"
                      style={{ marginLeft: "100px" }}
                    >
                      <b>Please Enter POID or Select Auto generate</b>
                    </span>
                  )}
                </div>
              </div>
              <div className="order-table">
                <span
                  style={{ fontSize: "16px", marginBottom: "5px" }}
                  className="order-id"
                >
                  <strong>Order ID #{ShortOrderDetails?.id}</strong>
                </span>
                {!loading && products?.length ? (
                  <table>
                    <thead style={{ fontSize: "14px" }}>
                      <tr>
                        <th>Sr NO</th>
                        <th>Product Category, Product Sub Category</th>
                        <th>Product Differentiation</th>
                        <th>Required Qty</th>
                        <th>GST (%)</th>
                        <th>Seller Offer Price per Qty</th>
                        <th
                          colSpan={`${
                            ShortOrderDetails?.status === "temporary" ||
                            ShortOrderDetails?.status === "draft"
                              ? 2
                              : 3
                          }`}
                        >
                          Total Gross Value
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {products?.map((item, index) => {
                        return (
                          <>
                            <tr style={{ fontSize: "14px" }} key={item.id}>
                              <td>{index + 1}</td>
                              <td>{`${item?.product?.category?.name}`}</td>
                              <td>
                                {item?.product?.brand?.name
                                  ? `Brand: ${item?.product?.brand?.name},`
                                  : "Brand: N/A,"}
                                &nbsp;
                                {item?.product?.size
                                  ? `Size: ${item?.product?.size},`
                                  : "Size: N/A,"}
                                &nbsp;
                                {item?.product?.color?.name
                                  ? `Color: ${item?.product?.color?.name}`
                                  : "Color: N/A"}
                              </td>
                              <td>{item?.quantity}</td>
                              <td>{item?.gst?.percentage}</td>
                              <td>₹{item?.amount}</td>
                              <td
                                colSpan={`${
                                  ShortOrderDetails?.status === "temporary" ||
                                  ShortOrderDetails?.status === "draft"
                                    ? 2
                                    : 3
                                }`}
                              >
                                ₹{item?.sub_total}
                              </td>
                              {(ShortOrderDetails?.status === "temporary" ||
                                ShortOrderDetails?.status === "draft") && (
                                <td>
                                  <DeleteIcon style={{ cursor: "pointer" }} />
                                </td>
                              )}
                            </tr>
                          </>
                        );
                      })}
                      <tr style={{ fontSize: "14px" }}>
                        <td colSpan={"6"}></td>
                        <td className="height-light">Total</td>
                        <td className="height-light">
                          ₹{ShortOrderDetails?.sub_total}
                        </td>
                      </tr>
                      <tr style={{ fontSize: "14px" }}>
                        <td colSpan={"6"}></td>
                        <td className="height-light">
                          {ShortOrderDetails?.freight_charge?.percentage}%
                          Freight
                        </td>
                        <td className="height-light">
                          ₹{ShortOrderDetails?.freight_charge?.charge}
                        </td>
                      </tr>
                      <tr style={{ fontSize: "14px" }}>
                        <td colSpan={"6"}></td>
                        <td className="height-light">Total after freight</td>
                        <td className="height-light">
                          ₹
                          {parseFloat(
                            Number(ShortOrderDetails?.freight_charge?.charge) +
                              Number(ShortOrderDetails?.sub_total)
                          )?.toFixed(2)}
                        </td>
                      </tr>
                      <tr style={{ fontSize: "14px" }}>
                        <td colSpan={"6"}></td>
                        <td className="height-light">GST</td>
                        <td className="height-light">
                          ₹{ShortOrderDetails?.gst}
                        </td>
                      </tr>
                      <tr style={{ fontSize: "14px" }} className="footer">
                        <td className="height-light">Profile ID</td>
                        <td className="height-light">
                          {ShortOrderDetails?.user?.id}
                        </td>
                        <td colSpan={"2"} className="height-light">
                          Payment Terms
                        </td>
                        <td
                          colSpan={"2"}
                          style={{ textTransform: "capitalize" }}
                          className="height-light"
                        >
                          {ShortOrderDetails?.payment_type}
                        </td>
                        <td className="height-light">Total Invoice Value</td>
                        <td className="height-light">
                          ₹{ShortOrderDetails?.grand_total}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <Skeleton height={270} />
                )}
              </div>
            </div>
            {!loading && (
              <div className="d-flex" style={{ height: "60px" }}>
                <div
                  style={{
                    display: "flex",
                    height: "60px",
                    gap: "10px",
                  }}
                  className="d-flex w-100 justify-content-center"
                >
                  <Link
                    to={"/product-purchase-order"}
                    state={{
                      id: ShortOrderDetails?.id,
                      route: location?.state?.route,
                    }}
                    className="button button-blue"
                    style={{
                      height: "40px",
                      padding: "10px 20px",
                      margin: 0,
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      marginLeft: "70px",
                    }}
                  >
                    Previous
                  </Link>

                  {ShortOrderDetails?.links?.invoice && (
                    <a
                      download="invoice.pdf"
                      href={ShortOrderDetails?.links?.invoice}
                      className="button button-primary download-btn"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        height: "40px",
                        padding: "10px 20px",
                        margin: 0,
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                        color: "black",
                      }}
                    >
                      Download Invoice
                    </a>
                  )}

                  {Number(ShortOrderDetails?.step?.current_step) >= 4 ? (
                    <Link
                      state={{
                        id: ShortOrderDetails?.id,
                        route: location?.state?.route,
                      }}
                      to={`${
                        payment === "advance"
                          ? "/product-bank-detail"
                          : "/product-e-bill"
                      }`}
                      className="button button-primary"
                      style={{
                        height: "40px",
                        padding: "10px 20px",
                        margin: 0,
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      Next
                    </Link>
                  ) : (
                    <button
                      style={{
                        height: "40px",
                        padding: "10px 20px",
                        margin: 0,
                        display: "flex",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                      disabled
                      className="button button-secondary"
                    >
                      Next
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-flex-end align-items-center">
                  <span>
                    {currentStep < 5 &&
                      ShortOrderDetails?.step?.current_step <= 4 && (
                        <DeleteTransaction id={ShortOrderDetails?.id} />
                      )}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <InvoiceModal
        OrderID={ShortOrderDetails?.id}
        updateData={updateData}
        invoiceNumber={poid}
        checkboxValues={checkboxValues}
      />
    </>
  );
}

export default ProductPurchaseInvoice;
