import React from "react";

const IconGenerate = ({ iconImage = "", width = "25px" }) => {
  return (
    <span>
      <img
        src={iconImage}
        style={{
          width: width,
          height: width,
        }}
      />
    </span>
  );
};

export default IconGenerate;
