import React, { useContext, useEffect, useState } from "react";
import "../common/scss/pages/order.scss";
import Header from "../common/header";
import { ReactComponent as SaveDraftIcon } from "../assets/images/save-draft.svg";
import { ReactComponent as SendOfferIcon } from "../assets/images/send-offer.svg";
import { ReactComponent as NegotiationIcon } from "../assets/images/short-negotiation.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/deleteBlck.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as RejectIcon } from "../assets/images/short-reject.svg";
import { ReactComponent as OfferAcceptIcon } from "../assets/images/offer-accept.svg";
import { ReactComponent as OrderMeetingIcon } from "../assets/images/order-meeting.svg";
import { Button } from "react-bootstrap";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import Skeleton from "react-loading-skeleton";
import Auth from "../libs/auth";
import { SwitchProfile } from "../context/switchProfile.context";
import ShortOrderStepper from "../common/Stepper/ShortOrderStepper";
import Breadcrumb from "../common/breadcrumb";

function ProductShortOrder() {
  const navigate = useNavigate();

  const user = Auth.getCurrentUser();
  const { setBuyerAccount, setSellerAccount } = useContext(SwitchProfile);

  const [shortOrderDetails, setShortOrderDetails] = useState({});
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [GstAvg, setGstAvg] = useState(0);
  const negotiation = localStorage.getItem("productNegotiation");
  const location = useLocation();

  let currentStep = 1;

  const handleAcceptOrReject = async (action, id) => {
    const formData = new FormData();
    formData.append("type", action);
    formData.append("order_id", id);
    await post("/short_order/action_on_transaction", formData, getAuthConfig())
      .then((res) => {
        if (action === "reject") {
          toast.success("Offer Rejected Successfully ");
          navigate("/transaction");
        } else if (action === "accept") {
          toast.success("Offer Accepted Successfully ");
          setTimeout(() => {
            navigate("/product-company-detail", { state: { id: id } });
          }, 1000);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const getProductCartSO = async () => {
    setLoading(true);
    if (location?.state?.id) {
      await get(`/short_order/details/${location?.state?.id}`, getAuthConfig())
        .then((res) => {
          setLoading(false);
          setShortOrderDetails(res?.data?.data);
          localStorage.setItem("paymentType", res?.data?.data?.payment_type);
          setProducts(res?.data?.data?.items);
          let gstSumm = 0;

          res?.data?.data?.items.map((item, index) => {
            gstSumm = gstSumm + Number(item?.gst?.percentage);
          });
          setGstAvg(
            parseFloat(gstSumm / res?.data?.data?.items.length).toFixed(2)
          );
        })
        .catch((err) => {
          setLoading(false);
          if (
            err.response.status === 400 &&
            err?.response?.data?.message === "Short order not found"
          ) {
            navigate(
              `/product-portfolio-initial/${shortOrderDetails?.to_user?.id}`
            );
          }
        });
    }
  };

  const saveDraft = async (id) => {
    if (shortOrderDetails?.status === "temporary") {
      await get(`/short_order/save_draft/${id}`, getAuthConfig())
        .then((res) => {
          toast.success(res?.data?.message);

          setTimeout(() => {
            navigate("/transaction");
          }, 2000);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getProductCartSO();
  }, [localStorage.getItem("SOID"), location?.state?.id]);

  const deleteProductCartSO = async (item) => {
    setLoading(true);
    if (
      shortOrderDetails?.sub_total - item?.sub_total <
        shortOrderDetails?.min_order_value &&
      shortOrderDetails.items.length > 1
    ) {
      setLoading(false);
      toast.error(
        "Minimum order value not fulfilled if you remove this product from cart"
      );
    } else {
      await get(`/short_order/delete_item/${item?.id}`, getAuthConfig())
        .then((res) => {
          if (
            Number(res?.data?.data?.sub_total) <
            Number(shortOrderDetails?.min_order_value)
          );
          setLoading(false);
          getProductCartSO();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  async function SendOffer() {
    await get(`/short_order/send_offer/${location?.state?.id}`, getAuthConfig())
      .then((res) => {
        if (res.status === 200) {
          toast.success("Short Order Created Successfully");
          getProductCartSO();

          if (user.is_seller === 1) {
            toast.success(`Your Account is switched to Buyer`);
            accountSwitch();
          } else {
            navigate("/transaction");
          }
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
  const accountSwitch = async (negotiation) => {
    await postwithOu("/user/account_switch", getAuthConfig())
      .then((res) => {
        if (res.status === 200) {
          if (res?.data?.data?.is_seller === 1) {
            setSellerAccount(true);
            localStorage.setItem("accountFor", "seller");
          }
          if (res?.data?.data?.is_buyer === 1) {
            setBuyerAccount(true);
            localStorage.setItem("accountFor", "buyer");
          }

          if (negotiation != undefined || negotiation != null) {
            setTimeout(() => {
              navigate(
                `/negotiation-buyer-adjustment/${shortOrderDetails?.id}`,
                {
                  state: {
                    shortOrder: true,
                    id: shortOrderDetails?.id,
                  },
                }
              );
            }, [2000]);
          } else {
            setTimeout(() => {
              navigate("/transaction");
            }, [2000]);
          }
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <ToastContainer />
      <Header home disableSwitch />
      <div style={{ overflowY: "hidden" }} className="grey-bg">
        <div className="container-fluid order-page">
          <Breadcrumb shortOrderDetails={shortOrderDetails} />
          <div className="steps">
            <ShortOrderStepper
              currentStep={currentStep}
              isCompleted={shortOrderDetails?.step?.current_step}
              orderId={shortOrderDetails?.id}
            />
          </div>
          <div className="order product-order">
            <div className="text-center">
              <div style={{ height: "50px" }} className="page-title ">
                <h6>{`${
                  user?.is_seller === 1
                    ? `Transaction With ${
                        shortOrderDetails?.to_user?.company_details?.name || "-"
                      }`
                    : "Short Order"
                }`}</h6>
              </div>
            </div>
            <div className="order-table">
              <span
                style={{ fontSize: "16px", marginBottom: "5px" }}
                className="order-id"
              >
                <strong>Order ID #{shortOrderDetails?.id}</strong>
              </span>
              {!loading && products?.length ? (
                <table>
                  <thead style={{ fontSize: "14px" }}>
                    <tr>
                      <th>Sr NO</th>
                      <th>Product Name</th>
                      <th>Product Differentiation</th>
                      <th>Required Qty</th>
                      <th>GST (%)</th>
                      <th>Seller Offer Price per Qty</th>
                      <th
                        colSpan={`${
                          shortOrderDetails?.status === "temporary" ||
                          shortOrderDetails?.status === "draft"
                            ? 2
                            : 3
                        }`}
                      >
                        Total Gross Value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products?.map((item, index) => {
                      return (
                        <>
                          <tr style={{ fontSize: "14px" }} key={item.id}>
                            <td>{index + 1}</td>
                            <td>{`${item?.product?.name}`}</td>
                            <td>
                              {item?.product?.brand?.name
                                ? `Brand: ${item?.product?.brand?.name},`
                                : "Brand: N/A,"}
                              &nbsp;
                              {item?.product?.size
                                ? `Size: ${item?.product?.size},`
                                : "Size: N/A,"}
                              &nbsp;
                              {item?.product?.color?.name
                                ? `Color: ${item?.product?.color?.name}`
                                : "Color: N/A"}
                            </td>
                            <td>{item?.quantity}</td>
                            <td>{item?.gst?.percentage}</td>
                            <td>₹{item?.amount}</td>
                            <td
                              colSpan={`${
                                shortOrderDetails?.status === "temporary" ||
                                shortOrderDetails?.status === "draft"
                                  ? 2
                                  : 3
                              }`}
                            >
                              ₹{item?.sub_total}
                            </td>
                            {(shortOrderDetails?.status === "temporary" ||
                              shortOrderDetails?.status === "draft") && (
                              <td>
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    swal({
                                      title: "Are you sure?",
                                      text: "Are you sure you want to remove this item from cart?",
                                      icon: "warning",
                                      dangerMode: true,
                                      buttons: ["Cancel", "Ok"],
                                    }).then((willDelete) => {
                                      setLoading(true);
                                      if (willDelete) {
                                        deleteProductCartSO(item);
                                      }
                                      setLoading(false);
                                    });
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td className="height-light">Total</td>
                      <td className="height-light">
                        ₹{shortOrderDetails?.sub_total}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td className="height-light">
                        {shortOrderDetails?.freight_charge?.percentage}% Freight
                      </td>
                      <td className="height-light">
                        ₹{shortOrderDetails?.freight_charge?.charge}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td className="height-light">Total after freight</td>
                      <td className="height-light">
                        ₹
                        {parseFloat(
                          Number(shortOrderDetails?.freight_charge?.charge) +
                            Number(shortOrderDetails?.sub_total)
                        )?.toFixed(2)}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }}>
                      <td colSpan={"6"}></td>
                      <td className="height-light">GST</td>
                      <td className="height-light">
                        ₹{shortOrderDetails?.gst}
                      </td>
                    </tr>
                    <tr style={{ fontSize: "14px" }} className="footer">
                      <td className="height-light">Profile ID</td>
                      <td className="height-light">
                        {shortOrderDetails?.user?.id}
                      </td>
                      <td colSpan={"2"} className="height-light">
                        Payment Terms
                      </td>
                      <td
                        colSpan={"2"}
                        style={{ textTransform: "capitalize" }}
                        className="height-light"
                      >
                        {shortOrderDetails?.payment_type}
                      </td>
                      <td className="height-light">Total Invoice Value</td>
                      <td className="height-light">
                        ₹{shortOrderDetails?.grand_total}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Skeleton height={270} />
              )}
              {loading ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div className="btn-wrap">
                      {shortOrderDetails?.status !== "offer_sent" &&
                      user?.id === shortOrderDetails?.user?.id &&
                      shortOrderDetails?.status !== "offer_accepted" &&
                      shortOrderDetails?.status !== "offer_rejected" &&
                      shortOrderDetails?.status !== "accepted" &&
                      shortOrderDetails?.status !== "rejected" &&
                      shortOrderDetails?.status !== "completed" &&
                      shortOrderDetails?.status !== "cancelled" ? (
                        <span
                          className="button button-primary button-primary--bordered"
                          onClick={(e) => {
                            e.preventDefault();
                            SendOffer();
                          }}
                          disabled={
                            shortOrderDetails?.status === "offer_sent"
                              ? true
                              : false
                          }
                        >
                          <SendOfferIcon />
                          Send Offer
                        </span>
                      ) : (
                        <>
                          {shortOrderDetails?.status === "accepted" ||
                          shortOrderDetails?.status === "offer_accepted" ||
                          shortOrderDetails?.step?.current_step > 1 ||
                          shortOrderDetails?.status === "completed" ? (
                            <Link
                              className="button button-primary"
                              to={"/product-company-detail"}
                              state={{
                                id: shortOrderDetails?.id,
                                route: location?.state?.route,
                              }}
                            >
                              Next
                            </Link>
                          ) : (
                            <>
                              {shortOrderDetails?.status === "offer_sent" &&
                                user?.id === shortOrderDetails?.to_user?.id && (
                                  <>
                                    <div className="btn-wrap">
                                      <button
                                        onClick={() =>
                                          swal({
                                            title: "Are you sure?",
                                            text: "Are you sure you want to accept this offer  ?",
                                            icon: "warning",
                                            success: true,
                                            buttons: ["Cancel", "Ok"],
                                          }).then((willDelete) => {
                                            if (willDelete) {
                                              setLoading(true);
                                              handleAcceptOrReject(
                                                "accept",
                                                shortOrderDetails?.id
                                              );
                                            }
                                            setLoading(false);
                                          })
                                        }
                                        className="button button-green button-green--bordered"
                                      >
                                        <OfferAcceptIcon />
                                        Accept
                                      </button>
                                      <button
                                        onClick={() =>
                                          swal({
                                            title: "Are you sure?",
                                            text: "Are you sure you want to reject this offer  ?",
                                            icon: "warning",
                                            dangerMode: true,
                                            buttons: ["Cancel", "Ok"],
                                          }).then((willDelete) => {
                                            setLoading(true);
                                            if (willDelete) {
                                              handleAcceptOrReject(
                                                "reject",
                                                shortOrderDetails?.id
                                              );
                                            }
                                            setLoading(false);
                                          })
                                        }
                                        className="button button-red button-red--bordered btn-reject"
                                      >
                                        <RejectIcon />
                                        Reject
                                      </button>
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                      {(negotiation === "1" ||
                        shortOrderDetails?.can_negotiate === "1") &&
                        user?.id === shortOrderDetails?.user?.id &&
                        shortOrderDetails?.status !== "offer_sent" &&
                        shortOrderDetails?.status !== "offer_accepted" &&
                        shortOrderDetails?.status !== "offer_rejected" &&
                        shortOrderDetails?.status !== "rejected" &&
                        shortOrderDetails?.status !== "accepted" && (
                          <div
                            to={`/negotiation-buyer-adjustment/${shortOrderDetails?.id}`}
                            state={{
                              shortOrder: true,
                              id: shortOrderDetails?.id,
                            }}
                            disabled={
                              shortOrderDetails?.status === "offer_sent"
                                ? true
                                : false
                            }
                            className="button button-primary button-primary--bordered"
                            onClick={(e) => {
                              e.preventDefault();
                              if (user.is_seller === 1) {
                                toast.success(
                                  `Your Account is switched to Buyer`
                                );
                                accountSwitch("negotiation");
                              } else {
                                navigate(
                                  `/negotiation-buyer-adjustment/${shortOrderDetails?.id}`,
                                  {
                                    state: {
                                      shortOrder: true,
                                      id: shortOrderDetails?.id,
                                    },
                                  }
                                );
                              }
                            }}
                          >
                            <NegotiationIcon />
                            Negotiation
                          </div>
                        )}
                      {user?.id === shortOrderDetails?.user?.id &&
                        shortOrderDetails?.status !== "offer_sent" &&
                        shortOrderDetails?.status !== "offer_accepted" &&
                        shortOrderDetails?.status !== "offer_rejected" &&
                        shortOrderDetails?.status !== "rejected" &&
                        shortOrderDetails?.status !== "accepted" &&
                        shortOrderDetails?.status !== "completed" &&
                        shortOrderDetails?.status !== "cancelled" && (
                          <button
                            disabled={
                              shortOrderDetails?.status === "draft" ||
                              shortOrderDetails?.status === "offer_sent"
                                ? true
                                : false
                            }
                            className="button button-blue button-blue--bordered"
                            onClick={() => {
                              saveDraft(shortOrderDetails?.id);
                            }}
                          >
                            <SaveDraftIcon />
                            Save Draft
                          </button>
                        )}
                    </div>
                    {user?.is_seller === 1 && (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "47px" }}
                      >
                        <button
                          onClick={() => navigate(-1)}
                          className=" button button-primary btn-primary"
                          style={{ padding: "10px 15px" }}
                        >
                          Back
                        </button>
                      </div>
                    )}
                  </div>
                  {(shortOrderDetails?.status === "temporary" &&
                    user?.id === shortOrderDetails?.to_user?.id) ||
                    (shortOrderDetails.can_edit === 0 && (
                      <>
                        <div
                          className="order_status"
                          style={{
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "red",
                            }}
                          >
                            Waiting for buyers's confirmation
                          </p>
                          <Link
                            className=" button button-green button-green--bordered btn-release"
                            to={"/transaction"}
                          >
                            Go Back to Negotiation List
                          </Link>
                        </div>
                      </>
                    ))}
                  {shortOrderDetails?.status === "offer_rejected" && (
                    <>
                      <div className="order_status">
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "red",
                          }}
                        >
                          Sorry! This Offer is already Rejected
                        </p>
                      </div>
                    </>
                  )}
                  {shortOrderDetails?.status === "offer_sent" &&
                    user?.id === shortOrderDetails?.user?.id && (
                      <>
                        <div
                          className="order_status"
                          style={{
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              color: "red",
                            }}
                          >
                            Waiting for seller confirmation
                          </p>
                          <Link
                            className=" button button-green button-green--bordered btn-release"
                            to={"/transaction"}
                          >
                            Go Back to Transaction List
                          </Link>
                        </div>
                      </>
                    )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductShortOrder;
