import React from "react";
import "./ProfileViewSwitch.css";

const ProfileViewSwitch = ({
  isBuyer = true,
  isDefaultBuyer = false,
  toggleView,
}) => {
  return (
    <div
      className={`switch-container ${isDefaultBuyer ? "disabled" : ""}`}
      onClick={!isDefaultBuyer ? toggleView : undefined}
    >
      <div className={`switch ${isBuyer ? "buyer" : "seller"}`}>
        <span className="label buyer-label">View Buyer</span>
        <span className="label seller-label">View Seller</span>
        <div className="slider"></div>
      </div>
    </div>
  );
};

export default ProfileViewSwitch;
