import React from "react";
import { ReactComponent as RecommendationIcon } from "../../assets/images/recommendation-v2.svg";
import ReviewProgressPanel from "./ReviewProgressPanel";

// import CircularProgress from "../CircularProgress";

const ReviewCard = ({ isSeller, reviews }) => {
  return (
    <div className="card">
      <div className="card-header card-header--large">
        <h6 style={{ fontSize: "16px" }}>
          <RecommendationIcon />
          Recommendations
        </h6>
      </div>
      <div className="card-body">
        {/* <CircularProgress
          value={
            isSeller === 0
              ? Number(reviews?.respond) * 20
              : Number(reviews?.price) * 20
          }
          label={isSeller === 0 ? "Respond" : "Price"}
        />

        <CircularProgress
          value={
            isSeller === 0
              ? Number(reviews?.payment) * 20
              : Number(reviews?.quality) * 20
          }
          label={isSeller === 0 ? "Payment" : "Quality"}
        />

        <CircularProgress
          value={
            isSeller === 0
              ? Number(reviews?.valuable) * 20
              : Number(reviews?.service) * 20
          }
          label={isSeller === 0 ? "Valuable" : "Service"}
        />

        <CircularProgress
          value={Number(reviews?.support) * 20}
          label={"Support"}
        /> */}

        <ReviewProgressPanel
          reviews={
            isSeller === 0 ? Number(reviews?.respond) : Number(reviews?.price)
          }
          label={isSeller === 0 ? "Respond" : "Price"}
        />
        <ReviewProgressPanel
          reviews={
            isSeller === 0 ? Number(reviews?.payment) : Number(reviews?.quality)
          }
          label={isSeller === 0 ? "Payment" : "Quality"}
        />
        <ReviewProgressPanel
          reviews={
            isSeller === 0
              ? Number(reviews?.valuable)
              : Number(reviews?.service)
          }
          label={isSeller === 0 ? "Valuable" : "Service"}
        />
        <ReviewProgressPanel
          reviews={Number(reviews?.support)}
          label={"Support"}
        />
      </div>
    </div>
  );
};

export default ReviewCard;
