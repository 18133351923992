import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../common/scss/pages/home.scss";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as CloseIcon } from "../assets/images/close-icon.svg";
import { ReactComponent as ConnectIcon } from "../assets/images/connect-2-new.svg";
import { ReactComponent as BuyerIcon } from "../assets/images/post-to-buy-new.svg";
import { ReactComponent as SellerIcon } from "../assets/images/post-to-sell-new.svg";
import { ReactComponent as SocialIcon } from "../assets/images/post-to-social-new.svg";
import { ReactComponent as ResetIcon } from "../assets/images/reset-icon.svg";
import { ReactComponent as ShareIcon } from "../assets/images/share-icon.svg";
import { ReactComponent as CaretDownIcon } from "../assets/images/caret-down.svg";
import { ReactComponent as MoreIcon } from "../assets/images/more-icon.svg";
import { ReactComponent as HistoryIcon } from "../assets/images/history-icon.svg";
import { ReactComponent as EditIcon } from "../assets/images/edit-icon.svg";
import { ReactComponent as LikeIconFilled } from "../assets/images/like-icon-filled.svg";
import { ReactComponent as CommentIconFilled } from "../assets/images/comment-icon-v2.svg";
import { ReactComponent as SendQuoteIcon } from "../assets/images/send-quote-icon.svg";
import { ReactComponent as MeetingRoomIcon } from "../assets/images/Meeting-room-v2.svg";
import { ReactComponent as MeetingRoomIconFilled } from "../assets/images/Meeting-room-v2.svg";
import { ReactComponent as PostShareIcon } from "../assets/images/post-share-icon.svg";
import { ReactComponent as PostShareIconFilled } from "../assets/images/post-share-icon-filled.svg";
import { ReactComponent as CopyIcon } from "../assets/images/copy-link-v2.svg";
import { ReactComponent as CopyIconFilled } from "../assets/images/copy-link-v2.svg";
import { ReactComponent as AcceptDealIcon } from "../assets/images/accept-deal-v2.svg";
import { ReactComponent as NegotiationIcon } from "../assets/images/negotiation-v2.svg";
import { ReactComponent as CardIcon } from "../assets/images/card-icon.svg";
import { ReactComponent as SearchIcon } from "../assets/images/search-icon.svg";
import { ReactComponent as ReactLikeIcon } from "../assets/images/react-like.svg";
import { ReactComponent as CelebrateIcon } from "../assets/images/celebrate.svg";
import { ReactComponent as CareIcon } from "../assets/images/care.svg";
import { ReactComponent as LoveIcon } from "../assets/images/love.svg";
import { ReactComponent as InsightfulIcon } from "../assets/images/insightful.svg";
import { ReactComponent as CuriousIcon } from "../assets/images/curious.svg";
import { ReactComponent as AttachmentIcon } from "../assets/images/attachment.svg";
import { ReactComponent as ArrowDownIcon } from "../assets/images/arrow-down.svg";
import { ReactComponent as SettingIcon } from "../assets/images/settings-icon.svg";
import featuredTagIcon from "../assets/images/featured-tag.svg";
import profilePic from "../assets/images/profile.png";
import GSTImage from "../assets/images/gst.png";
import BankImage from "../assets/images/bank.png";
import Header from "../common/header";
import PostSocialModal from "./post-social-modal";
import VerifyModal from "./verify-modal";
import VerifyGSTModal from "./verify-gst-modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post, postwithOu } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import swal from "sweetalert";
import { useCallback } from "react";
import { useRef } from "react";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import Select from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "react-bootstrap/Modal";
import ReportModal from "./report-modal";
import { SwitchProfile } from "../context/switchProfile.context";
import Skeleton from "react-loading-skeleton";
import SendQuote from "./send-quote";
import NegotiationModal from "./negotiation-modal";
import AcceptDeal from "./acceptDeal";
import { ModalBody } from "react-bootstrap";
import SellerModal from "./sellerModal";
import RecievedCard from "./RecievedCard";
import domtoimage from "dom-to-image";
import DescriptionWithLinks from "../Hook/DescriptionWithLinks";
import { customStyles } from "../utils/react-select-input-style";
import { IconAcceptDealtDark, IconAcceptDealtLight } from "../assets/icons/new";
import IconGenerate from "../components/GenerateIcon/IconGenerate";

function Home() {
  let darkMode = localStorage.getItem("dark");
  if (darkMode === "false") {
    darkMode = false;
  } else {
    darkMode = true;
  }

  var settings = {
    dots: false,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 2.25,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { accountFor } = useContext(SwitchProfile);
  const [Reply, setReply] = useState({
    isReply: false,
    parent_id: "",
    comment: "",
    ReplyData: [],
    CurrentReply: "",
  });
  const [ImageModal, setImageModal] = useState(false);
  const [ImageModalId, setImageModalId] = useState();
  const handleCloseModalImage = () => setImageModal(false);
  const user = Auth.getCurrentUser();
  const location = useLocation();
  const [ProductCommentTag, setProductCommentTag] = useState({
    has_more: false,
    items: [],
  });
  const [PostSellComTag, setPostSellComTag] = useState({
    has_more: false,
    items: [],
  });
  const [PostBuyComTag, setPostBuyComTag] = useState({
    has_more: false,
    items: [],
  });
  const [SocialComTag, setSocialComTag] = useState({
    has_more: false,
    items: [],
  });
  const descRef = useRef(null);
  const [FilterMarket, setFilterMarket] = useState({
    allMain: false,
    seller: false,
    buyer: false,
    allChild: false,
    buisness: false,
    social: false,
  });
  const [socialdata, setsocialdata] = useState({});
  const [productDetail, setProductDetail] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [headerData, setHeaderData] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [showRecievedCardModal, setshowRecievedCardModal] = useState(false);
  const handleCloseRecievedCardModal = () => setshowRecievedCardModal(false);
  const handleRecievedCardModal = () => setshowRecievedCardModal(true);

  const [showModalCred, setShowModalCred] = useState(false);
  const handleCloseModalCred = () => setShowModalCred(false);
  const handleShowModalCred = () => setShowModalCred(true);
  const [CredPolicyData, setCredPolicyData] = useState({});

  const [showModalCard, setshowModalCard] = useState(false);
  const handleclosecard = () => setshowModalCard(false);
  const handleshowcard = () => setshowModalCard(true);

  const [shareLink, setShareLink] = useState(false);
  const [HomeDatas, setHomeDatas] = useState({});
  const [goButton, setGoButton] = useState(false);
  const [CommentV, setComment] = useState({
    comment: "",
    comment_Id: "",
    child_Comment: "",
    parent_id: "",
  });
  const [sent, setSent] = useState({
    items: [],
  });
  const [timeline, setTimeline] = useState({
    items: [],
    has_more: false,
  });
  const [roleData, setRoleData] = useState();
  const [Update, setSupdate] = useState(false);
  const buyerRole = (roleData) => {
    setRoleData(roleData);
  };
  const gstRef = useRef();

  const [available_usersPage, setavailable_usersPage] = useState(0);
  const [IndPage, setIndPage] = useState(0);

  const [suggested_usersPage, setsuggested_usersPage] = useState(0);
  const [TimeLine_usersPage, setTimeLine_usersPage] = useState(0);
  const [categoryPage, setcategoryPage] = useState(0);
  const [subCatPage, setsubCatPage] = useState(0);
  const [FiltAvailable, setFiltAvailable] = useState(0);
  const [LoadingT, setLoadingT] = useState(false);
  const [LoadingTAvailable, setLoadingTAvailable] = useState(false);
  const [isShowMoreLessContent, setShowMoreLessContent] = useState(false);
  const [loggedUserDetails, setLoggedUserDetails] = useState(null);

  const navigate = useNavigate();
  const MarketUpdates = useRef(null);

  const [stateList, setStateList] = useState({
    items: [],
    has_more: false,
  });
  const [cityList, setCityList] = useState([]);
  const [productArray, setProductArray] = useState({
    items: [],
    has_more: false,
  });
  const [productSubCategoryArray, setProductSubCategoryArray] = useState({
    items: [],
    has_more: false,
  });
  const [availableIndustry, setAvailableIndustry] = useState({
    items: [],
    has_more: false,
  });
  const [CurrentComment, setCurrentComment] = useState({
    post_id: "",
    post_type: "",
    commentArray: [],
    has_more: false,
    page: 0,
    parent_id: "",
    child_Comment_List: [],
    child_Comment_Has_more: false,
  });
  const [SelectedCard, setSelectedCard] = useState("");
  const [replyUser, setReplyUser] = useState("");

  const [FilterAvail, setFilterAvail] = useState({
    search: "",
    state_Id: "",
    city_Id: "",
    industry_Id: "",
    category_id: "",
    subcategory_id: "",
    Filter: "",
    count: "",
  });
  const settingsPost = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [cardSelectedUser, setcardselecteduser] = useState("");
  const [data, setData] = useState({
    dataa: "",
  });
  const [available, setAvailable] = useState({
    items: [],
    has_more: false,
  });
  const [socialCard, setSocialCard] = useState({
    items: [],
    has_more: false,
  });
  const stateRef = useRef(null);
  const IndustryRef = useRef(null);
  const categoryRef = useRef(null);
  const selectInputRef = useRef(null);
  const selectCat = useRef(null);
  const [selectedSocialPost, setselectedSocialPost] = useState(0);
  const [socialcardListing, setsocialcardListing] = useState({
    items: [],
    has_more: false,
  });
  const [socialPostCardPage, setsocialPostCardPage] = useState(0);

  const [downloadLink, setDownloadLink] = useState(null);
  const [socialCardIndex, setsocialCardIndex] = useState(0);
  const [sendCard, setSendCard] = useState(false);
  const [connectionsUsers, setConnectionsUsers] = useState([]);

  const decapitalize = ([first, ...rest], upperRest = false) => {
    const lowercaseString =
      first.toLowerCase() +
      (upperRest ? rest.join("").toUpperCase() : rest.join(""));
    return lowercaseString.replaceAll(" ", "");
  };

  const fetchFriends = async () => {
    if (location?.state != null && location?.state != undefined) {
      // alert(location.state);
    }
    await get("/connection/listing?type=friends", getAuthConfig())
      .then((res) => {
        setConnectionsUsers(res.data.data.connections);
      })
      .catch((err) => {});
  };

  const fetchAllLoggedUserDetails = async () => {
    if (user?.slug) {
      const deCapsSlug = decapitalize(user?.slug);

      await get(`/user/profile/details/${deCapsSlug}`, getAuthConfig())
        .then((res) => {
          setLoggedUserDetails(res?.data?.data);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    fetchFriends();
  }, []);

  UseEffectOnce(() => {
    if (user?.slug) {
      fetchAllLoggedUserDetails();
    }
  });

  async function sendSocialCard() {
    try {
      setSendCard(true);
      const node = document.getElementById(`card-container${socialCardIndex}`); // Replace 'card-container' with the actual ID of the container you want to capture
      if (node) {
        domtoimage
          .toBlob(node)
          .then(async (blob) => {
            // Create a download link
            const url = window.URL.createObjectURL(blob);
            setDownloadLink(url);

            // Send the blob to the backend
            const formdata = new FormData();
            formdata.append("card", blob, "card.png"); // 'card.png' is the desired filename on the server
            const data = await post(
              `/social_post/${selectedSocialPost}/card/send`,
              formdata,
              getAuthConfig()
            );
            if (data.status === 200) {
              setSendCard(false);

              // Perform your API call here, and then update the items array
              const updatedItems = timeline.items.map((item) => {
                if (item?.id === selectedSocialPost) {
                  // Increment the cards property by 1
                  return {
                    ...item,
                    interactions: {
                      ...item?.interactions,
                      cards: item.interactions.cards + 1,
                    },
                  };
                }
                return item; // Return the original item if it's not the one to update
              });

              // Update the timeline state with the updated items
              setTimeline((prevState) => ({
                ...prevState,
                items: updatedItems,
              }));

              handleclosecard();
            }
          })
          .catch((error) => {
            console.error("Error generating image:", error);
          });
      }
    } catch (err) {
      setSendCard(true);
      toast.error(err?.response?.data?.message);
    }
  }

  async function removeCarditem() {
    // Perform your API call here, and then update the items array
    const updatedItems = timeline.items.map((item) => {
      if (item?.id === selectedSocialPost) {
        // Increment the cards property by 1
        return {
          ...item,
          interactions: {
            ...item?.interactions,
            cards: item.interactions.cards - 1,
          },
        };
      }
      return item; // Return the original item if it's not the one to update
    });

    // Update the timeline state with the updated items
    setTimeline((prevState) => ({ ...prevState, items: updatedItems }));
  }
  useEffect(() => {
    // window.scrollTo(0, 0);
    const header = document.querySelector("header");
    const postsHeader = document.querySelector(".posts-header");
    if (header && postsHeader) {
      postsHeader.style.top = header.offsetHeight;
      postsHeader.setAttribute("style", `top: ${header.offsetHeight}px`);
    }
  }, []);
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
    if (location?.state === "scrollToDiv") {
      // fetchMoreData();
      MarketUpdates.current.scrollIntoView({ behavior: "smooth" });
      location.state = undefined;
    } else {
    }
  });
  UseEffectOnce(() => {
    if (goButton === true) {
      handleFilterUsers();
    }
    // available_usersPages();
    // fetchMoreData();
  }, []);
  useEffect(() => {
    if (available_usersPage > 0) {
      available_usersPages();
    }
  }, [available_usersPage]);

  useEffect(() => {
    handleFilterUsers();
  }, [FiltAvailable]);
  useEffect(() => {
    if (categoryPage === 0) {
    } else {
      intialCat();
    }
  }, [categoryPage]);
  useEffect(() => {
    if (TimeLine_usersPage >= 1) {
      fetchMoreData();
    }
  }, [TimeLine_usersPage]);

  useEffect(() => {
    if (suggested_usersPage === 0) {
    } else {
      // suggest_usersPages();
    }
  }, [suggested_usersPage]);

  useEffect(() => {
    if (subCatPage === 0) {
    } else {
      searcSubCat(FilterAvail?.category_id);
    }
  }, [subCatPage]);

  useEffect(() => {
    if (IndPage === 0) {
    } else {
      Industry();
    }
  }, [IndPage]);

  UseEffectOnce(() => {
    HomeData();
  }, []);
  useEffect(() => {
    if (Update === true) {
      HomeData();
    }
    setSupdate(false);
  }, [Update]);

  useEffect(() => {
    if (CurrentComment?.page === 0) {
    } else {
      CommentListing(CurrentComment?.post_id, CurrentComment?.post_type);
    }
  }, [CurrentComment?.page]);
  useEffect(() => {
    if (FilterMarket?.seller === true) {
      fetchMoreData();
    }
  }, [FilterMarket?.seller]);
  useEffect(() => {
    if (FilterMarket?.buyer === true) {
      fetchMoreData();
    }
  }, [FilterMarket?.buyer]);
  useEffect(() => {
    if (FilterMarket?.buisness === true) {
      fetchMoreData();
    }
  }, [FilterMarket?.buisness]);
  useEffect(() => {
    if (FilterMarket?.social === true) {
      fetchMoreData();
    }
  }, [FilterMarket?.social]);
  useEffect(() => {
    if (FilterMarket?.allMain === true) {
      fetchMoreData();
    }
  }, [FilterMarket?.allMain]);
  useEffect(() => {
    if (FilterMarket?.allChild === true) {
      fetchMoreData();
    }
  }, [FilterMarket?.allChild]);
  const handleReset = () => {
    // e.preventDefault();
    setStateList((p) => ({ ...p, items: [] }));
    setProductSubCategoryArray((p) => ({
      ...p,
      items: [],
    }));
    setCityList([]);
    setAvailableIndustry((p) => ({ ...p, items: [] }));
    setProductArray((p) => ({ ...p, items: [] }));
    stateRef.current.commonProps.setValue("");
    IndustryRef.current.commonProps.setValue("");
    selectInputRef.current.commonProps.setValue("");
    categoryRef.current.commonProps.setValue("");
    selectCat.current.commonProps.setValue("");
    setFilterAvail((p) => ({ ...p, search: "" }));
    // setavailable_usersPage(1);

    available_usersPages(true);
  };

  // Function to toggle class
  function toggleClass() {
    // Get the element using querySelector
    const element = document.querySelector("#search-show1");
    const collepsebleEle = document.querySelector("#collapseSeller");

    // Toggle the class
    element.classList.toggle("search-show");
    if (!collepsebleEle.classList.contains("show")) {
      collepsebleEle.classList.toggle("show");
    }
  }

  function IntialState() {
    get("/general/region_listing?region_id=1")
      .then((response) => {
        const sortedData = response?.data?.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data by name in ascending order
          .map((item) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }));

        setStateList((p) => ({
          ...p,
          items: sortedData,
        }));

        setStateList((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  function City() {
    get(`/general/region_listing?region_id=${FilterAvail?.state_Id}`)
      .then((response) => {
        const sortedData = response.data.data
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort the data by name in ascending order
          .map((item) => ({
            ...item,
            name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          }));

        setCityList(sortedData);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }

  function intialCat() {
    post("/general/category_listing", {
      type: "product",
      parent_id: FilterAvail?.industry_Id ? FilterAvail?.industry_Id : "",
      page: categoryPage + 1,
    })
      .then((response) => {
        if (FilterAvail?.industry_Id && categoryPage === 0) {
          setProductArray((p) => ({
            ...p,
            items: response?.data?.data,
          }));
        } else {
          setProductArray((p) => ({
            ...p,
            items: productArray.items.concat(response?.data?.data),
          }));
        }

        setProductArray((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  function searcCat(newValue) {
    post("/general/category_listing", {
      type: "product",
      page: 1,
      search: newValue,
    })
      .then((response) => {
        setProductArray((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setProductArray((p) => ({ ...p, has_more: response?.data?.has_more }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }
  function searcSubCat(parent_id, newValue) {
    post("/general/category_listing", {
      type: "product_sub_category",
      per_page: 50,
      page: subCatPage + 0,
      search: newValue,
      parent_id: parent_id,
    })
      .then((response) => {
        if (subCatPage === 0) {
          setProductSubCategoryArray((p) => ({
            ...p,
            items: response?.data?.data,
          }));
        } else {
          setProductSubCategoryArray((p) => ({
            ...p,
            items: productSubCategoryArray.items.concat(response?.data?.data),
          }));
        }

        setProductSubCategoryArray((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
        // setProductSubCategoryArray(response.data.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "This is the error");
      });
  }

  function Industry() {
    post(
      "/general/category_listing",
      { type: "industry", page: IndPage + 1 }
      // { headers: { Authorization: `Bearer ${user.token}` } }
    ).then((response) => {
      if (IndPage === 0) {
        setAvailableIndustry((p) => ({
          ...p,
          items: response?.data?.data,
        }));
      } else {
        setAvailableIndustry((p) => ({
          ...p,
          items: availableIndustry.items.concat(response?.data?.data),
        }));
      }
      setAvailableIndustry((p) => ({
        ...p,
        has_more: response?.data?.has_more,
      }));
      // setAvailableIndustry(response.data.data);
    });
  }

  function searcIndus(newValue) {
    post(
      "/general/category_listing",
      { type: "industry", page: 1, search: newValue }
      // { headers: { Authorization: `Bearer ${user.token}` } }
    )
      .then((response) => {
        setAvailableIndustry((p) => ({
          ...p,
          items: response?.data?.data,
        }));
        setAvailableIndustry((p) => ({
          ...p,
          has_more: response?.data?.has_more,
        }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  const handleFilterUsers = async () => {
    setLoadingT(true);
    setGoButton(true);
    if (
      FilterAvail?.state_Id ||
      FilterAvail?.category_id ||
      FilterAvail?.city_Id ||
      FilterAvail?.industry_Id ||
      FilterAvail?.subcategory_id ||
      FilterAvail?.search != ""
    ) {
      await get(
        `/home/available_users?page=${FiltAvailable + 1}${
          FilterAvail?.state_Id === undefined || FilterAvail?.state_Id === ""
            ? ""
            : "&state_id=" + FilterAvail?.state_Id
        }${
          FilterAvail?.industry_Id != undefined
            ? "&industry_id=" + FilterAvail.industry_Id
            : ""
        }${
          FilterAvail?.category_id != undefined
            ? "&product_category_id=" + FilterAvail.category_id
            : ""
        }${
          FilterAvail?.subcategory_id != undefined
            ? "&product_sub_category_id=" + FilterAvail?.subcategory_id
            : ""
        }${FilterAvail?.search != "" ? "&search=" + FilterAvail?.search : ""}${
          FilterAvail?.city_Id != undefined
            ? "&city_id=" + FilterAvail?.city_Id
            : ""
        }`,
        getAuthConfig()
      )
        .then((response) => {
          setLoadingT(false);
          if (FiltAvailable === 0) {
            setAvailable((p) => ({
              ...p,
              items: response?.data?.data?.items,
            }));
            setAvailable((p) => ({
              ...p,
              has_more: response?.data?.data?.has_more,
            }));
          } else {
            setAvailable((p) => ({
              ...p,
              items: available.items.concat(response?.data?.data?.items),
            }));
            setAvailable((p) => ({
              ...p,
              has_more: response?.data?.data?.has_more,
            }));
          }
          setFilterAvail((p) => ({ ...p, count: response?.data?.data?.count }));
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
    const element = document.getElementById("search-expand");
    if (element.className.includes("collapsed")) {
      element.click();
    }
  };

  async function available_usersPages(reset) {
    let url;
    setLoadingTAvailable(true);
    if (reset) {
      url = `/home/available_users?page=1`;
    } else {
      url = `/home/available_users?page=${available_usersPage + 1}`;
    }

    await get(url, getAuthConfig())
      .then((response) => {
        setLoadingTAvailable(false);

        if (reset) {
          setAvailable((p) => ({
            ...p,
            items: response?.data?.data?.items,
          }));
        } else {
          if (available_usersPage === 0) {
            setAvailable((p) => ({
              ...p,
              items: response?.data?.data?.items,
            }));
          } else {
            setAvailable((p) => ({
              ...p,
              items: available.items.concat(response?.data?.data?.items),
            }));
          }
        }

        setAvailable((p) => ({
          ...p,
          has_more: response?.data?.data?.has_more,
        }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function HomeData() {
    try {
      const data = await get("/home", getAuthConfig());
      if (data.status === 200) {
        setHomeDatas(data.data.data);
        setAvailable((p) => ({
          ...p,
          items: data?.data?.data?.available_users?.items,
        }));
        if (data?.data?.data?.available_users?.count > 5) {
          setAvailable((p) => ({ ...p, has_more: true }));
        } else {
          setAvailable((p) => ({ ...p, has_more: false }));
        }
        setTimeline((p) => ({
          ...p,
          items: data?.data?.data?.timeline?.items,
        }));
        if (data?.data?.data?.timeline?.has_more === true) {
          setTimeline((p) => ({ ...p, has_more: true }));
        } else {
          setTimeline((p) => ({ ...p, has_more: true }));
        }

        localStorage.setItem("PENDREQ", data?.data?.data?.pending_request);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  }
  function UpdateTrue() {
    setSupdate(true);
    descRef?.current?.blur();
  }

  const createThread = async (item) => {
    const formdata = new FormData();
    formdata.append("to_user_id", item.user.id);
    await postwithOu("/chat/init_thread", getAuthConfig(), formdata)
      .then((res) => {
        navigate("chat-room", {
          state: {
            id:
              res?.data?.data?.to_user_id === user?.id
                ? res?.data?.data?.user_id
                : res?.data?.data?.to_user_id,
          },
        });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  async function sentConnectionRequest(User_id) {
    const formdata = new FormData();
    formdata.append("user_id", User_id);
    const data = await postwithOu("/connection/send", getAuthConfig(), formdata)
      .then((datta) => {
        if (datta.status === 200) {
          setSent((p) => ({
            ...p,
            items: sent.items.concat(User_id),
          }));
        }
      })
      .catch((err) => {
        if (err.response.message === 400) {
          swal("Warning", "Request Already sent to this user ", "warning");
        } else {
          toast.error(err?.response?.data?.message);
        }
      });
  }

  async function fetchMoreData() {
    setLoadingT(true);

    if (
      FilterMarket?.buyer === true ||
      FilterMarket?.seller === true ||
      FilterMarket?.buisness ||
      FilterMarket?.social
    ) {
      let url;
      if (FilterMarket?.buyer === true) {
        url = `/home/timeline?page=${TimeLine_usersPage + 1}&user_type=buyer`;
      } else if (FilterMarket?.seller === true) {
        url = `/home/timeline?page=${TimeLine_usersPage + 1}&user_type=seller`;
      }

      if (FilterMarket?.buisness === true) {
        url = `/home/timeline?page=${TimeLine_usersPage + 1}&post_type=product`;
      } else if (FilterMarket?.social === true) {
        url = `/home/timeline?page=${TimeLine_usersPage + 1}&post_type=social`;
      }

      if (FilterMarket?.buisness === true && FilterMarket?.buyer === true) {
        url = `/home/timeline?page=${
          TimeLine_usersPage + 1
        }&user_type=buyer&post_type=product`;
      } else if (
        FilterMarket?.social === true &&
        FilterMarket?.buyer === true
      ) {
        url = `/home/timeline?page=${
          TimeLine_usersPage + 1
        }&user_type=buyer&post_type=social`;
      } else if (
        FilterMarket?.buisness === true &&
        FilterMarket?.seller === true
      ) {
        url = `/home/timeline?page=${
          TimeLine_usersPage + 1
        }&user_type=seller&post_type=product`;
      } else if (
        FilterMarket?.social === true &&
        FilterMarket?.seller === true
      ) {
        url = `/home/timeline?page=${
          TimeLine_usersPage + 1
        }&user_type=seller&post_type=social`;
      }

      await get(url, getAuthConfig())
        .then((response) => {
          if (TimeLine_usersPage === 0) {
            setTimeline((p) => ({
              ...p,
              items: response?.data?.data?.items,
            }));
          } else {
            setTimeline((p) => ({
              ...p,
              items: timeline.items.concat(response?.data?.data?.items),
            }));
          }
          setTimeline((p) => ({
            ...p,
            has_more: response?.data?.data?.has_more,
          }));
          setLoadingT(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      await get(
        `/home/timeline?page=${TimeLine_usersPage + 1}`,
        getAuthConfig()
      )
        .then((response) => {
          if (TimeLine_usersPage === 0) {
            setTimeline((p) => ({
              ...p,
              items: response?.data?.data?.items,
            }));
          } else {
            setTimeline((p) => ({
              ...p,
              items: timeline.items.concat(response?.data?.data?.items),
            }));
          }
          setTimeline((p) => ({
            ...p,
            has_more: response?.data?.data?.has_more,
          }));
          setLoadingT(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  }

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && timeline?.has_more) {
          setTimeLine_usersPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [timeline?.has_more]
  );

  async function reaction(Post_Id, type, comment_Id, unlike, index, reaction) {
    const formdata = new FormData();

    if (type === "social_post") {
      formdata.append("social_post_id", Post_Id);
    } else if (type === "product") {
      formdata.append("product_id", Post_Id);
    }

    if (comment_Id) {
      formdata.append("comment_id", comment_Id);
    }
    formdata.append("type", type);
    if (unlike) {
      formdata.append("reaction", "");
    } else {
      formdata.append("reaction", reaction);
    }

    await postwithOu("/general/reaction", getAuthConfig(), formdata)
      .then((datta) => {
        if (datta.status === 200) {
          // timeline?.items[index]?.interactions?.reaction = "like";

          let arr = [];
          arr = timeline?.items;

          if (arr[index].interactions.reaction != null) {
            if (unlike) {
              arr[index].interactions.reaction = null;
              arr[index].interactions.reactions =
                arr[index].interactions.reactions - 1;
            } else {
              arr[index].interactions.reaction = reaction;
              // arr[index].interactions.reactions =
              //   arr[index].interactions.reactions + 1;
            }
          } else {
            if (unlike) {
              arr[index].interactions.reaction = null;
              arr[index].interactions.reactions =
                arr[index].interactions.reactions - 1;
            } else {
              arr[index].interactions.reaction = reaction;
              arr[index].interactions.reactions =
                arr[index].interactions.reactions + 1;
            }
          }

          setTimeline((p) => ({ ...p, items: arr }));
          // fetchMoreData();
          // func(index)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function reactionChild(Post_Id, type, comment_Id, unlike, ind, child) {
    const formdata = new FormData();

    if (type === "social_post") {
      formdata.append("social_post_id", Post_Id);
    } else if (type === "product") {
      formdata.append("product_id", Post_Id);
    }

    if (comment_Id) {
      formdata.append("comment_id", comment_Id);
    }
    formdata.append("type", "comment");
    if (unlike) {
      formdata.append("reaction", "");
    } else {
      formdata.append("reaction", "like");
    }

    const data = await postwithOu(
      "/general/reaction",
      getAuthConfig(),
      formdata
    )
      .then((datta) => {
        if (datta.status === 200) {
          // fetchMoreData();

          if (child) {
            let arr = [];
            arr = CurrentComment?.child_Comment_List;
            if (unlike) {
              arr[ind].interactions.is_like = 0;
              arr[ind].interactions.reactions =
                arr[ind].interactions.reactions - 1;
            } else {
              arr[ind].interactions.is_like = 1;
              arr[ind].interactions.reactions =
                arr[ind].interactions.reactions + 1;
            }
            setCurrentComment((p) => ({ ...p, child_Comment_List: arr }));
            ChildCommentListing(CurrentComment?.parent_id, Post_Id, type);
          } else {
            let arr = [];
            arr = CurrentComment?.commentArray;
            if (unlike) {
              arr[ind].interactions.is_like = 0;
              arr[ind].interactions.reactions =
                arr[ind].interactions.reactions - 1;
            } else {
              arr[ind].interactions.is_like = 1;
              arr[ind].interactions.reactions =
                arr[ind].interactions.reactions + 1;
            }
            setCurrentComment((p) => ({ ...p, commentArray: arr }));
          }
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function Comment(Post_Id, type, parent_id, index) {
    const formdata = new FormData();
    if (type === "social_post") {
      formdata.append("social_post_id", Post_Id);
    } else if (type === "product") {
      formdata.append("product_id", Post_Id);
    }
    if (parent_id) {
      formdata.append("parent_id", parent_id);
      formdata.append("comment", CommentV?.child_Comment);
    } else {
      formdata.append("comment", CommentV?.comment);
    }

    formdata.append("type", type);
    // formdata.append("comment", CommentV?.comment);

    await postwithOu("/general/comment", getAuthConfig(), formdata)
      .then((datta) => {
        if (datta.status === 200) {
          // fetchMoreData();
          CommentListing(Post_Id, type);

          if (parent_id) {
            ChildCommentListing(parent_id, Post_Id, type);
            setComment((p) => ({ ...p, child_Comment: "" }));
          }

          let arr = [];
          arr = timeline?.items;

          if (arr[index].interactions.comments != null) {
            arr[index].interactions.comments =
              arr[index].interactions.comments + 1;
          }

          setTimeline((p) => ({ ...p, items: arr }));

          setComment((p) => ({ ...p, comment: "" }));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  function clear() {
    categoryRef.current.commonProps.setValue("");
  }

  async function CommentListing(id, type) {
    // alert(CurrentComment?.post_id)
    let url;
    if (type === "social_post") {
      url = `/general/comment/listing?page=${
        CurrentComment?.page + 1
      }&social_post_id=${id}&type=${type}`;
    } else {
      url = `/general/comment/listing?page=${
        CurrentComment?.page + 1
      }&product_id=${id}&type=product`;
    }

    await get(url, getAuthConfig())
      .then((response) => {
        if (CurrentComment?.page === 0) {
          setCurrentComment((p) => ({
            ...p,
            commentArray: response.data.data.items,
          }));
        } else {
          setCurrentComment((p) => ({
            ...p,
            commentArray: CurrentComment.commentArray.concat(
              response.data.data.items
            ),
          }));
        }

        setCurrentComment((p) => ({
          ...p,
          has_more: response.data.data.has_more,
        }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function ChildCommentListing(parent_id, product_id, type) {
    let url;
    if (type === "social_post") {
      url = `/general/comment/listing?social_post_id=${product_id}&parent_id=${parent_id}&type=${type}`;
    } else {
      url = `/general/comment/listing?product_id=${product_id}&parent_id=${parent_id}&type=product`;
    }
    await get(url, getAuthConfig())
      .then((response) => {
        setCurrentComment((p) => ({
          ...p,
          child_Comment_List: response.data.data.items,
        }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function DeleteComment(Comment_Id, product_Id, type, is_child, index) {
    try {
      let url;
      url = `/general/comment/delete/${Comment_Id}}`;
      await get(url, getAuthConfig()).then((response) => {
        if (is_child) {
          ChildCommentListing(CurrentComment?.parent_id, product_Id, type);
        } else {
          CommentListing(product_Id, type);
          let arr = [];
          arr = timeline?.items;
          arr[index].interactions.comments =
            arr[index].interactions.comments - 1;
          setTimeline((p) => ({ ...p, items: arr }));
        }
      });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  }

  const handleDeletePost = async (id, index) => {
    await get(`/product/delete/${id}`, getAuthConfig())
      .then((res) => {
        let arr = [];
        arr = timeline?.items;
        arr.splice(index, 1);
        setTimeline((p) => ({ ...p, items: arr }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDeleteSocial = async (id, index) => {
    await get(`/social_post/destroy/${id}`, getAuthConfig())
      .then((res) => {
        let arr = [];
        arr = timeline?.items;
        arr.splice(index, 1);
        setTimeline((p) => ({ ...p, items: arr }));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const CommentTag = async (type) => {
    let url;

    if (type === "product") {
      url = `/general/content?search&type=product_comment`;
    } else if (type === "post_to_sell") {
      url = `/general/content?search&type=post_sell_comment`;
    } else if (type === "post_to_buy") {
      url = `/general/content?search&type=post_buy_comment`;
    } else if (type === "social_post") {
      url = `/general/content?search&type=social_post_comment`;
    }
    await get(url, getAuthConfig())
      .then((res) => {
        if (type === "product") {
          setProductCommentTag((p) => ({ ...p, items: res.data.data }));
        } else if (type === "post_to_sell") {
          setPostSellComTag((p) => ({ ...p, items: res.data.data }));
        } else if (type === "post_to_buy") {
          setPostBuyComTag((p) => ({ ...p, items: res.data.data }));
        } else if (type === "social_post") {
          setSocialComTag((p) => ({ ...p, items: res.data.data }));
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  function momentT(ed) {
    if (Math.abs(moment(ed).diff()) < 5000) {
      return "just now";
    }
    return moment(ed).fromNow();
  }

  async function ReplyToMyPost(ProductId, productType, parent_id) {
    const formdata = new FormData();
    if (productType === "social_post") {
      formdata.append("social_post_id", ProductId);
    } else if (productType === "product") {
      formdata.append("product_id", ProductId);
    }
    formdata.append("parent_id", parent_id);
    formdata.append("type", productType);
    formdata.append("comment", Reply?.comment);
    await postwithOu("/general/comment", getAuthConfig(), formdata)
      .then((datta) => {
        ReplyListing(productType, ProductId, parent_id);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  async function ReplyListing(type, product_id, parent_id) {
    let url;
    if (type === "social_post") {
      url = `/general/comment/listing?social_post_id=${product_id}&type=${type}&parent_id=${parent_id}`;
    } else {
      url = `/general/comment/listing?product_id=${product_id}&type=product&parent_id=${parent_id}`;
    }

    await get(url, getAuthConfig())
      .then((response) => {
        setReply((p) => ({ ...p, ReplyData: response?.data?.data?.items }));
        setReplyUser(response?.data?.data?.items.map((item) => item.user));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }

  const headerDataCall = (data) => {
    setHeaderData(data);
  };

  async function getSocialCard() {
    try {
      const data = await get("/social_post/cards?page=1", getAuthConfig());

      if (data.status === 200) {
        setSocialCard((p) => ({
          ...p,
          items: data?.data?.data?.list,
        }));
        setSocialCard((p) => ({
          ...p,
          has_more: data?.data?.data?.has_more,
        }));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
  UseEffectOnce(() => {
    getSocialCard();
  }, []);

  const firstPostRef = useRef(null);

  const getTabStyle = (isActive) => ({
    backgroundColor: isActive
      ? "#ffc107" // Active tab color
      : darkMode
      ? "#1c2226" // Dark mode inactive tab color
      : "#fff", // Light mode inactive tab color
    color: isActive ? "#000" : darkMode ? "#fff" : "#000", // Text color
    transition: "background-color 0.3s, color 0.3s",
  });

  return (
    <>
      <ToastContainer />
      <Header
        home
        buyerRole={buyerRole}
        UpdateTrue={UpdateTrue}
        Buyer={accountFor?.buyer}
        headerDataCall={headerDataCall}
      />
      <div className="grey-bg">
        <div className="container-fluid">
          <div className="layout-grid-box">
            <LeftSideBar
              childFunc={accountFor?.buyer}
              loggedUserDetails={loggedUserDetails}
            />
            <div className="layout-grid">
              <div className="card top-buttons">
                {headerData ? (
                  <>
                    <Skeleton height={100} />
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                      gap: "20px",
                    }}
                  >
                    {accountFor?.buyer === true ? (
                      <> </>
                    ) : (
                      <>
                        {" "}
                        <div className="flex-item">
                          {user?.verified_at === null &&
                          user?.is_seller === 1 ? (
                            <Link
                              to={"/"}
                              style={{
                                borderRadius: "5px",
                                padding: "8px 40px",
                                width: "100%",
                              }}
                              className="button button-primary button-primary--bordered"
                              onClick={(e) => {
                                e.preventDefault();
                                handleShowModal();
                              }}
                            >
                              <SellerIcon />
                              Post to Sell
                            </Link>
                          ) : (
                            <Link
                              style={{
                                borderRadius: "5px",
                                padding: "8px 40px",
                                width: "100%",
                              }}
                              to={"/create-post-sell"}
                              className="button button-primary button-primary--bordered"
                            >
                              <SellerIcon />
                              Post to Sell
                            </Link>
                          )}
                        </div>
                      </>
                    )}

                    <div className="flex-item">
                      {user?.verified_at === null && user?.is_seller === 1 ? (
                        <Link
                          to={"/"}
                          style={{
                            borderRadius: "5px",
                            padding: "8px 40px",
                            width: "100%",
                          }}
                          className="button button-primary button-primary--bordered"
                          onClick={(e) => {
                            e.preventDefault();
                            handleShowModal();
                          }}
                        >
                          <BuyerIcon />
                          Post to Buy
                        </Link>
                      ) : (
                        <Link
                          style={{
                            borderRadius: "5px",
                            padding: "8px 40px",
                            width: "100%",
                          }}
                          to={"/create-post-buy"}
                          className="button button-primary button-primary--bordered"
                        >
                          <BuyerIcon />
                          Post to Buy
                        </Link>
                      )}
                    </div>
                    <div className="flex-item">
                      {user?.verified_at === null &&
                      user?.is_seller === 1 &&
                      user?.is_seller === 1 ? (
                        <button
                          style={{
                            borderRadius: "5px",
                            padding: "8px 40px",
                            width: "100%",
                          }}
                          className="button button-primary button-primary--bordered"
                          onClick={(e) => {
                            e.preventDefault();
                            handleShowModal();
                          }}
                        >
                          <SocialIcon />
                          Post to Social
                        </button>
                      ) : (
                        <Link
                          to={""}
                          ref={descRef}
                          className="button button-primary button-primary--bordered"
                          data-toggle="modal"
                          data-target="#PostSocialModal"
                          onClick={(e) => {
                            e.preventDefault();
                            descRef.current.blur();
                          }}
                          style={{
                            borderRadius: "5px",
                            padding: "8px 40px",
                            width: "100%",
                          }}
                        >
                          <SocialIcon />
                          Post to Social
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* <div className='campaign-ads'>
                <div className='campaign-ads-title'><h6>Run Campaign</h6></div>
                <Slider {...settings}>
                  <div className='slide-item'>
                    <div className='card featured'>
                      <div className='card-body'>
                        <div className='user-profile'>
                          <div className='user-profile-image'><img src={profilePic} className='profile-pic' /></div>
                          <div className='user-profile-content'>
                            <div className='user-profile-name'>
                              <h6>Makita Panchal<span>(Profile ID: 302101)</span></h6>
                              <p>Owner at mehta brothers</p>
                            </div>
                            <div className='featured-tag'><img src={featuredTagIcon} />Featured Profile</div>
                          </div>
                        </div>
                        <div className='featured-details'>
                          <ul>
                            <li><span className='featured-details--label'>Location</span>:<span className='featured-details--text'>Ahmedabad</span></li>
                            <li><span className='featured-details--label'>GST</span>:<span className='featured-details--text'>NHD9F987G0834</span></li>
                            <li><span className='featured-details--label'>Deals Closed</span>:<span className='featured-details--text'>32</span></li>
                            <li><span className='featured-details--label'>Followers</span>:<span className='featured-details--text'>234</span></li>
                          </ul>
                          <div className='featured-details--highlights'>
                            <strong>Industry: Industrial Supplies</strong>
                          </div>
                          <div className='featured-details--highlights'>
                            <strong>Deals In</strong>
                            <p>Abrasives, Power Tools, Hand To-
                              ols, and Industrial Safty Tools</p>
                          </div>
                        </div>
                        <div className='featured-actions'>
                          <button className='button button-primary button-connect'><ConnectIcon />Follow</button>
                          <button className='button button-primary button-connect'><MeetingRoomIcon />Meeting Room</button>
                          <button className='button button-blue button-connect'><PostShareIcon /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='slide-item'>
                    <div className='card featured'>
                      <div className='card-body'>
                        <div className='user-profile'>
                          <div className='user-profile-image'><img src={profilePic} className='profile-pic' /></div>
                          <div className='user-profile-content'>
                            <div className='user-profile-name'>
                              <h6>Makita Panchal<span>(Profile ID: 302101)</span></h6>
                              <p>Owner at mehta brothers</p>
                            </div>
                            <div className='featured-tag'><img src={featuredTagIcon} />Featured Profile</div>
                          </div>
                        </div>
                        <div className='featured-details'>
                          <ul>
                            <li><span className='featured-details--label'>Location</span>:<span className='featured-details--text'>Ahmedabad</span></li>
                            <li><span className='featured-details--label'>GST</span>:<span className='featured-details--text'>NHD9F987G0834</span></li>
                            <li><span className='featured-details--label'>Deals Closed</span>:<span className='featured-details--text'>32</span></li>
                            <li><span className='featured-details--label'>Followers</span>:<span className='featured-details--text'>234</span></li>
                          </ul>
                          <div className='featured-details--highlights'>
                            <strong>Industry: Industrial Supplies</strong>
                          </div>
                          <div className='featured-details--highlights'>
                            <strong>Deals In</strong>
                            <p>Abrasives, Power Tools, Hand To-
                              ols, and Industrial Safty Tools</p>
                          </div>
                        </div>
                        <div className='featured-actions'>
                          <button className='button button-primary button-connect'><ConnectIcon />Follow</button>
                          <button className='button button-primary button-connect'><MeetingRoomIcon />Meeting Room</button>
                          <button className='button button-blue button-connect'><PostShareIcon /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='slide-item'>
                    <div className='card featured'>
                      <div className='card-body'>
                        <div className='user-profile'>
                          <div className='user-profile-image'><img src={profilePic} className='profile-pic' /></div>
                          <div className='user-profile-content'>
                            <div className='user-profile-name'>
                              <h6>Makita Panchal<span>(Profile ID: 302101)</span></h6>
                              <p>Owner at mehta brothers</p>
                            </div>
                            <div className='featured-tag'><img src={featuredTagIcon} />Featured Profile</div>
                          </div>
                        </div>
                        <div className='featured-details'>
                          <ul>
                            <li><span className='featured-details--label'>Location</span>:<span className='featured-details--text'>Ahmedabad</span></li>
                            <li><span className='featured-details--label'>GST</span>:<span className='featured-details--text'>NHD9F987G0834</span></li>
                            <li><span className='featured-details--label'>Deals Closed</span>:<span className='featured-details--text'>32</span></li>
                            <li><span className='featured-details--label'>Followers</span>:<span className='featured-details--text'>234</span></li>
                          </ul>
                          <div className='featured-details--highlights'>
                            <strong>Industry: Industrial Supplies</strong>
                          </div>
                          <div className='featured-details--highlights'>
                            <strong>Deals In</strong>
                            <p>Abrasives, Power Tools, Hand To-
                              ols, and Industrial Safty Tools</p>
                          </div>
                        </div>
                        <div className='featured-actions'>
                          <button className='button button-primary button-connect'><ConnectIcon />Follow</button>
                          <button className='button button-primary button-connect'><MeetingRoomIcon />Meeting Room</button>
                          <button className='button button-blue button-connect'><PostShareIcon /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='slide-item'>
                    <div className='card featured'>
                      <div className='card-body'>
                        <div className='user-profile'>
                          <div className='user-profile-image'><img src={profilePic} className='profile-pic' /></div>
                          <div className='user-profile-content'>
                            <div className='user-profile-name'>
                              <h6>Makita Panchal<span>(Profile ID: 302101)</span></h6>
                              <p>Owner at mehta brothers</p>
                            </div>
                            <div className='featured-tag'><img src={featuredTagIcon} />Featured Profile</div>
                          </div>
                        </div>
                        <div className='featured-details'>
                          <ul>
                            <li><span className='featured-details--label'>Location</span>:<span className='featured-details--text'>Ahmedabad</span></li>
                            <li><span className='featured-details--label'>GST</span>:<span className='featured-details--text'>NHD9F987G0834</span></li>
                            <li><span className='featured-details--label'>Deals Closed</span>:<span className='featured-details--text'>32</span></li>
                            <li><span className='featured-details--label'>Followers</span>:<span className='featured-details--text'>234</span></li>
                          </ul>
                          <div className='featured-details--highlights'>
                            <strong>Industry: Industrial Supplies</strong>
                          </div>
                          <div className='featured-details--highlights'>
                            <strong>Deals In</strong>
                            <p>Abrasives, Power Tools, Hand To-
                              ols, and Industrial Safty Tools</p>
                          </div>
                        </div>
                        <div className='featured-actions'>
                          <button className='button button-primary button-connect'><ConnectIcon />Follow</button>
                          <button className='button button-primary button-connect'><MeetingRoomIcon />Meeting Room</button>
                          <button className='button button-blue button-connect'><PostShareIcon /></button>
                        </div>
                      </div>
                    </div>
                  </div>

                </Slider>
              </div> */}
              <div className="card seller-list">
                <div className="search-market">
                  <div className="card-header">
                    <h6>
                      <span>Search Market</span>
                    </h6>
                    <h6>
                      <span
                        className="country-tag"
                        style={{ borderRadius: "5px", padding: "5px 8px" }}
                      >
                        India
                      </span>
                      <div
                        style={{ borderRadius: "5px" }}
                        className="search-box"
                      >
                        <input
                          type="text"
                          className="form-input"
                          placeholder="Search"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleFilterUsers();
                              setFilterAvail((p) => ({ ...p, Filter: true }));
                            }
                          }}
                          style={{ borderRadius: "5px" }}
                          value={FilterAvail?.search}
                          onChange={(e) => {
                            e.preventDefault();
                            setFilterAvail((p) => ({
                              ...p,
                              search: e.target.value,
                            }));
                          }}
                        />
                        <button
                          className="search-button"
                          style={{
                            borderRadius: "5px",
                            background: darkMode ? "#1C2226" : "#fff",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleFilterUsers();
                            setFilterAvail((p) => ({ ...p, Filter: true }));
                          }}
                        >
                          <SearchIcon />
                        </button>
                      </div>
                    </h6>

                    <div
                      className="reset-icon button button-primary"
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #bdbdbd",
                        color: darkMode ? "#fff" : "#666666",
                        background: darkMode ? "#1C2226" : "#fff",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleReset();

                        setFilterAvail((p) => ({ ...p, Filter: false }));
                      }}
                    >
                      Reset
                    </div>

                    <div
                      className="button button-primary"
                      id="search-btn-toggle"
                      style={{
                        background: "#fff",
                        borderRadius: "5px",
                        border: "1px solid #bdbdbd",
                        color: "#666666",
                        background: darkMode ? "#1C2226" : "#fff",
                        color: darkMode ? "#fff" : "#666666",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        toggleClass();
                      }}
                    >
                      Advance&nbsp;Search
                    </div>

                    <a
                      className="collapse-button collapsed"
                      id="search-expand"
                      data-toggle="collapse"
                      href="#collapseSeller"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseSeller"
                    >
                      <ArrowDownIcon />
                    </a>
                  </div>
                </div>
                <div className="collapse show" id="collapseSeller">
                  <div className="card search-market" id="search-show1">
                    <div
                      className="card-body pt-0 collapse desktop-view"
                      id="collapseSearch"
                    >
                      <form className="flex-box">
                        <div className="flex-item">
                          <label className="form-label">State</label>
                          <Select
                            ref={stateRef}
                            placeholder="Select State"
                            id="State"
                            onFocus={(e) => {
                              e.preventDefault();

                              IntialState();
                            }}
                            options={
                              stateList?.items &&
                              stateList?.items?.map(function (productArray) {
                                return {
                                  value: productArray.id,
                                  label: productArray.name,
                                };
                              })
                            }
                            onKeyDown={(e) => {
                              // searcCat(e.target.value);
                            }}
                            onChange={(e) => {
                              clear();
                              setFilterAvail((p) => ({
                                ...p,
                                state_Id: e.value,
                              }));
                              setavailable_usersPage(0);
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                            styles={customStyles(darkMode, true)}
                          ></Select>
                        </div>
                        <div className="flex-item">
                          <label className="form-label">City</label>
                          <Select
                            ref={categoryRef}
                            placeholder="Select city"
                            id="city"
                            onFocus={(e) => {
                              e.preventDefault();
                              City();
                            }}
                            options={
                              cityList &&
                              cityList.map(function (productArray) {
                                return {
                                  value: productArray.id,
                                  label: productArray.name,
                                };
                              })
                            }
                            onKeyDown={(e) => {
                              // searcCat(e.target.value);
                            }}
                            onChange={(e) => {
                              setFilterAvail((p) => ({
                                ...p,
                                city_Id: e.value,
                              }));
                              setavailable_usersPage(0);
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                            styles={customStyles(darkMode, true)}
                          ></Select>
                        </div>
                        <div className="flex-item">
                          <label className="form-label">
                            Industry Category
                          </label>
                          <Select
                            ref={IndustryRef}
                            placeholder="Select"
                            id="industry"
                            onFocus={(e) => {
                              e.preventDefault();
                              Industry();
                            }}
                            options={
                              availableIndustry?.items &&
                              availableIndustry?.items.map(function (
                                productArray
                              ) {
                                return {
                                  value: productArray.id,
                                  label: productArray.name,
                                };
                              })
                            }
                            onKeyDown={(e) => {
                              setTimeout(() => {
                                searcIndus(e.target.value);
                              }, 2000);
                            }}
                            onChange={(e) => {
                              setFilterAvail((p) => ({
                                ...p,
                                industry_Id: e.value,
                              }));
                              setProductArray((p) => ({ ...p, items: [] }));
                              selectCat.current.commonProps.setValue("");
                              setProductSubCategoryArray((p) => ({
                                ...p,
                                items: [],
                              }));
                              setavailable_usersPage(0);
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                            onMenuScrollToBottom={(e) => {
                              if (availableIndustry?.has_more === true) {
                                let p = IndPage + 1;
                                setIndPage(p);
                              }
                            }}
                            styles={customStyles(darkMode, true)}
                          ></Select>
                        </div>
                        <div className="flex-item">
                          <label className="form-label">Product Category</label>

                          <Select
                            placeholder="Select Category"
                            ref={selectCat}
                            id="category"
                            onFocus={(e) => {
                              e.preventDefault();
                              if (FilterAvail?.industry_Id != "") {
                                intialCat();
                              }
                            }}
                            options={
                              productArray?.items &&
                              productArray?.items.map(function (te) {
                                return {
                                  value: te.id,
                                  label: te.name,
                                };
                              })
                            }
                            onKeyDown={(e) => {
                              setTimeout(() => {
                                searcCat(e.target.value);
                              }, 2000);
                            }}
                            onChange={(e) => {
                              setFilterAvail((p) => ({
                                ...p,
                                category_id: e.value,
                              }));
                              selectInputRef.current.commonProps.setValue("");
                              setavailable_usersPage(0);
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                            onMenuScrollToBottom={(e) => {
                              if (productArray?.has_more === true) {
                                let p = categoryPage + 1;
                                setcategoryPage(p);
                              }
                            }}
                            styles={customStyles(darkMode, true)}
                          ></Select>
                        </div>
                        <div className="flex-item">
                          <label className="form-label">Sub Category</label>
                          <Select
                            ref={selectInputRef}
                            id="subCategory"
                            placeholder="Select Sub Category"
                            options={productSubCategoryArray?.items.map(
                              function (sub_category) {
                                return {
                                  value: sub_category.id,
                                  label: sub_category.name,
                                };
                              }
                            )}
                            onFocus={(e) => {
                              e.preventDefault();
                              if (
                                FilterAvail?.category_id != "" &&
                                FilterAvail?.category_id != undefined
                              ) {
                                searcSubCat(FilterAvail?.category_id);
                              }
                            }}
                            onKeyDown={(e) => {
                              setTimeout(() => {
                                searcSubCat(
                                  FilterAvail?.category_id,
                                  e.target.value
                                );
                              }, 2000);
                            }}
                            // onInputChange={(newValue) => {
                            //   searcSubCat(newValue, ProductData?.category_id);
                            // }}
                            onChange={(e) => {
                              setFilterAvail((p) => ({
                                ...p,
                                subcategory_id: e.value,
                              }));
                              setavailable_usersPage(0);
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                            }}
                            onMenuScrollToBottom={(e) => {
                              if (productSubCategoryArray?.has_more === true) {
                                let p = subCatPage + 1;
                                setsubCatPage(p);
                              }
                            }}
                            styles={customStyles(darkMode, true)}
                          ></Select>
                        </div>
                        <div className="form-button flex-item">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleFilterUsers();
                              setFilterAvail((p) => ({ ...p, Filter: true }));
                            }}
                            className="button button-primary"
                            type="button"
                            style={{ borderRadius: "5px" }}
                          >
                            Go
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>

                  {headerData ? (
                    <>
                      <Skeleton height={700} />
                    </>
                  ) : (
                    <>
                      <div className="card-header">
                        <h6>
                          Nos. of{" "}
                          {accountFor?.buyer === true ? "Seller" : "Buyer"}{" "}
                          Available{" "}
                          <strong>
                            (
                            {FilterAvail?.Filter === true
                              ? FilterAvail?.count
                              : HomeDatas?.available_users?.count}
                            )
                          </strong>
                        </h6>
                      </div>
                      <div className="card-body pt-0">
                        <div className="card-lists">
                          {available?.items &&
                            available?.items.length > 0 &&
                            available?.items.map((item, index) => {
                              return (
                                <>
                                  <div className="card-lists-item user-profile">
                                    <Link
                                      to={`/edit-profile-seller/${item.slug}`}
                                    >
                                      <div className="user-profile-image">
                                        <img
                                          alt="profile_img"
                                          src={item?.profile_image}
                                          className="profile-pic"
                                        />
                                      </div>
                                    </Link>
                                    <div className="user-profile-content">
                                      <div className="user-profile-name">
                                        <h6>
                                          {item?.name}
                                          <span>(Profile ID: {item?.id})</span>
                                        </h6>
                                        <p>
                                          {/* {item?.company_details?.designation != null ?  "at" : ""}  */}
                                          {/* {item.i_am === "individual" ? (item?.company_details?.name) : (item?.i_am)(item?.company_details?.name)}  */}
                                          {item?.company_details?.name}{" "}
                                        </p>
                                        <p>
                                          Product Category:{" "}
                                          <span>
                                            {item?.categories &&
                                            item?.categories.length <= 0
                                              ? "N/A"
                                              : ""}
                                            {item?.categories &&
                                              item?.categories.length > 0 &&
                                              item?.categories.map(
                                                (it, ind) => {
                                                  return (
                                                    <>
                                                      {ind === 0 ? " " : " , "}
                                                      {it?.name}
                                                    </>
                                                  );
                                                }
                                              )}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="btn-wrap">
                                        {sent?.items.filter(
                                          (word) => word === item?.id
                                        ).length > 0 ? (
                                          <button className="btn btn-success button-connect">
                                            <ConnectIcon />
                                            Request Sent
                                          </button>
                                        ) : (
                                          <button
                                            className="button button-primary button-connect"
                                            style={{
                                              borderRadius: "5px",
                                              background: darkMode
                                                ? "#1C2226"
                                                : "#fff",
                                              color: darkMode ? "#fff" : "#000",
                                            }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                // swal("error","Please verify your profile","error");
                                                // sentConnectionRequest(item?.id);
                                                handleShowModal();
                                              } else {
                                                sentConnectionRequest(item?.id);
                                              }
                                            }}
                                          >
                                            <ConnectIcon />
                                            Connect
                                          </button>
                                        )}

                                        {/* <button
                                      className="button button-primary button-connect"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if(user?.verified_at === null  && user?.is_seller === 1){
                                          swal("error","Please verify your profile","error");
                                          sentConnectionRequest(item?.id);

                                        }
                                        else{
                                          sentConnectionRequest(item?.id);

                                        }
                                      }}
                                    >
                                      <ConnectIcon />
                                      Add Connection
                                    </button> */}
                                        <CopyToClipboard
                                          text={`https://busimeet.com/edit-profile-seller/${item?.slug}`}
                                          onCopy={() => {
                                            setShareLink(true);
                                            toast.success(
                                              "copied to clipboard!"
                                            );
                                          }}
                                        >
                                          <button
                                            style={{
                                              background: "#19ACDA",
                                              border: "none",
                                              borderRadius: "10px",
                                            }}
                                            className="button button-secondary button-share"
                                          >
                                            <ShareIcon />
                                          </button>
                                        </CopyToClipboard>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                          {available?.items.length === 0 ? (
                            <div className="text-center w-100">
                              No Data Found
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="card-footer">
                        {available?.has_more === true && (
                          <div
                            className="show-less-or-all-content"
                            onClick={(e) => {
                              setShowMoreLessContent(!isShowMoreLessContent);

                              e.preventDefault();
                              if (FilterAvail?.Filter === true) {
                                let p = FiltAvailable + 1;
                                setFiltAvailable(p);
                              } else {
                                let p = available_usersPage + 1;
                                setavailable_usersPage(p);
                              }
                            }}
                          >
                            <span>
                              {" "}
                              {isShowMoreLessContent ? "Show less" : "Show all"}
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="posts" ref={MarketUpdates}>
                <div className="posts-header">
                  <h6 id="section-1">Market Updates</h6>
                  <div className="tab-group">
                    <div className="tabs tabs--solid">
                      <ul>
                        <li
                          className={
                            FilterMarket?.allMain === true ||
                            (FilterMarket?.seller === false &&
                              FilterMarket?.buyer === false)
                              ? "active"
                              : ""
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            firstPostRef.current.scrollIntoView({
                              behavior: "smooth",
                            });
                            if (FilterMarket?.allMain === false) {
                              setFilterMarket((p) => ({
                                ...p,
                                allMain: true,
                              }));
                              setFilterMarket((p) => ({ ...p, buyer: false }));
                              setFilterMarket((p) => ({ ...p, seller: false }));
                              setTimeLine_usersPage(0);
                            }
                          }}
                        >
                          <Link
                            style={getTabStyle(
                              FilterMarket?.allMain ||
                                (!FilterMarket?.seller && !FilterMarket?.buyer)
                            )}
                            to={""}
                          >
                            All
                          </Link>
                        </li>
                        <li
                          className={
                            FilterMarket?.seller === true ? "active" : ""
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            firstPostRef.current.scrollIntoView({
                              behavior: "smooth",
                            });
                            if (FilterMarket?.seller === false) {
                              setFilterMarket((p) => ({
                                ...p,
                                allMain: false,
                              }));
                              setFilterMarket((p) => ({ ...p, buyer: false }));
                              setFilterMarket((p) => ({ ...p, seller: true }));
                              setTimeLine_usersPage(0);
                            }
                          }}
                        >
                          <Link
                            style={getTabStyle(FilterMarket?.seller)}
                            to={""}
                          >
                            Sellers
                          </Link>
                        </li>
                        <li
                          className={
                            FilterMarket?.buyer === true ? "active" : ""
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            firstPostRef.current.scrollIntoView({
                              behavior: "smooth",
                            });
                            if (FilterMarket?.buyer === false) {
                              setFilterMarket((p) => ({
                                ...p,
                                allMain: false,
                              }));
                              setFilterMarket((p) => ({ ...p, buyer: true }));

                              setFilterMarket((p) => ({ ...p, seller: false }));
                              setTimeLine_usersPage(0);
                            }
                          }}
                        >
                          <Link
                            style={getTabStyle(FilterMarket?.buyer)}
                            to={""}
                          >
                            Buyers
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="tabs tabs--solid">
                      <ul>
                        <li
                          className={
                            FilterMarket?.allChild === true ||
                            (FilterMarket?.buisness === false &&
                              FilterMarket?.social === false)
                              ? "active"
                              : ""
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            firstPostRef.current.scrollIntoView({
                              behavior: "smooth",
                            });

                            if (FilterMarket?.allChild === false) {
                              setFilterMarket((p) => ({
                                ...p,
                                allChild: true,
                              }));
                              setFilterMarket((p) => ({
                                ...p,
                                buisness: false,
                              }));
                              setFilterMarket((p) => ({ ...p, social: false }));
                              setTimeLine_usersPage(0);
                            }
                          }}
                        >
                          <Link
                            style={getTabStyle(
                              FilterMarket?.allChild ||
                                (!FilterMarket?.buisness &&
                                  !FilterMarket?.social)
                            )}
                            to={""}
                          >
                            All
                          </Link>
                        </li>
                        <li
                          className={
                            FilterMarket?.social === true ? "active" : ""
                          }
                        >
                          <Link
                            to={""}
                            onClick={(e) => {
                              e.preventDefault();
                              firstPostRef.current.scrollIntoView({
                                behavior: "smooth",
                              });

                              if (FilterMarket?.social === false) {
                                setFilterMarket((p) => ({
                                  ...p,
                                  allChild: false,
                                }));
                                setFilterMarket((p) => ({
                                  ...p,
                                  buisness: false,
                                }));
                                setFilterMarket((p) => ({
                                  ...p,
                                  social: true,
                                }));
                                setTimeLine_usersPage(0);
                              }
                            }}
                            style={getTabStyle(FilterMarket?.social)}
                          >
                            Social
                          </Link>
                        </li>
                        <li
                          className={
                            FilterMarket?.buisness === true ? "active" : ""
                          }
                        >
                          <Link
                            to={""}
                            onClick={(e) => {
                              e.preventDefault();
                              firstPostRef.current.scrollIntoView({
                                behavior: "smooth",
                              });

                              if (FilterMarket?.buisness === false) {
                                setFilterMarket((p) => ({
                                  ...p,
                                  allChild: false,
                                }));
                                setFilterMarket((p) => ({
                                  ...p,
                                  buisness: true,
                                }));
                                setFilterMarket((p) => ({
                                  ...p,
                                  social: false,
                                }));
                                setTimeLine_usersPage(0);
                              }
                            }}
                            style={getTabStyle(FilterMarket?.buisness)}
                          >
                            Business
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <a
                    className="collapse-button"
                    data-toggle="collapse"
                    href="#collapupdate"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapupdate"
                  >
                    <ArrowDownIcon />
                  </a>
                </div>

                <div
                  className="posts-content collapse show"
                  id="collapupdate"
                  ref={firstPostRef}
                  style={{ marginTop: "10px" }}
                >
                  {timeline?.items &&
                    timeline?.items.map((item, index) => {
                      return (
                        <>
                          {item?.type === "post_to_sell" ||
                          item?.type === "product" ? (
                            <>
                              <div
                                className="card post-seller"
                                ref={lastBookElementRef}
                                key={item}
                              >
                                <div className="card-body">
                                  <div
                                    className="user-profile"
                                    style={{
                                      marginBottom: "0px",
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <Link
                                      to={`/edit-profile-seller/${item?.user?.slug}`}
                                    >
                                      <div className="user-profile-image">
                                        <img
                                          alt="profile"
                                          src={item?.user?.profile_image}
                                          className="profile-pic"
                                        />
                                      </div>
                                    </Link>
                                    <div className="user-profile-content">
                                      <Link
                                        to={`/edit-profile-seller/${item?.user?.slug}`}
                                        style={{
                                          width: "fit-content",
                                          cursor: "default",
                                        }}
                                      >
                                        <div className="user-profile-name">
                                          <h6>
                                            {item?.user?.name}
                                            <span>
                                              (Profile ID: {item?.user?.id})
                                            </span>
                                          </h6>
                                          <p>
                                            {item?.user?.company_details
                                              ?.designation != null
                                              ? "Partner" +
                                                item?.user?.company_details
                                                  ?.designation +
                                                "at"
                                              : " "}
                                            {item?.user?.company_details?.name}
                                          </p>
                                          <span
                                            style={{ cursor: "pointer" }}
                                            className="post-tag"
                                          >
                                            {item?.type === "post_to_sell"
                                              ? "Seller"
                                              : "Buyer"}
                                          </span>

                                          {
                                            item?.user?.id === user?.id ||
                                            item?.user?.has_connection === 1 ? (
                                              ""
                                            ) : sent?.items.filter(
                                                (word) =>
                                                  word === item?.user?.id
                                              ).length > 0 ? (
                                              <button
                                                className="btn btn-success button-connect"
                                                style={{
                                                  maxWidth: "fit-content",
                                                }}
                                              >
                                                Request Sent
                                              </button>
                                            ) : (
                                              <button
                                                className="button button-primary button-connect"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  if (
                                                    user?.verified_at ===
                                                      null &&
                                                    user?.is_seller === 1 &&
                                                    user?.is_seller === 1
                                                  ) {
                                                    // swal("error","Please verify your profile","error");
                                                    // sentConnectionRequest(item?.id);
                                                    handleShowModal();
                                                  } else {
                                                    sentConnectionRequest(
                                                      item?.user?.id
                                                    );
                                                  }
                                                }}
                                              >
                                                + Connect
                                              </button>
                                            )

                                            // <button
                                            //   className="button button-primary button-connect"
                                            //   onClick={(e) => {
                                            //     e.preventDefault();
                                            //     if (
                                            //       user?.verified_at === null &&
                                            //       user?.is_seller === 1 &&
                                            //       user?.is_seller === 1
                                            //     ) {
                                            //       handleShowModal();
                                            //     } else {
                                            //       sentConnectionRequest(
                                            //         item?.user?.id
                                            //       );
                                            //     }
                                            //   }}
                                            // >
                                            //   + Connect
                                            // </button>
                                          }
                                        </div>
                                      </Link>
                                    </div>
                                    <div className="posts-action">
                                      <div className="posts-time">
                                        <p>
                                          <HistoryIcon />
                                          {""}
                                          {momentT(item?.created_at)}
                                        </p>
                                        <p>
                                          <EditIcon />
                                          {""}
                                          {momentT(item?.updated_at)}
                                        </p>
                                      </div>
                                      {user?.id === item?.user?.id ? (
                                        <div className="more-btn">
                                          <div className="nav-item dropdown account-dropdown">
                                            <Link
                                              to={""}
                                              className="nav-link dropdown-toggle"
                                              href="#"
                                              id="navbarDropdown"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <MoreIcon />
                                            </Link>
                                            <div className="dropdown-menu dropdown-menu-right">
                                              <button
                                                className="dropdown-item"
                                                onClick={() => {
                                                  if (
                                                    item?.type ===
                                                    "post_to_sell"
                                                  ) {
                                                    navigate("/edit-post", {
                                                      state: item,
                                                    });
                                                  } else {
                                                    navigate("/edit-product", {
                                                      state: item,
                                                    });
                                                  }
                                                }}
                                                style={{ fontSize: 12 }}
                                              >
                                                Edit
                                              </button>
                                              <button
                                                className="dropdown-item"
                                                onClick={() => {
                                                  // e.preventDefault();

                                                  swal({
                                                    title: "Are you sure?",
                                                    text: "Are you sure to delete this product!",
                                                    icon: "warning",
                                                    dangerMode: true,
                                                    buttons: ["cancel", "ok"],
                                                  }).then((willDelete) => {
                                                    if (willDelete) {
                                                      handleDeletePost(
                                                        item?.id,
                                                        index
                                                      );
                                                    } else {
                                                    }
                                                  });
                                                }}
                                                style={{ fontSize: 12 }}
                                              >
                                                Delete
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="more-btn">
                                          <div className="nav-item dropdown account-dropdown">
                                            <Link
                                              to={""}
                                              className="nav-link dropdown-toggle"
                                              href="#"
                                              id="navbarDropdown"
                                              role="button"
                                              data-toggle="dropdown"
                                              aria-haspopup="true"
                                              aria-expanded="false"
                                            >
                                              <MoreIcon />
                                            </Link>
                                            <div
                                              className="dropdown-menu dropdown-menu-right"
                                              aria-labelledby="navbarDropdown"
                                            >
                                              <Link
                                                className="dropdown-item"
                                                to={"/"}
                                                onClick={(e) => {
                                                  e.preventDefault();

                                                  sessionStorage.setItem(
                                                    "reportid",
                                                    item?.id
                                                  );
                                                  sessionStorage.setItem(
                                                    "post",
                                                    "Post"
                                                  );
                                                  sessionStorage.setItem(
                                                    "type",
                                                    item?.type
                                                  );

                                                  // ReportComment(item?.id,com?.id,item?.type )
                                                }}
                                                data-toggle="modal"
                                                data-target="#ReportModal"
                                              >
                                                Report
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <p
                                    style={{
                                      marginBottom: "10px",
                                      padding: "0px 10px",
                                      textAlign: "justify",
                                    }}
                                    className="posts-summery"
                                  >
                                    {item?.post_template?.content}
                                  </p>
                                  <div className="posts-product-details flex-box">
                                    <div className="flex-item posts-image-wrap">
                                      {item.media.length > 1 ? (
                                        <div className="posts-image">
                                          <Slider
                                            {...settingsPost}
                                            arrows={true}
                                          >
                                            {item.media.map((imagess) => (
                                              <img
                                                src={imagess.file}
                                                alt="productImage"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setImageModalId(index);
                                                  setImageModal(true);
                                                }}
                                              />
                                            ))}
                                          </Slider>
                                        </div>
                                      ) : (
                                        <div className="posts-image">
                                          {/* <a href=""> */}
                                          <img
                                            src={item?.media[0]?.file}
                                            alt="productImage"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setImageModalId(index);
                                              setImageModal(true);
                                            }}
                                          />
                                          {/* </a> */}
                                        </div>
                                      )}
                                    </div>
                                    <div className="flex-item posts-info-wrap">
                                      <div className="tags">
                                        <div className="tags--title">
                                          Product Details
                                        </div>
                                        <span className="tag">
                                          {item?.name}
                                        </span>
                                        {/* <span className="tag">{item?.name}</span> */}
                                        <span className="tag">
                                          Size:{" "}
                                          {item?.size ? item?.size : "N/A"}
                                        </span>

                                        <span className="tag">
                                          Colour:{" "}
                                          {item?.color?.name
                                            ? item?.color?.name
                                            : "N/A"}
                                        </span>
                                        <span className="tag">
                                          Material:{" "}
                                          {item?.material_type
                                            ? item?.material_type
                                            : "N/A"}
                                        </span>
                                        <span className="tag">
                                          Brand:{" "}
                                          {item?.brand?.name
                                            ? item?.brand?.name
                                            : "N/A"}
                                        </span>
                                        <span className="tag">
                                          Item Code:{" "}
                                          {item?.product_code
                                            ? item?.product_code
                                            : "N/A"}
                                        </span>
                                        <span className="tag">
                                          Shape:{" "}
                                          {item?.shape ? item?.shape : "N/A"}
                                        </span>
                                        <span className="tag">
                                          Storage:{" "}
                                          {item?.storage_capacity
                                            ? item?.storage_capacity
                                            : "N/A"}
                                        </span>
                                      </div>
                                      <div className="tags">
                                        <div className="tags--title">
                                          Pricing and Tax
                                        </div>
                                        <span className="tag">
                                          Advance Payment Price:{" "}
                                          {item?.amount ? item?.amount : "N/A"}
                                        </span>
                                        <span className="tag">
                                          Credit Payment Price:{" "}
                                          {item?.credit_amount
                                            ? item?.credit_amount
                                            : "N/A"}
                                        </span>
                                        {item?.credit_policy && (
                                          <span
                                            className="attachment"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setCredPolicyData(
                                                item?.credit_policy
                                              );
                                              handleShowModalCred();
                                            }}
                                          >
                                            <AttachmentIcon />
                                          </span>
                                        )}

                                        <span className="tag">
                                          HSN Code: {item?.hsn_code}
                                        </span>
                                        <span className="tag">
                                          GST: {item?.gst_rate}%
                                        </span>
                                      </div>
                                      <div className="tags">
                                        <div className="tags--title">
                                          Other Details
                                        </div>

                                        {item?.order_qty && (
                                          <span className="tag">
                                            Box Qty: {item?.order_qty}{" "}
                                            {item?.unit?.id != 0 ? (
                                              <>
                                                {/* <span className="tag"> */}
                                                {item?.unit?.name}
                                                {/* </span> */}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        )}
                                        {item?.quality_standard && (
                                          <span className="tag">
                                            Quality Standard:{" "}
                                            {item?.quality_standard}
                                          </span>
                                        )}
                                        <span className="tag">
                                          Delivery lead time within state:{" "}
                                          {
                                            item?.delivery_details
                                              ?.delivery_within_state
                                          }{" "}
                                          Days
                                        </span>
                                        <span className="tag">
                                          Delivery lead time outside state:{" "}
                                          {
                                            item?.delivery_details
                                              ?.delivery_outside_state
                                          }{" "}
                                          Days
                                        </span>

                                        {item?.description !== null &&
                                          item?.description !== "null" && (
                                            <span className="tag">
                                              <strong>
                                                Other Description:
                                              </strong>

                                              {
                                                item?.description != null &&
                                                  item?.description !=
                                                    undefined && (
                                                    <p
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item?.description,
                                                      }}
                                                    ></p>
                                                  )
                                                // <DescriptionWithLinks description={item?.description} />
                                              }
                                            </span>
                                          )}
                                      </div>
                                      {item?.catalogue && (
                                        <ul>
                                          <li>
                                            <a
                                              href={item?.catalogue}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <AttachmentIcon />
                                              Product Catalogue
                                            </a>
                                          </li>
                                        </ul>
                                      )}
                                      {item.freight_charges && (
                                        <ul>
                                          <li>
                                            Freight Charges{" "}
                                            {item?.freight_charges}%
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        columnGap: "10px",
                                        marginBottom: "0px",
                                      }}
                                      className="posts-data"
                                    >
                                      <div className="posts-connections">
                                        {item?.interactions?.reactions > 0 ||
                                        item?.interactions?.reaction > 0 ? (
                                          <span>
                                            {item?.interactions?.reaction ===
                                            "like" ? (
                                              <LikeIconFilled />
                                            ) : (
                                              <>
                                                {item.interactions.reaction ===
                                                "celebrate" ? (
                                                  <CelebrateIcon />
                                                ) : item?.interactions
                                                    ?.reaction === "love" ? (
                                                  <LoveIcon />
                                                ) : item?.interactions
                                                    ?.reaction ===
                                                  "insightful" ? (
                                                  <InsightfulIcon />
                                                ) : item?.interactions
                                                    ?.reaction === "support" ? (
                                                  <CareIcon />
                                                ) : item?.interactions
                                                    ?.reaction === "curious" ? (
                                                  <CuriousIcon />
                                                ) : (
                                                  <LikeIconFilled />
                                                )}
                                              </>
                                            )}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                        <span style={{ marginTop: "20px" }}>
                                          {item?.interactions?.reaction ===
                                            null &&
                                            item.interactions.reactions === 1 &&
                                            `${item.interactions.reactions}`}
                                          {item?.interactions?.reaction !==
                                            null &&
                                            item.interactions.reactions === 1 &&
                                            `${item.interactions.reactions}`}
                                          {item?.interactions?.reaction ===
                                            null &&
                                            item.interactions.reactions > 1 &&
                                            `${item.interactions.reactions} Likes`}
                                          {item?.interactions?.reaction !==
                                            null &&
                                            item.interactions.reactions === 2 &&
                                            `You and ${
                                              item.interactions.reactions - 1
                                            } other`}
                                          {item?.interactions?.reaction !==
                                            null &&
                                            item.interactions.reactions > 2 &&
                                            `You and ${
                                              item.interactions.reactions - 1
                                            } others`}
                                        </span>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                        className="comments-deals"
                                        onClick={(e) => {
                                          if (
                                            user?.verified_at === null &&
                                            user?.is_seller === 1 &&
                                            user?.is_seller === 1
                                          ) {
                                            handleShowModal();
                                          } else {
                                            // if (
                                            //   e.target.style.fill === ""
                                            // ) {
                                            //   e.target.style.fill =
                                            //     "#0a95ff";
                                            //   reaction(item?.id, "product");
                                            // } else {
                                            //   e.target.style.fill = "";
                                            // }
                                            if (item?.type === "product") {
                                              if (
                                                ProductCommentTag?.items
                                                  .length <= 0
                                              ) {
                                                CommentTag(item?.type);
                                              }
                                            }
                                            if (item?.type === "post_to_sell") {
                                              if (
                                                PostSellComTag?.items.length <=
                                                0
                                              ) {
                                                CommentTag(item?.type);
                                              }
                                            }
                                            if (
                                              item?.interactions?.comments === 0
                                            ) {
                                              // alert("test ")
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_id: item?.id,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_type: item?.type,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                commentArray: [],
                                              }));
                                            } else {
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_id: item?.id,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_type: item?.type,
                                              }));
                                              if (
                                                item?.id !=
                                                CurrentComment?.post_id
                                              ) {
                                                CommentListing(
                                                  item?.id,
                                                  item?.type
                                                );
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        {Number(item?.interactions?.comments) >
                                          0 && (
                                          <CommentIconFilled
                                            style={{
                                              height: "12px",
                                              width: "12px",
                                            }}
                                          />
                                        )}
                                        <span style={{ paddingLeft: "5px" }}>
                                          {Number(
                                            item?.interactions?.comments
                                          ) > 0
                                            ? `${item.interactions.comments} Comments`
                                            : ""}
                                        </span>

                                        {/* <span>7 Cards Received</span> */}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="posts-activity">
                                    <div className="posts-activity-actions">
                                      <span className="posts-activity-actions--button reaction">
                                        <span
                                          className={
                                            item?.interactions?.reaction ===
                                              "like" ||
                                            item?.interactions?.reaction ===
                                              "support" ||
                                            item?.interactions?.reaction ===
                                              "love" ||
                                            item?.interactions?.reaction ===
                                              "celebrate" ||
                                            item?.interactions?.reaction ===
                                              "insightful" ||
                                            item?.interactions?.reaction ===
                                              "curious"
                                              ? "icon-reacted"
                                              : ""
                                          }
                                          onClick={(e) => {
                                            if (
                                              user?.verified_at === null &&
                                              user?.is_seller === 1 &&
                                              user?.is_seller === 1
                                            ) {
                                              handleShowModal();
                                            } else {
                                              if (
                                                item?.interactions?.reaction ===
                                                  "like" ||
                                                item?.interactions?.reaction ===
                                                  "celebrate" ||
                                                item?.interactions?.reaction ===
                                                  "love" ||
                                                item?.interactions?.reaction ===
                                                  "insightful" ||
                                                item?.interactions?.reaction ===
                                                  "support" ||
                                                item?.interactions?.reaction ===
                                                  "curious"
                                              ) {
                                                reaction(
                                                  item?.id,
                                                  "product",
                                                  "",
                                                  "unlike",
                                                  index
                                                );
                                              } else {
                                                reaction(
                                                  item?.id,
                                                  "product",
                                                  "",
                                                  "",
                                                  index,
                                                  "like"
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          {item?.interactions?.reaction ===
                                          "like" ? (
                                            <LikeIconFilled />
                                          ) : (
                                            <>
                                              {item.interactions.reaction ===
                                              "celebrate" ? (
                                                <CelebrateIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "love" ? (
                                                <LoveIcon />
                                              ) : item?.interactions
                                                  ?.reaction ===
                                                "insightful" ? (
                                                <InsightfulIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "support" ? (
                                                <CareIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "curious" ? (
                                                <CuriousIcon />
                                              ) : (
                                                <LikeIconFilled />
                                              )}
                                            </>
                                          )}
                                        </span>
                                        <span className="posts-activity-actions--text">
                                          {item?.interactions?.reaction ===
                                          "like"
                                            ? "Like"
                                            : item?.interactions?.reaction ===
                                              "celebrate"
                                            ? "Celebrate"
                                            : item?.interactions?.reaction ===
                                              "support"
                                            ? "Support"
                                            : item?.interactions?.reaction ===
                                              "insightful"
                                            ? "Insightful"
                                            : item?.interactions?.reaction ===
                                              "love"
                                            ? "Love"
                                            : item?.interactions?.reaction ===
                                              "curious"
                                            ? "Curious"
                                            : "Like"}
                                        </span>
                                        <div className="reaction-hover">
                                          <span>
                                            <ReactLikeIcon
                                              onClick={(e) => {
                                                if (
                                                  user?.verified_at === null &&
                                                  user?.is_seller === 1 &&
                                                  user?.is_seller === 1
                                                ) {
                                                  handleShowModal();
                                                } else {
                                                  if (
                                                    item?.interactions
                                                      ?.reaction === "like"
                                                  ) {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "unlike",
                                                      index
                                                    );
                                                  } else {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "",
                                                      index,
                                                      "like"
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          </span>
                                          <span>
                                            <CelebrateIcon
                                              onClick={(e) => {
                                                if (
                                                  user?.verified_at === null &&
                                                  user?.is_seller === 1 &&
                                                  user?.is_seller === 1
                                                ) {
                                                  handleShowModal();
                                                } else {
                                                  if (
                                                    item?.interactions
                                                      ?.reaction === "celebrate"
                                                  ) {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "unlike",
                                                      index
                                                    );
                                                  } else {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "",
                                                      index,
                                                      "celebrate"
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          </span>
                                          <span>
                                            <CareIcon
                                              onClick={(e) => {
                                                if (
                                                  user?.verified_at === null &&
                                                  user?.is_seller === 1 &&
                                                  user?.is_seller === 1
                                                ) {
                                                  handleShowModal();
                                                } else {
                                                  if (
                                                    item?.interactions
                                                      ?.reaction === "support"
                                                  ) {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "unlike",
                                                      index
                                                    );
                                                  } else {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "",
                                                      index,
                                                      "support"
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          </span>
                                          <span>
                                            <LoveIcon
                                              onClick={(e) => {
                                                if (
                                                  user?.verified_at === null &&
                                                  user?.is_seller === 1
                                                ) {
                                                  handleShowModal();
                                                } else {
                                                  if (
                                                    item?.interactions
                                                      ?.reaction === "love"
                                                  ) {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "unlike",
                                                      index
                                                    );
                                                  } else {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "",
                                                      index,
                                                      "love"
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          </span>
                                          <span>
                                            <InsightfulIcon
                                              onClick={(e) => {
                                                if (
                                                  user?.verified_at === null &&
                                                  user?.is_seller === 1 &&
                                                  user?.is_seller === 1
                                                ) {
                                                  handleShowModal();
                                                } else {
                                                  if (
                                                    item?.interactions
                                                      ?.reaction ===
                                                    "insightful"
                                                  ) {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "unlike",
                                                      index
                                                    );
                                                  } else {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "",
                                                      index,
                                                      "insightful"
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          </span>
                                          <span>
                                            <CuriousIcon
                                              onClick={(e) => {
                                                if (
                                                  user?.verified_at === null &&
                                                  user?.is_seller === 1 &&
                                                  user?.is_seller === 1
                                                ) {
                                                  handleShowModal();
                                                } else {
                                                  if (
                                                    item?.interactions
                                                      ?.reaction === "curious"
                                                  ) {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "unlike",
                                                      index
                                                    );
                                                  } else {
                                                    reaction(
                                                      item?.id,
                                                      "product",
                                                      "",
                                                      "",
                                                      index,
                                                      "curious"
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </span>
                                      <span
                                        className="posts-activity-actions--button comment"
                                        onClick={(e) => {
                                          if (
                                            user?.verified_at === null &&
                                            user?.is_seller === 1 &&
                                            user?.is_seller === 1
                                          ) {
                                            handleShowModal();
                                          } else {
                                            // if (
                                            //   e.target.style.fill === ""
                                            // ) {
                                            //   e.target.style.fill =
                                            //     "#0a95ff";
                                            //   reaction(item?.id, "product");
                                            // } else {
                                            //   e.target.style.fill = "";
                                            // }
                                            if (item?.type === "product") {
                                              if (
                                                ProductCommentTag?.items
                                                  .length <= 0
                                              ) {
                                                CommentTag(item?.type);
                                              }
                                            }
                                            if (item?.type === "post_to_sell") {
                                              if (
                                                PostSellComTag?.items.length <=
                                                0
                                              ) {
                                                CommentTag(item?.type);
                                              }
                                            }
                                            if (
                                              item?.interactions?.comments === 0
                                            ) {
                                              // alert("test ")
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_id: item?.id,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_type: item?.type,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                commentArray: [],
                                              }));
                                            } else {
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_id: item?.id,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_type: item?.type,
                                              }));
                                              if (
                                                item?.id !=
                                                CurrentComment?.post_id
                                              ) {
                                                CommentListing(
                                                  item?.id,
                                                  item?.type
                                                );
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        <span className="icon">
                                          <CommentIconFilled />
                                        </span>
                                        <span className="icon-filled">
                                          <CommentIconFilled />
                                        </span>

                                        <span className="posts-activity-actions--text">
                                          Comment
                                        </span>
                                      </span>
                                      {item?.user?.id !== user?.id && (
                                        <span className="posts-activity-actions--button send-quote">
                                          <Link
                                            to=""
                                            onClick={() =>
                                              setProductDetail(item)
                                            }
                                            style={{ color: "inherit" }}
                                            data-toggle="modal"
                                            data-target="#accpetDeal"
                                          >
                                            <span className="icon">
                                              <AcceptDealIcon />
                                            </span>
                                            <span className="icon-filled">
                                              <AcceptDealIcon />
                                            </span>
                                            {/* {darkMode ? (
                                              // <span>
                                              //   <img
                                              //     src={IconAcceptDealtLight}
                                              //     style={{
                                              //       width: "25px",
                                              //       height: "25px",
                                              //     }}
                                              //   />
                                              // </span>
                                              <IconGenerate
                                                iconImage={IconAcceptDealtLight}
                                              />
                                            ) : (
                                              <IconGenerate
                                                iconImage={IconAcceptDealtDark}
                                              />
                                              // <span>
                                              //   <img
                                              //     src={IconAcceptDealtDark}
                                              //     style={{
                                              //       width: "25px",
                                              //       height: "25px",
                                              //     }}
                                              //   />
                                              // </span>
                                            )} */}

                                            <span className="posts-activity-actions--text">
                                              Accept Deal
                                            </span>
                                          </Link>
                                        </span>
                                      )}
                                      {item?.negotiation != null &&
                                      item?.negotiation != 0 &&
                                      item?.user?.id !== user?.id ? (
                                        <span className="posts-activity-actions--button meeting-room">
                                          <Link
                                            to=""
                                            onClick={() =>
                                              setProductDetail(item)
                                            }
                                            style={{ color: "inherit" }}
                                            data-toggle="modal"
                                            data-target="#NegotiationModal"
                                            className=""
                                          >
                                            <span className="icon">
                                              <NegotiationIcon />
                                            </span>
                                            <span className="icon-filled">
                                              <NegotiationIcon />
                                            </span>
                                            <span className="posts-activity-actions--text">
                                              Negotiation
                                            </span>
                                          </Link>
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {item.user.id !== user.id && (
                                        <span className="posts-activity-actions--button meeting-room">
                                          <Link
                                            to={""}
                                            onClick={() => createThread(item)}
                                          >
                                            <span className="icon">
                                              <MeetingRoomIcon />
                                            </span>
                                            <span className="icon-filled">
                                              <MeetingRoomIconFilled />
                                            </span>
                                            <span className="posts-activity-actions--text">
                                              Meeting Room
                                            </span>
                                          </Link>
                                        </span>
                                      )}
                                      <CopyToClipboard
                                        text={`${window.location.origin}/post-detail/${item?.id}/${item?.type}`}
                                        onCopy={() => {
                                          setShareLink(true);
                                          toast.success("copied to clipboard!");
                                        }}
                                      >
                                        <span className="posts-activity-actions--button share">
                                          <span className="icon">
                                            <CopyIcon />
                                          </span>
                                          <span className="icon-filled">
                                            <CopyIconFilled />
                                          </span>
                                          <span className="posts-activity-actions--text">
                                            Copy Link
                                          </span>
                                        </span>
                                      </CopyToClipboard>
                                    </div>

                                    {CurrentComment?.post_id === item?.id ? (
                                      <>
                                        <div className="add-comments">
                                          <form>
                                            <div className="add-comments--form">
                                              <div className="profile-image">
                                                <Link
                                                  to={`/edit-profile-seller/${user.slug}`}
                                                >
                                                  <img
                                                    src={user?.profile_image}
                                                    className="profile-pic"
                                                  />
                                                </Link>
                                              </div>
                                              <div className="comment-form">
                                                <div className="form-field">
                                                  <input
                                                    type="text"
                                                    name="cname"
                                                    id="cname"
                                                    className="form-input"
                                                    placeholder="Add a Comment"
                                                    value={
                                                      CommentV?.comment_Id ===
                                                      item?.id
                                                        ? CommentV?.comment
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      setComment((p) => ({
                                                        ...p,
                                                        comment: e.target.value,
                                                      }));
                                                      setComment((p) => ({
                                                        ...p,
                                                        comment_Id: item?.id,
                                                      }));
                                                    }}
                                                  />
                                                  <button
                                                    className="button button-primary"
                                                    onClick={(e) => {
                                                      e.preventDefault();

                                                      if (
                                                        user?.verified_at ===
                                                          null &&
                                                        user?.is_seller === 1
                                                      ) {
                                                        handleShowModal();
                                                      } else {
                                                        if (
                                                          CommentV?.comment ===
                                                          ""
                                                        ) {
                                                        } else {
                                                          Comment(
                                                            item?.id,
                                                            "product",
                                                            null,
                                                            index
                                                          );
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    Post
                                                  </button>
                                                </div>
                                                <div className="tags">
                                                  {item?.type === "product" &&
                                                    ProductCommentTag?.items.map(
                                                      (tag, tagI) => {
                                                        return (
                                                          <>
                                                            <span
                                                              className="tag"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                setComment(
                                                                  (p) => ({
                                                                    ...p,
                                                                    comment:
                                                                      tag?.title,
                                                                  })
                                                                );
                                                                setComment(
                                                                  (p) => ({
                                                                    ...p,
                                                                    comment_Id:
                                                                      item?.id,
                                                                  })
                                                                );
                                                              }}
                                                            >
                                                              {tag?.title}
                                                            </span>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  {item?.type ===
                                                    "post_to_sell" &&
                                                    PostSellComTag?.items.map(
                                                      (tag, tagI) => {
                                                        return (
                                                          <>
                                                            <span
                                                              className="tag"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                setComment(
                                                                  (p) => ({
                                                                    ...p,
                                                                    comment:
                                                                      tag?.title,
                                                                  })
                                                                );
                                                                setComment(
                                                                  (p) => ({
                                                                    ...p,
                                                                    comment_Id:
                                                                      item?.id,
                                                                  })
                                                                );
                                                              }}
                                                            >
                                                              {tag?.title}
                                                            </span>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                        {CurrentComment?.commentArray.length >
                                          0 &&
                                          CurrentComment?.commentArray.map(
                                            (com, ind) => {
                                              return (
                                                <>
                                                  <div className="posts-comments">
                                                    <div className="comment-item">
                                                      <div
                                                        style={{
                                                          paddingBottom: "10px",
                                                        }}
                                                        className="user-profile"
                                                      >
                                                        <Link
                                                          to={
                                                            user?.slug ===
                                                            com?.user?.slug
                                                              ? `/edit-profile-seller/${user.slug}`
                                                              : `/edit-profile-seller/${com?.user?.slug}`
                                                          }
                                                        >
                                                          <div className="user-profile-image">
                                                            <img
                                                              alt=""
                                                              src={
                                                                com?.user
                                                                  ?.profile_image
                                                              }
                                                              className="profile-pic"
                                                            />
                                                          </div>
                                                        </Link>
                                                        <div className="comment-content">
                                                          <div className="comment-meta">
                                                            <div className="user-profile-content">
                                                              <Link
                                                                to={
                                                                  user?.slug ===
                                                                  com?.user
                                                                    ?.slug
                                                                    ? `/edit-profile-seller/${user.slug}`
                                                                    : `/edit-profile-seller/${com?.user?.slug}`
                                                                }
                                                              >
                                                                <div className="user-profile-name">
                                                                  <h6>
                                                                    {
                                                                      com?.user
                                                                        ?.name
                                                                    }
                                                                    <span>
                                                                      (Profile
                                                                      ID:{" "}
                                                                      {
                                                                        com
                                                                          ?.user
                                                                          ?.id
                                                                      }
                                                                      )
                                                                    </span>
                                                                    <span>
                                                                      (
                                                                      {com?.user
                                                                        ?.is_buyer ===
                                                                      0
                                                                        ? "Seller"
                                                                        : "Buyer"}
                                                                      )
                                                                    </span>
                                                                  </h6>
                                                                  <p>
                                                                    {
                                                                      com?.user
                                                                        ?.i_am
                                                                    }{" "}
                                                                    at{" "}
                                                                    {
                                                                      com?.user
                                                                        ?.company_details
                                                                        ?.name
                                                                    }
                                                                  </p>
                                                                </div>
                                                              </Link>
                                                            </div>

                                                            <div className="posts-action">
                                                              <div className="posts-time">
                                                                <p>
                                                                  <HistoryIcon />
                                                                  {momentT(
                                                                    com?.created_at
                                                                  )}
                                                                </p>
                                                              </div>
                                                              {user?.id ===
                                                              com?.user?.id ? (
                                                                <div className="more-btn">
                                                                  <div className="nav-item dropdown account-dropdown">
                                                                    <Link
                                                                      to={""}
                                                                      className="nav-link dropdown-toggle"
                                                                      href="#"
                                                                      id="navbarDropdown"
                                                                      role="button"
                                                                      data-toggle="dropdown"
                                                                      aria-haspopup="true"
                                                                      aria-expanded="false"
                                                                    >
                                                                      <MoreIcon />
                                                                    </Link>
                                                                    <div
                                                                      className="dropdown-menu dropdown-menu-right"
                                                                      aria-labelledby="navbarDropdown"
                                                                    >
                                                                      <Link
                                                                        to={"/"}
                                                                        className="dropdown-item"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          DeleteComment(
                                                                            com?.id,
                                                                            item?.id,
                                                                            item?.type,
                                                                            "",
                                                                            index
                                                                          );
                                                                        }}
                                                                      >
                                                                        Delete
                                                                      </Link>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <div className="more-btn">
                                                                  <div className="nav-item dropdown account-dropdown">
                                                                    <Link
                                                                      to={""}
                                                                      className="nav-link dropdown-toggle"
                                                                      href="#"
                                                                      id="navbarDropdown"
                                                                      role="button"
                                                                      data-toggle="dropdown"
                                                                      aria-haspopup="true"
                                                                      aria-expanded="false"
                                                                    >
                                                                      <MoreIcon />
                                                                    </Link>
                                                                    <div
                                                                      className="dropdown-menu dropdown-menu-right"
                                                                      aria-labelledby="navbarDropdown"
                                                                    >
                                                                      <Link
                                                                        className="dropdown-item"
                                                                        to={"/"}
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();

                                                                          sessionStorage.setItem(
                                                                            "reportid",
                                                                            item?.id
                                                                          );
                                                                          sessionStorage.setItem(
                                                                            "comid",
                                                                            com?.id
                                                                          );
                                                                          sessionStorage.setItem(
                                                                            "type",
                                                                            item?.type
                                                                          );

                                                                          // ReportComment(item?.id,com?.id,item?.type )
                                                                        }}
                                                                        data-toggle="modal"
                                                                        data-target="#ReportModal"
                                                                      >
                                                                        Report
                                                                      </Link>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                          <div className="comment-text">
                                                            <p
                                                              style={{
                                                                marginBottom:
                                                                  "5px",
                                                              }}
                                                            >
                                                              {com?.comment}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                        className="comment-actions"
                                                      >
                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                          className="comment-actions--button reaction"
                                                          onClick={(e) => {
                                                            if (
                                                              user?.verified_at ===
                                                                null &&
                                                              user?.is_seller ===
                                                                1
                                                            ) {
                                                              handleShowModal();
                                                            } else {
                                                              if (
                                                                com
                                                                  ?.interactions
                                                                  ?.is_like > 0
                                                              ) {
                                                                reactionChild(
                                                                  item?.id,
                                                                  "product",
                                                                  com?.id,
                                                                  "unlike",
                                                                  ind
                                                                );
                                                              } else {
                                                                reactionChild(
                                                                  item?.id,
                                                                  "product",
                                                                  com?.id,
                                                                  "",
                                                                  ind
                                                                );
                                                              }
                                                            }
                                                          }}
                                                        >
                                                          <span
                                                            className={
                                                              com?.interactions
                                                                ?.is_like > 0
                                                                ? "icon-filld"
                                                                : ""
                                                            }
                                                          >
                                                            <LikeIconFilled
                                                              style={{
                                                                width: "12px",
                                                                height: "12px",
                                                              }}
                                                            />
                                                          </span>
                                                          <span className="comment-actions--text">
                                                            Like
                                                          </span>
                                                        </span>
                                                        {com?.interactions
                                                          ?.reactions > 0 && (
                                                          <span className="pr-3">
                                                            {com?.interactions
                                                              ?.reactions > 1
                                                              ? com
                                                                  ?.interactions
                                                                  ?.reactions +
                                                                " Likes"
                                                              : com
                                                                  ?.interactions
                                                                  ?.reactions ===
                                                                1
                                                              ? com
                                                                  ?.interactions
                                                                  ?.reactions +
                                                                " Like"
                                                              : ""}
                                                          </span>
                                                        )}

                                                        <span
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                          className="comment-actions--button comment"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            if (
                                                              com?.id !=
                                                              CurrentComment?.parent_id
                                                            ) {
                                                              setCurrentComment(
                                                                (p) => ({
                                                                  ...p,
                                                                  child_Comment_List:
                                                                    [],
                                                                })
                                                              );
                                                            }

                                                            setCurrentComment(
                                                              (p) => ({
                                                                ...p,
                                                                parent_id:
                                                                  com?.id,
                                                              })
                                                            );
                                                            setComment((p) => ({
                                                              ...p,
                                                              parent_id:
                                                                com?.id,
                                                            }));
                                                            if (
                                                              com?.interactions
                                                                ?.child_comments >
                                                              0
                                                            ) {
                                                              if (
                                                                com?.id !=
                                                                CurrentComment?.parent_id
                                                              ) {
                                                                ChildCommentListing(
                                                                  com?.id,
                                                                  item?.id,
                                                                  item?.type
                                                                );
                                                              }
                                                            }
                                                          }}
                                                        >
                                                          <span className="">
                                                            <CommentIconFilled
                                                              style={{
                                                                height: "12px",
                                                                width: "12px",
                                                              }}
                                                            />
                                                          </span>
                                                          <span className="comment-actions--text">
                                                            Reply
                                                          </span>
                                                        </span>
                                                        <span
                                                          className="pr-3"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            if (
                                                              com?.id !=
                                                              CurrentComment?.parent_id
                                                            ) {
                                                              setCurrentComment(
                                                                (p) => ({
                                                                  ...p,
                                                                  child_Comment_List:
                                                                    [],
                                                                })
                                                              );
                                                            }

                                                            setCurrentComment(
                                                              (p) => ({
                                                                ...p,
                                                                parent_id:
                                                                  com?.id,
                                                              })
                                                            );
                                                            setComment((p) => ({
                                                              ...p,
                                                              parent_id:
                                                                com?.id,
                                                            }));
                                                            if (
                                                              com?.interactions
                                                                ?.child_comments >
                                                              0
                                                            ) {
                                                              if (
                                                                com?.id !=
                                                                CurrentComment?.parent_id
                                                              ) {
                                                                ChildCommentListing(
                                                                  com?.id,
                                                                  item?.id,
                                                                  item?.type
                                                                );
                                                              }
                                                            }
                                                          }}
                                                        >
                                                          {com?.interactions
                                                            ?.child_comments > 1
                                                            ? com?.interactions
                                                                ?.child_comments +
                                                              " Comments"
                                                            : com?.interactions
                                                                ?.child_comments ===
                                                              1
                                                            ? com?.interactions
                                                                ?.child_comments +
                                                              " Comment"
                                                            : ""}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  {com?.id ===
                                                    CurrentComment?.parent_id &&
                                                    CurrentComment
                                                      ?.child_Comment_List
                                                      .length > 0 &&
                                                    CurrentComment?.child_Comment_List.map(
                                                      (it, ig) => {
                                                        return (
                                                          <>
                                                            <div className="posts-comments ml-5 ">
                                                              <div className="comment-item">
                                                                <div
                                                                  style={{
                                                                    paddingBottom:
                                                                      "10px",
                                                                  }}
                                                                  className="user-profile"
                                                                >
                                                                  <Link
                                                                    to={
                                                                      user?.slug ===
                                                                      it?.user
                                                                        ?.slug
                                                                        ? `/edit-profile-seller/${user.slug}`
                                                                        : `/edit-profile-seller/${it?.user?.slug}`
                                                                    }
                                                                  >
                                                                    <div className="user-profile-image">
                                                                      <img
                                                                        alt=""
                                                                        src={
                                                                          it
                                                                            ?.user
                                                                            ?.profile_image
                                                                        }
                                                                        className="profile-pic"
                                                                      />
                                                                    </div>
                                                                  </Link>
                                                                  <div className="comment-content">
                                                                    <div className="comment-meta">
                                                                      <div className="user-profile-content">
                                                                        <Link
                                                                          to={
                                                                            user?.slug ===
                                                                            it
                                                                              ?.user
                                                                              ?.slug
                                                                              ? `/edit-profile-seller/${user.slug}`
                                                                              : `/edit-profile-seller/${it?.user?.slug}`
                                                                          }
                                                                        >
                                                                          <div className="user-profile-name">
                                                                            <h6>
                                                                              {
                                                                                it
                                                                                  ?.user
                                                                                  ?.name
                                                                              }
                                                                              <span>
                                                                                (Profile
                                                                                ID:{" "}
                                                                                {
                                                                                  it
                                                                                    ?.user
                                                                                    ?.id
                                                                                }

                                                                                )
                                                                              </span>

                                                                              <span>
                                                                                (
                                                                                {it
                                                                                  ?.user
                                                                                  ?.is_buyer ===
                                                                                0
                                                                                  ? "Seller"
                                                                                  : "Buyer"}

                                                                                )
                                                                              </span>
                                                                            </h6>
                                                                            <p>
                                                                              {
                                                                                it
                                                                                  ?.user
                                                                                  ?.i_am
                                                                              }{" "}
                                                                              at{" "}
                                                                              {
                                                                                it
                                                                                  ?.user
                                                                                  ?.company_details
                                                                                  ?.name
                                                                              }
                                                                            </p>
                                                                          </div>
                                                                        </Link>
                                                                      </div>
                                                                      <div className="posts-action">
                                                                        <div className="posts-time">
                                                                          <p>
                                                                            <HistoryIcon />
                                                                            {momentT(
                                                                              it?.created_at
                                                                            )}
                                                                          </p>
                                                                        </div>
                                                                        {user?.id ===
                                                                        it?.user
                                                                          ?.id ? (
                                                                          <div className="more-btn">
                                                                            <div className="nav-item dropdown account-dropdown">
                                                                              <Link
                                                                                to={
                                                                                  ""
                                                                                }
                                                                                className="nav-link dropdown-toggle"
                                                                                href="#"
                                                                                id="navbarDropdown"
                                                                                role="button"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                              >
                                                                                <MoreIcon />
                                                                              </Link>
                                                                              <div
                                                                                className="dropdown-menu dropdown-menu-right"
                                                                                aria-labelledby="navbarDropdown"
                                                                              >
                                                                                <Link
                                                                                  className="dropdown-item"
                                                                                  to={
                                                                                    "/"
                                                                                  }
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    e.preventDefault();
                                                                                    DeleteComment(
                                                                                      it?.id,
                                                                                      item?.id,
                                                                                      item?.type,
                                                                                      true,
                                                                                      index
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  Delete
                                                                                </Link>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        ) : (
                                                                          <div className="more-btn">
                                                                            <div className="nav-item dropdown account-dropdown">
                                                                              <Link
                                                                                to={
                                                                                  ""
                                                                                }
                                                                                className="nav-link dropdown-toggle"
                                                                                href="#"
                                                                                id="navbarDropdown"
                                                                                role="button"
                                                                                data-toggle="dropdown"
                                                                                aria-haspopup="true"
                                                                                aria-expanded="false"
                                                                              >
                                                                                <MoreIcon />
                                                                              </Link>
                                                                              <div
                                                                                className="dropdown-menu dropdown-menu-right"
                                                                                aria-labelledby="navbarDropdown"
                                                                              >
                                                                                <Link
                                                                                  className="dropdown-item"
                                                                                  to={
                                                                                    "/"
                                                                                  }
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    e.preventDefault();

                                                                                    sessionStorage.setItem(
                                                                                      "reportid",
                                                                                      item?.id
                                                                                    );
                                                                                    sessionStorage.setItem(
                                                                                      "comid",
                                                                                      it?.id
                                                                                    );
                                                                                    sessionStorage.setItem(
                                                                                      "type",
                                                                                      item?.type
                                                                                    );
                                                                                    sessionStorage.setItem(
                                                                                      "parent_id",
                                                                                      CurrentComment?.parent_id
                                                                                    );

                                                                                    // ReportComment(item?.id,com?.id,item?.type )
                                                                                  }}
                                                                                  data-toggle="modal"
                                                                                  data-target="#ReportModal"
                                                                                >
                                                                                  Report
                                                                                </Link>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                    <div className="comment-text">
                                                                      <p>
                                                                        {
                                                                          it?.comment
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="comment-actions">
                                                                  <span
                                                                    className="comment-actions--button reaction"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      if (
                                                                        user?.verified_at ===
                                                                          null &&
                                                                        user?.is_seller ===
                                                                          1
                                                                      ) {
                                                                        handleShowModal();
                                                                      } else {
                                                                        if (
                                                                          it
                                                                            ?.interactions
                                                                            ?.is_like >
                                                                          0
                                                                        ) {
                                                                          reactionChild(
                                                                            item?.id,
                                                                            "product",
                                                                            it?.id,
                                                                            "unlike",
                                                                            ig,
                                                                            "child"
                                                                          );
                                                                        } else {
                                                                          reactionChild(
                                                                            item?.id,
                                                                            "product",
                                                                            it?.id,
                                                                            "",
                                                                            ig,
                                                                            "child"
                                                                          );
                                                                        }
                                                                      }
                                                                    }}
                                                                  >
                                                                    <span
                                                                      className={
                                                                        it
                                                                          ?.interactions
                                                                          ?.is_like >
                                                                        0
                                                                          ? "icon-filld"
                                                                          : ""
                                                                      }
                                                                    >
                                                                      <LikeIconFilled
                                                                        style={{
                                                                          width:
                                                                            "12px",
                                                                          height:
                                                                            "12px",
                                                                        }}
                                                                      />
                                                                    </span>
                                                                    <span className="comment-actions--text">
                                                                      Like
                                                                    </span>
                                                                  </span>
                                                                  {com
                                                                    ?.interactions
                                                                    ?.reactions >
                                                                    0 && (
                                                                    <span className="pr-3">
                                                                      {it
                                                                        ?.interactions
                                                                        ?.reactions >
                                                                      0
                                                                        ? it
                                                                            ?.interactions
                                                                            ?.reactions +
                                                                          " Like"
                                                                        : ""}
                                                                    </span>
                                                                  )}

                                                                  {user?.id ===
                                                                  item?.user
                                                                    ?.id ? (
                                                                    <>
                                                                      <span
                                                                        className="comment-actions--button reaction"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          setReply(
                                                                            (
                                                                              p
                                                                            ) => ({
                                                                              ...p,
                                                                              CurrentReply:
                                                                                it?.id,
                                                                            })
                                                                          );
                                                                          ReplyListing(
                                                                            item?.type,
                                                                            item?.id,
                                                                            it?.id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <span
                                                                          className={
                                                                            "icon-filld"
                                                                          }
                                                                        >
                                                                          <CommentIconFilled
                                                                            style={{
                                                                              height:
                                                                                "12px",
                                                                              width:
                                                                                "12px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                        <span className="comment-actions--text">
                                                                          {user?.name !==
                                                                          replyUser[0]
                                                                            ?.name
                                                                            ? "Reply"
                                                                            : "Reply"}
                                                                        </span>
                                                                      </span>
                                                                    </>
                                                                  ) : it
                                                                      ?.interactions
                                                                      ?.child_comments >
                                                                    0 ? (
                                                                    <span
                                                                      className="comment-actions--button reaction"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();
                                                                        setReply(
                                                                          (
                                                                            p
                                                                          ) => ({
                                                                            ...p,
                                                                            CurrentReply:
                                                                              it?.id,
                                                                          })
                                                                        );
                                                                        ReplyListing(
                                                                          item?.type,
                                                                          item?.id,
                                                                          it?.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      <span
                                                                        className={
                                                                          "icon-filld"
                                                                        }
                                                                      >
                                                                        <CommentIconFilled
                                                                          style={{
                                                                            height:
                                                                              "12px",
                                                                            width:
                                                                              "12px",
                                                                          }}
                                                                        />
                                                                      </span>
                                                                      <span className="comment-actions--text">
                                                                        {user?.name !==
                                                                        replyUser[0]
                                                                          ?.name
                                                                          ? "Reply"
                                                                          : "Reply"}
                                                                      </span>
                                                                    </span>
                                                                  ) : (
                                                                    ""
                                                                  )}

                                                                  {it
                                                                    ?.interactions
                                                                    ?.child_comments >
                                                                  0 ? (
                                                                    <span
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();
                                                                        setReply(
                                                                          (
                                                                            p
                                                                          ) => ({
                                                                            ...p,
                                                                            CurrentReply:
                                                                              it?.id,
                                                                          })
                                                                        );
                                                                        ReplyListing(
                                                                          item?.type,
                                                                          item?.id,
                                                                          it?.id
                                                                        );
                                                                      }}
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                    >
                                                                      {it
                                                                        ?.interactions
                                                                        ?.child_comments ===
                                                                      1
                                                                        ? it
                                                                            ?.interactions
                                                                            ?.child_comments +
                                                                          " Reply "
                                                                        : it
                                                                            ?.interactions
                                                                            ?.child_comments +
                                                                          " Replies"}
                                                                    </span>
                                                                  ) : (
                                                                    ""
                                                                  )}

                                                                  {it?.id ===
                                                                    Reply?.CurrentReply &&
                                                                    Reply
                                                                      ?.ReplyData
                                                                      .length >
                                                                      0 &&
                                                                    Reply?.ReplyData.map(
                                                                      (
                                                                        reply,
                                                                        reIndex
                                                                      ) => {
                                                                        return (
                                                                          <>
                                                                            <div className="posts-comments ml-5 ">
                                                                              <div className="comment-item">
                                                                                <div
                                                                                  style={{
                                                                                    paddingBottom:
                                                                                      "10px",
                                                                                  }}
                                                                                  className="user-profile"
                                                                                >
                                                                                  <Link
                                                                                    to={`/edit-profile-seller/${reply?.user.slug}`}
                                                                                  >
                                                                                    <div className="user-profile-image">
                                                                                      <img
                                                                                        alt=""
                                                                                        src={
                                                                                          reply
                                                                                            ?.user
                                                                                            ?.profile_image
                                                                                        }
                                                                                        className="profile-pic"
                                                                                      />
                                                                                    </div>
                                                                                  </Link>
                                                                                  <div className="comment-content">
                                                                                    <div className="comment-meta">
                                                                                      <div className="user-profile-content">
                                                                                        <Link
                                                                                          to={`/edit-profile-seller/${reply?.user?.slug}`}
                                                                                        >
                                                                                          <div className="user-profile-name">
                                                                                            <h6>
                                                                                              {
                                                                                                reply
                                                                                                  ?.user
                                                                                                  ?.name
                                                                                              }
                                                                                              <span>
                                                                                                (Profile
                                                                                                ID:{" "}
                                                                                                {
                                                                                                  reply
                                                                                                    ?.user
                                                                                                    ?.id
                                                                                                }

                                                                                                )
                                                                                              </span>

                                                                                              <span>
                                                                                                (
                                                                                                {reply
                                                                                                  ?.user
                                                                                                  ?.is_buyer ===
                                                                                                0
                                                                                                  ? "Seller"
                                                                                                  : "Buyer"}

                                                                                                )
                                                                                              </span>
                                                                                            </h6>
                                                                                          </div>
                                                                                        </Link>
                                                                                      </div>
                                                                                    </div>
                                                                                    <div className="comment-text">
                                                                                      <p>
                                                                                        {
                                                                                          reply?.comment
                                                                                        }
                                                                                      </p>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </>
                                                                        );
                                                                      }
                                                                    )}

                                                                  {it?.id ===
                                                                    Reply?.CurrentReply &&
                                                                    item?.user
                                                                      ?.id ===
                                                                      user?.id && (
                                                                      <>
                                                                        <div className="add-comments">
                                                                          <form>
                                                                            <div className="add-comments--form">
                                                                              <div className="profile-image">
                                                                                <Link
                                                                                  to={`/edit-profile-seller/${user.slug}`}
                                                                                >
                                                                                  <img
                                                                                    src={
                                                                                      user?.profile_image
                                                                                    }
                                                                                    className="profile-pic"
                                                                                  />
                                                                                </Link>
                                                                              </div>
                                                                              <div className="comment-form">
                                                                                <div className="form-field">
                                                                                  <input
                                                                                    type="text"
                                                                                    name="cname"
                                                                                    id="cname"
                                                                                    className="form-input"
                                                                                    placeholder="Add a Reply"
                                                                                    value={
                                                                                      Reply?.comment
                                                                                    }
                                                                                    onChange={(
                                                                                      e
                                                                                    ) => {
                                                                                      setReply(
                                                                                        (
                                                                                          p
                                                                                        ) => ({
                                                                                          ...p,
                                                                                          comment:
                                                                                            e
                                                                                              .target
                                                                                              .value,
                                                                                        })
                                                                                      );
                                                                                      setComment(
                                                                                        (
                                                                                          p
                                                                                        ) => ({
                                                                                          ...p,
                                                                                          parent_id:
                                                                                            it?.id,
                                                                                        })
                                                                                      );
                                                                                    }}
                                                                                  />
                                                                                  <button
                                                                                    className="button button-primary"
                                                                                    onClick={(
                                                                                      e
                                                                                    ) => {
                                                                                      e.preventDefault();

                                                                                      if (
                                                                                        user?.verified_at ===
                                                                                          null &&
                                                                                        user?.is_seller ===
                                                                                          1
                                                                                      ) {
                                                                                        handleShowModal();
                                                                                      } else {
                                                                                        if (
                                                                                          Reply?.comment ===
                                                                                          ""
                                                                                        ) {
                                                                                        } else {
                                                                                          ReplyToMyPost(
                                                                                            item?.id,
                                                                                            "product",
                                                                                            it?.id
                                                                                          );
                                                                                        }
                                                                                      }
                                                                                    }}
                                                                                  >
                                                                                    Post
                                                                                  </button>
                                                                                </div>
                                                                                <div className="tags">
                                                                                  {item?.type ===
                                                                                    "product" &&
                                                                                    ProductCommentTag?.items.map(
                                                                                      (
                                                                                        tag,
                                                                                        tagI
                                                                                      ) => {
                                                                                        return (
                                                                                          <>
                                                                                            <span
                                                                                              className="tag"
                                                                                              onClick={(
                                                                                                e
                                                                                              ) => {
                                                                                                e.preventDefault();
                                                                                                setReply(
                                                                                                  (
                                                                                                    p
                                                                                                  ) => ({
                                                                                                    ...p,
                                                                                                    comment:
                                                                                                      tag?.title,
                                                                                                  })
                                                                                                );
                                                                                                setReply(
                                                                                                  (
                                                                                                    p
                                                                                                  ) => ({
                                                                                                    ...p,
                                                                                                    parent_id:
                                                                                                      item?.id,
                                                                                                  })
                                                                                                );
                                                                                              }}
                                                                                            >
                                                                                              {
                                                                                                tag?.title
                                                                                              }
                                                                                            </span>
                                                                                          </>
                                                                                        );
                                                                                      }
                                                                                    )}
                                                                                  {item?.type ===
                                                                                    "post_to_sell" &&
                                                                                    PostSellComTag?.items.map(
                                                                                      (
                                                                                        tag,
                                                                                        tagI
                                                                                      ) => {
                                                                                        return (
                                                                                          <>
                                                                                            <span
                                                                                              className="tag"
                                                                                              onClick={(
                                                                                                e
                                                                                              ) => {
                                                                                                e.preventDefault();
                                                                                                setReply(
                                                                                                  (
                                                                                                    p
                                                                                                  ) => ({
                                                                                                    ...p,
                                                                                                    comment:
                                                                                                      tag?.title,
                                                                                                  })
                                                                                                );
                                                                                                setReply(
                                                                                                  (
                                                                                                    p
                                                                                                  ) => ({
                                                                                                    ...p,
                                                                                                    parent_id:
                                                                                                      item?.id,
                                                                                                  })
                                                                                                );
                                                                                              }}
                                                                                            >
                                                                                              {
                                                                                                tag?.title
                                                                                              }
                                                                                            </span>
                                                                                          </>
                                                                                        );
                                                                                      }
                                                                                    )}
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </form>
                                                                        </div>
                                                                      </>
                                                                    )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}

                                                  {com?.id ===
                                                  CurrentComment?.parent_id ? (
                                                    <>
                                                      <div className="add-comments ml-5">
                                                        <form>
                                                          <div className="add-comments--form">
                                                            <Link
                                                              to={`/edit-profile-seller/${user.slug}`}
                                                            >
                                                              <div className="profile-image">
                                                                <img
                                                                  alt=""
                                                                  src={
                                                                    user?.profile_image
                                                                  }
                                                                  className="profile-pic"
                                                                />
                                                              </div>
                                                            </Link>
                                                            <div className="comment-form">
                                                              <div className="form-field">
                                                                <input
                                                                  type="text"
                                                                  name="cname"
                                                                  id="cname"
                                                                  className="form-input"
                                                                  placeholder="Add a Comment"
                                                                  value={
                                                                    CommentV?.parent_id ===
                                                                    com?.id
                                                                      ? CommentV?.child_Comment
                                                                      : ""
                                                                  }
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    setComment(
                                                                      (p) => ({
                                                                        ...p,
                                                                        child_Comment:
                                                                          e
                                                                            .target
                                                                            .value,
                                                                      })
                                                                    );
                                                                    setComment(
                                                                      (p) => ({
                                                                        ...p,
                                                                        comment_Id:
                                                                          item?.id,
                                                                      })
                                                                    );
                                                                  }}
                                                                />
                                                                <button
                                                                  className="button button-primary"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.preventDefault();

                                                                    if (
                                                                      user?.verified_at ===
                                                                        null &&
                                                                      user?.is_seller ===
                                                                        1
                                                                    ) {
                                                                      handleShowModal();
                                                                    } else {
                                                                      if (
                                                                        CommentV?.child_Comment ===
                                                                        ""
                                                                      ) {
                                                                      } else {
                                                                        Comment(
                                                                          item?.id,
                                                                          "product",
                                                                          com?.id
                                                                        );
                                                                      }
                                                                    }
                                                                  }}
                                                                >
                                                                  Post
                                                                </button>
                                                              </div>
                                                              <div className="tags">
                                                                {item?.type ===
                                                                  "product" &&
                                                                  ProductCommentTag?.items.map(
                                                                    (
                                                                      tag,
                                                                      tagI
                                                                    ) => {
                                                                      return (
                                                                        <>
                                                                          <span
                                                                            className="tag"
                                                                            onClick={(
                                                                              e
                                                                            ) => {
                                                                              e.preventDefault();
                                                                              setComment(
                                                                                (
                                                                                  p
                                                                                ) => ({
                                                                                  ...p,
                                                                                  child_Comment:
                                                                                    tag?.title,
                                                                                })
                                                                              );
                                                                              setComment(
                                                                                (
                                                                                  p
                                                                                ) => ({
                                                                                  ...p,
                                                                                  comment_Id:
                                                                                    item?.id,
                                                                                })
                                                                              );
                                                                            }}
                                                                          >
                                                                            {
                                                                              tag?.title
                                                                            }
                                                                          </span>
                                                                        </>
                                                                      );
                                                                    }
                                                                  )}
                                                                {item?.type ===
                                                                  "post_to_sell" &&
                                                                  PostSellComTag?.items.map(
                                                                    (
                                                                      tag,
                                                                      tagI
                                                                    ) => {
                                                                      return (
                                                                        <>
                                                                          <span
                                                                            className="tag"
                                                                            onClick={(
                                                                              e
                                                                            ) => {
                                                                              e.preventDefault();
                                                                              setComment(
                                                                                (
                                                                                  p
                                                                                ) => ({
                                                                                  ...p,
                                                                                  child_Comment:
                                                                                    tag?.title,
                                                                                })
                                                                              );
                                                                              setComment(
                                                                                (
                                                                                  p
                                                                                ) => ({
                                                                                  ...p,
                                                                                  comment_Id:
                                                                                    item?.id,
                                                                                })
                                                                              );
                                                                            }}
                                                                          >
                                                                            {
                                                                              tag?.title
                                                                            }
                                                                          </span>
                                                                        </>
                                                                      );
                                                                    }
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </form>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                        {CurrentComment?.has_more === true ? (
                                          <div className="posts-comments-footer">
                                            <span
                                              href=""
                                              className="seemore-link"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setCurrentComment((p) => ({
                                                  ...p,
                                                  page: CurrentComment.page + 1,
                                                }));
                                              }}
                                            >
                                              Load more comments{" "}
                                              <CaretDownIcon />
                                            </span>
                                          </div>
                                        ) : (
                                          " "
                                        )}{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : item?.type === "social_post" ? (
                            <div
                              className="card post-seller post-social"
                              ref={lastBookElementRef}
                              key={item}
                            >
                              <div className="card-body">
                                <div
                                  className="user-profile"
                                  style={{
                                    marginBottom: "0px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <Link
                                    to={`/edit-profile-seller/${item?.user?.slug}`}
                                  >
                                    <div className="user-profile-image">
                                      <img
                                        src={item?.user?.profile_image}
                                        className="profile-pic"
                                      />
                                    </div>
                                  </Link>
                                  <div className="user-profile-content">
                                    <Link
                                      to={`/edit-profile-seller/${item?.user?.slug}`}
                                    >
                                      <div className="user-profile-name">
                                        <h6>
                                          {item?.user?.name}
                                          <span>
                                            (Profile ID: {item?.user?.id})
                                          </span>
                                        </h6>
                                        <p>
                                          {item?.user?.company_details
                                            ?.designation != null
                                            ? "Partner" +
                                              item?.user?.company_details
                                                ?.designation +
                                              "at"
                                            : " "}
                                          {item?.user?.company_details?.name}
                                        </p>
                                        <span className="post-tag">
                                          {item?.user?.is_buyer === 1
                                            ? "Buyer"
                                            : "Seller"}
                                        </span>
                                        {item?.user?.id === user?.id ||
                                        item?.user?.has_connection === 1 ? (
                                          ""
                                        ) : sent?.items.filter(
                                            (word) => word === item?.user?.id
                                          ).length > 0 ? (
                                          <button
                                            className="btn btn-success button-connect"
                                            style={{ maxWidth: "fit-content" }}
                                          >
                                            Request Sent
                                          </button>
                                        ) : (
                                          <button
                                            className="button button-primary button-connect"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1 &&
                                                user?.is_seller === 1
                                              ) {
                                                // swal("error","Please verify your profile","error");
                                                // sentConnectionRequest(item?.id);
                                                handleShowModal();
                                              } else {
                                                sentConnectionRequest(
                                                  item?.user?.id
                                                );
                                              }
                                            }}
                                          >
                                            + Connect
                                          </button>
                                        )}
                                      </div>
                                    </Link>
                                  </div>
                                  {user?.id === item?.user?.id ? (
                                    <div className="posts-action">
                                      <div className="posts-time">
                                        <p>
                                          <HistoryIcon />
                                          {""}
                                          {momentT(item?.created_at)}
                                        </p>
                                        <p>
                                          <EditIcon />
                                          {""}
                                          {momentT(item?.updated_at)}
                                        </p>
                                      </div>
                                      <div className="more-btn">
                                        <div className="nav-item dropdown account-dropdown">
                                          <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <MoreIcon />
                                          </a>
                                          <div
                                            className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="navbarDropdown"
                                          >
                                            <a
                                              className="dropdown-item"
                                              data-toggle="modal"
                                              data-target="#PostSocialModal"
                                              onClick={() => {
                                                setsocialdata(item);
                                                // handleDeletePost(item?.id);
                                              }}
                                            >
                                              Edit
                                            </a>
                                            <a
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                swal({
                                                  title: "Are you sure?",
                                                  text: "Once deleted, you will not be able to recover this Post file!",
                                                  icon: "warning",
                                                  buttons: true,
                                                  dangerMode: true,
                                                }).then((willDelete) => {
                                                  if (willDelete) {
                                                    handleDeleteSocial(
                                                      item?.id,
                                                      index
                                                    );
                                                  } else {
                                                  }
                                                });
                                              }}
                                            >
                                              Delete
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="posts-action">
                                      <div className="posts-time">
                                        <p>
                                          <HistoryIcon />
                                          {""}
                                          {momentT(item?.created_at)}
                                        </p>
                                        <p>
                                          <EditIcon />
                                          {""}
                                          {momentT(item?.updated_at)}
                                        </p>
                                      </div>
                                      <div className="more-btn">
                                        <div className="nav-item dropdown account-dropdown">
                                          <a
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <MoreIcon />
                                          </a>
                                          <div
                                            className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="navbarDropdown"
                                          >
                                            <Link
                                              className="dropdown-item"
                                              to={"/"}
                                              onClick={(e) => {
                                                e.preventDefault();

                                                sessionStorage.setItem(
                                                  "reportid",
                                                  item?.id
                                                );
                                                sessionStorage.setItem(
                                                  "post",
                                                  "Post"
                                                );
                                                sessionStorage.setItem(
                                                  "type",
                                                  item?.type
                                                );

                                                // ReportComment(item?.id,com?.id,item?.type )
                                              }}
                                              data-toggle="modal"
                                              data-target="#ReportModal"
                                            >
                                              Report
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <p
                                  style={{
                                    marginBottom: "10px",
                                    padding: "0px 10px",
                                    textAlign: "justify",
                                  }}
                                  className="posts-summery"
                                >
                                  {item?.description != undefined &&
                                  item?.description !== null ? (
                                    <DescriptionWithLinks
                                      description={item?.description}
                                      connectionsUsers={connectionsUsers}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </p>
                                <div className="posts-product-details flex-box">
                                  {item?.media?.length >= 1 && (
                                    <div
                                      className={` ${
                                        item.media.length === 1 && "text-center"
                                      } flex-item  ${
                                        item.media.length > 0 &&
                                        item?.category?.icon
                                          ? "posts-image-wrap"
                                          : ""
                                      }`}
                                    >
                                      {" "}
                                      <div className="posts-image">
                                        {item.media.length > 1 ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                            className="posts-image"
                                          >
                                            <Slider
                                              style={{ width: "300px" }}
                                              {...settingsPost}
                                              arrows={true}
                                            >
                                              {item.media.map((imagess) => (
                                                <img
                                                  src={imagess.file}
                                                  alt=""
                                                  height={300}
                                                  width={300}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setImageModalId(index);
                                                    setImageModal(true);
                                                  }}
                                                />
                                              ))}
                                            </Slider>
                                          </div>
                                        ) : (
                                          <div className="posts-image">
                                            <a href="">
                                              <img
                                                src={item?.media[0]?.file}
                                                alt=""
                                                height={300}
                                                width={300}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setImageModalId(index);
                                                  setImageModal(true);
                                                }}
                                              />
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  {item?.category?.icon && (
                                    <div className="flex-item posts-info-wrap">
                                      <div
                                        className="tags"
                                        style={{ textAlign: "center" }}
                                      >
                                        <img
                                          height={300}
                                          width={300}
                                          src={item?.category?.icon}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="posts-data">
                                    <div className="posts-connections">
                                      {item?.interactions?.reactions > 0 ||
                                      item?.interactions?.reaction > 0 ? (
                                        <span>
                                          {item?.interactions?.reaction ===
                                          "like" ? (
                                            <LikeIconFilled />
                                          ) : (
                                            <>
                                              {item.interactions.reaction ===
                                              "celebrate" ? (
                                                <CelebrateIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "love" ? (
                                                <LoveIcon />
                                              ) : item?.interactions
                                                  ?.reaction ===
                                                "insightful" ? (
                                                <InsightfulIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "support" ? (
                                                <CareIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "curious" ? (
                                                <CuriousIcon />
                                              ) : (
                                                <LikeIconFilled />
                                              )}
                                            </>
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <span>
                                        {item?.interactions?.reaction ===
                                          null &&
                                          item.interactions.reactions === 1 &&
                                          `${item.interactions.reactions}`}
                                        {item?.interactions?.reaction !==
                                          null &&
                                          item.interactions.reactions === 1 &&
                                          `${item.interactions.reactions}`}
                                        {item?.interactions?.reaction ===
                                          null &&
                                          item.interactions.reactions > 1 &&
                                          `${item.interactions.reactions} Likes`}
                                        {item?.interactions?.reaction !==
                                          null &&
                                          item.interactions.reactions === 2 &&
                                          `You and ${
                                            item.interactions.reactions - 1
                                          } other`}
                                        {item?.interactions?.reaction !==
                                          null &&
                                          item.interactions.reactions > 2 &&
                                          `You and ${
                                            item.interactions.reactions - 1
                                          } others`}
                                      </span>
                                    </div>
                                    <div className="comments-deals">
                                      <div
                                        onClick={(e) => {
                                          if (
                                            user?.verified_at === null &&
                                            user?.is_seller === 1
                                          ) {
                                            handleShowModal();
                                          } else {
                                            if (
                                              SocialComTag?.items?.length <= 0
                                            ) {
                                              CommentTag(item?.type);
                                            }
                                            if (
                                              item?.interactions?.comments <= 0
                                            ) {
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_id: item?.id,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_type: item?.type,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                commentArray: [],
                                              }));
                                            } else {
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_id: item?.id,
                                              }));
                                              setCurrentComment((p) => ({
                                                ...p,
                                                post_type: item?.type,
                                              }));
                                              if (
                                                item?.id !=
                                                CurrentComment?.post_id
                                              ) {
                                                CommentListing(
                                                  item?.id,
                                                  item?.type
                                                );
                                              }
                                            }
                                          }
                                        }}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            padding: 0,
                                            marginTop: "4px",
                                          }}
                                        >
                                          {Number(
                                            item?.interactions?.comments
                                          ) > 0 && (
                                            <CommentIconFilled
                                              style={{
                                                height: "12px",
                                                width: "12px",
                                              }}
                                            />
                                          )}
                                        </span>
                                        <span
                                          style={{
                                            padding: 0,
                                            marginLeft: "5px",
                                          }}
                                        >
                                          {Number(
                                            item?.interactions?.comments
                                          ) > 0
                                            ? `${item.interactions.comments} Comments`
                                            : ""}
                                        </span>
                                      </div>

                                      {item.user.id !== user.id &&
                                        Number(item?.interactions?.cards) >
                                          0 && (
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setselectedSocialPost(item?.id);
                                              handleRecievedCardModal();
                                            }}
                                          >
                                            {" "}
                                            {item?.interactions?.cards}
                                            {Number(item?.interactions?.cards) >
                                            1
                                              ? " Cards"
                                              : " Card"}{" "}
                                            Received
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                </div>

                                <div className="posts-activity">
                                  <div className="posts-activity-actions">
                                    <span className="posts-activity-actions--button reaction">
                                      <span
                                        className={
                                          item?.interactions?.reaction ===
                                            "like" ||
                                          item?.interactions?.reaction ===
                                            "support" ||
                                          item?.interactions?.reaction ===
                                            "love" ||
                                          item?.interactions?.reaction ===
                                            "celebrate" ||
                                          item?.interactions?.reaction ===
                                            "insightful" ||
                                          item?.interactions?.reaction ===
                                            "curious"
                                            ? "icon-reacted"
                                            : ""
                                        }
                                        onClick={(e) => {
                                          if (
                                            user?.verified_at === null &&
                                            user?.is_seller === 1 &&
                                            user?.is_seller === 1
                                          ) {
                                            handleShowModal();
                                          } else {
                                            if (
                                              item?.interactions?.reaction ===
                                                "like" ||
                                              item?.interactions?.reaction ===
                                                "celebrate" ||
                                              item?.interactions?.reaction ===
                                                "love" ||
                                              item?.interactions?.reaction ===
                                                "insightful" ||
                                              item?.interactions?.reaction ===
                                                "support" ||
                                              item?.interactions?.reaction ===
                                                "curious"
                                            ) {
                                              reaction(
                                                item?.id,
                                                item?.type,
                                                "",
                                                "unlike",
                                                index
                                              );
                                            } else {
                                              reaction(
                                                item?.id,
                                                item?.type,
                                                "",
                                                "",
                                                index,
                                                "like"
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        {item?.interactions?.reaction ===
                                        "like" ? (
                                          <LikeIconFilled />
                                        ) : (
                                          <>
                                            {item.interactions.reaction ===
                                            "celebrate" ? (
                                              <CelebrateIcon />
                                            ) : item?.interactions?.reaction ===
                                              "love" ? (
                                              <LoveIcon />
                                            ) : item?.interactions?.reaction ===
                                              "insightful" ? (
                                              <InsightfulIcon />
                                            ) : item?.interactions?.reaction ===
                                              "support" ? (
                                              <CareIcon />
                                            ) : item?.interactions?.reaction ===
                                              "curious" ? (
                                              <CuriousIcon />
                                            ) : (
                                              <LikeIconFilled />
                                            )}
                                          </>
                                        )}
                                      </span>
                                      <span className="posts-activity-actions--text">
                                        {item?.interactions?.reaction === "like"
                                          ? "Like"
                                          : item?.interactions?.reaction ===
                                            "celebrate"
                                          ? "Celebrate"
                                          : item?.interactions?.reaction ===
                                            "support"
                                          ? "Support"
                                          : item?.interactions?.reaction ===
                                            "insightful"
                                          ? "Insightful"
                                          : item?.interactions?.reaction ===
                                            "love"
                                          ? "Love"
                                          : item?.interactions?.reaction ===
                                            "curious"
                                          ? "Curious"
                                          : "Like"}
                                      </span>
                                      <div className="reaction-hover">
                                        <span>
                                          <ReactLikeIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "like"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "",
                                                    index,
                                                    "like"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <CelebrateIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "celebrate"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "",
                                                    index,
                                                    "celebrate"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <CareIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "support"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "",
                                                    index,
                                                    "support"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <LoveIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "love"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "",
                                                    index,
                                                    "love"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <InsightfulIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "insightful"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "",
                                                    index,
                                                    "insightful"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <CuriousIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "curious"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    item?.type,
                                                    "",
                                                    "",
                                                    index,
                                                    "curious"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </span>
                                    <span
                                      className="posts-activity-actions--button comment"
                                      onClick={(e) => {
                                        if (
                                          user?.verified_at === null &&
                                          user?.is_seller === 1
                                        ) {
                                          handleShowModal();
                                        } else {
                                          if (
                                            SocialComTag?.items?.length <= 0
                                          ) {
                                            CommentTag(item?.type);
                                          }
                                          if (
                                            item?.interactions?.comments <= 0
                                          ) {
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_id: item?.id,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_type: item?.type,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              commentArray: [],
                                            }));
                                          } else {
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_id: item?.id,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_type: item?.type,
                                            }));
                                            if (
                                              item?.id !=
                                              CurrentComment?.post_id
                                            ) {
                                              CommentListing(
                                                item?.id,
                                                item?.type
                                              );
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      <span className="icon">
                                        <CommentIconFilled />
                                      </span>
                                      <span className="icon-filled">
                                        <CommentIconFilled />
                                      </span>

                                      <span className="posts-activity-actions--text">
                                        Comment
                                      </span>
                                    </span>
                                    {item.user.id !== user.id && (
                                      <span
                                        className="posts-activity-actions--button send-card"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleshowcard();
                                          setselectedSocialPost(item?.id);
                                          setcardselecteduser(item?.user?.name);
                                        }}
                                      >
                                        <span className="icon">
                                          <CardIcon />
                                        </span>
                                        <span className="icon-filled">
                                          <CardIcon />
                                        </span>
                                        <span className="posts-activity-actions--text">
                                          Send Card
                                        </span>
                                      </span>
                                    )}
                                    {item.user.id !== user.id && (
                                      <span className="posts-activity-actions--button meeting-room">
                                        <Link
                                          to={"/"}
                                          onClick={() => createThread(item)}
                                        >
                                          <span className="icon">
                                            <MeetingRoomIcon />
                                          </span>
                                          <span className="icon-filled">
                                            <MeetingRoomIconFilled />
                                          </span>
                                          <span className="posts-activity-actions--text">
                                            Meeting Room
                                          </span>
                                        </Link>
                                      </span>
                                    )}
                                    <CopyToClipboard
                                      text={`${window.location.origin}/post-detail/${item?.id}/${item?.type}`}
                                      // text={`https://busimeet.com/post-detail/${item?.slug}/${item?.type}`}
                                      onCopy={() => {
                                        setShareLink(true);
                                        toast.success("copied to clipboard!");
                                      }}
                                    >
                                      <span className="posts-activity-actions--button share">
                                        <span className="icon">
                                          <CopyIcon />
                                        </span>
                                        <span className="icon-filled">
                                          <CopyIconFilled />
                                        </span>

                                        <span className="posts-activity-actions--text">
                                          Copy Link
                                        </span>
                                      </span>
                                    </CopyToClipboard>
                                  </div>
                                  {CurrentComment?.post_id === item?.id ? (
                                    <>
                                      <div className="add-comments">
                                        <form>
                                          <div className="add-comments--form">
                                            <div className="profile-image">
                                              <Link
                                                to={`/edit-profile-seller/${user.slug}`}
                                              >
                                                <img
                                                  alt=""
                                                  src={user?.profile_image}
                                                  className="profile-pic"
                                                />
                                              </Link>
                                            </div>
                                            <div className="comment-form">
                                              <div className="form-field">
                                                <input
                                                  type="text"
                                                  name="cname"
                                                  id="cname"
                                                  className="form-input"
                                                  placeholder="Add a Comment"
                                                  value={
                                                    CommentV?.comment_Id ===
                                                    item?.id
                                                      ? CommentV?.comment
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    setComment((p) => ({
                                                      ...p,
                                                      comment: e.target.value,
                                                    }));
                                                    setComment((p) => ({
                                                      ...p,
                                                      comment_Id: item?.id,
                                                    }));
                                                  }}
                                                />
                                                <button
                                                  className="button button-primary"
                                                  onClick={(e) => {
                                                    e.preventDefault();

                                                    if (
                                                      user?.verified_at ===
                                                        null &&
                                                      user?.is_seller === 1
                                                    ) {
                                                      handleShowModal();
                                                    } else {
                                                      if (
                                                        CommentV?.comment === ""
                                                      ) {
                                                      } else {
                                                        Comment(
                                                          item?.id,
                                                          "social_post",
                                                          null,
                                                          index
                                                        );
                                                      }
                                                    }
                                                  }}
                                                >
                                                  Post
                                                </button>
                                              </div>
                                              <div className="tags">
                                                {SocialComTag?.items.map(
                                                  (tag, tagI) => {
                                                    return (
                                                      <>
                                                        <span
                                                          className="tag"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            setComment((p) => ({
                                                              ...p,
                                                              comment:
                                                                tag?.title,
                                                            }));
                                                            setComment((p) => ({
                                                              ...p,
                                                              comment_Id:
                                                                item?.id,
                                                            }));
                                                          }}
                                                        >
                                                          {tag?.title}{" "}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      {CurrentComment?.commentArray.length >
                                        0 &&
                                        CurrentComment?.commentArray.map(
                                          (com, ind) => {
                                            return (
                                              <>
                                                <div className="posts-comments">
                                                  <div className="comment-item">
                                                    <div className="user-profile">
                                                      <Link
                                                        to={
                                                          user?.slug ===
                                                          com?.user?.slug
                                                            ? `/edit-profile-seller/${user.slug}`
                                                            : `/edit-profile-seller/${com?.user?.slug}`
                                                        }
                                                      >
                                                        <div className="user-profile-image">
                                                          <img
                                                            src={
                                                              com?.user
                                                                ?.profile_image
                                                            }
                                                            className="profile-pic"
                                                          />
                                                        </div>
                                                      </Link>
                                                      <div className="comment-content">
                                                        <div className="comment-meta">
                                                          <div className="user-profile-content">
                                                            <Link
                                                              to={
                                                                user?.slug ===
                                                                com?.user?.slug
                                                                  ? `/edit-profile-seller/${user.slug}`
                                                                  : `/edit-profile-seller/${com?.user?.slug}`
                                                              }
                                                            >
                                                              <div className="user-profile-name">
                                                                <h6>
                                                                  {
                                                                    com?.user
                                                                      ?.name
                                                                  }
                                                                  <span>
                                                                    (Profile ID:{" "}
                                                                    {
                                                                      com?.user
                                                                        ?.id
                                                                    }
                                                                    )
                                                                  </span>
                                                                  <span>
                                                                    (
                                                                    {com?.user
                                                                      ?.is_buyer ===
                                                                    0
                                                                      ? "Seller"
                                                                      : "Buyer"}
                                                                    )
                                                                  </span>
                                                                </h6>
                                                                <p>
                                                                  {
                                                                    com?.user
                                                                      ?.i_am
                                                                  }{" "}
                                                                  at{" "}
                                                                  {
                                                                    com?.user
                                                                      ?.company_details
                                                                      ?.name
                                                                  }
                                                                </p>
                                                              </div>
                                                            </Link>
                                                          </div>
                                                          <div className="posts-action">
                                                            <div className="posts-time">
                                                              <p>
                                                                <HistoryIcon />
                                                                {momentT(
                                                                  com?.created_at
                                                                )}
                                                              </p>
                                                            </div>
                                                            {user?.id ===
                                                            com?.user?.id ? (
                                                              <div className="more-btn">
                                                                <div className="nav-item dropdown account-dropdown">
                                                                  <Link
                                                                    to={""}
                                                                    className="nav-link dropdown-toggle"
                                                                    href="#"
                                                                    id="navbarDropdown"
                                                                    role="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                  >
                                                                    <MoreIcon />
                                                                  </Link>
                                                                  <div
                                                                    className="dropdown-menu dropdown-menu-right"
                                                                    aria-labelledby="navbarDropdown"
                                                                  >
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to={"/"}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        DeleteComment(
                                                                          com?.id,
                                                                          item?.id,
                                                                          item?.type,
                                                                          "",
                                                                          index
                                                                        );
                                                                      }}
                                                                    >
                                                                      Delete
                                                                    </Link>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <div className="more-btn">
                                                                <div className="nav-item dropdown account-dropdown">
                                                                  <Link
                                                                    to={""}
                                                                    className="nav-link dropdown-toggle"
                                                                    href="#"
                                                                    id="navbarDropdown"
                                                                    role="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                  >
                                                                    <MoreIcon />
                                                                  </Link>
                                                                  <div
                                                                    className="dropdown-menu dropdown-menu-right"
                                                                    aria-labelledby="navbarDropdown"
                                                                  >
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to={"/"}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();

                                                                        sessionStorage.setItem(
                                                                          "reportid",
                                                                          item?.id
                                                                        );
                                                                        sessionStorage.setItem(
                                                                          "comid",
                                                                          com?.id
                                                                        );
                                                                        sessionStorage.setItem(
                                                                          "type",
                                                                          item?.type
                                                                        );

                                                                        // ReportComment(item?.id,com?.id,item?.type )
                                                                      }}
                                                                      data-toggle="modal"
                                                                      data-target="#ReportModal"
                                                                    >
                                                                      Report
                                                                    </Link>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                        <div className="comment-text">
                                                          <p>{com?.comment}</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="comment-actions">
                                                      <span
                                                        className="comment-actions--button reaction"
                                                        onClick={(e) => {
                                                          if (
                                                            user?.verified_at ===
                                                              null &&
                                                            user?.is_seller ===
                                                              1
                                                          ) {
                                                            handleShowModal();
                                                          } else {
                                                            if (
                                                              com?.interactions
                                                                ?.is_like > 0
                                                            ) {
                                                              reactionChild(
                                                                item?.id,
                                                                "social_post",
                                                                com?.id,
                                                                "unlike",
                                                                ind
                                                              );
                                                            } else {
                                                              reactionChild(
                                                                item?.id,
                                                                "social_post",
                                                                com?.id,
                                                                "",
                                                                ind
                                                              );
                                                            }
                                                          }
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            com?.interactions
                                                              ?.is_like > 0
                                                              ? "icon-filld"
                                                              : ""
                                                          }
                                                          onClick={(e) => {
                                                            if (
                                                              user?.verified_at ===
                                                                null &&
                                                              user?.is_seller ===
                                                                1
                                                            ) {
                                                              handleShowModal();
                                                            } else {
                                                              if (
                                                                com
                                                                  ?.interactions
                                                                  ?.is_like > 0
                                                              ) {
                                                                reactionChild(
                                                                  item?.id,
                                                                  "social_post",
                                                                  com?.id,
                                                                  "unlike",
                                                                  ind
                                                                );
                                                              } else {
                                                                reactionChild(
                                                                  item?.id,
                                                                  "social_post",
                                                                  com?.id,
                                                                  "",
                                                                  ind
                                                                );
                                                              }
                                                            }
                                                          }}
                                                        >
                                                          <LikeIconFilled />
                                                        </span>
                                                        <span className="comment-actions--text">
                                                          Like
                                                        </span>
                                                      </span>
                                                      {com?.interactions
                                                        ?.reactions > 0 && (
                                                        <span className="pr-3">
                                                          {com?.interactions
                                                            ?.reactions > 1
                                                            ? com?.interactions
                                                                ?.reactions +
                                                              " Likes"
                                                            : com?.interactions
                                                                ?.reactions ===
                                                              1
                                                            ? com?.interactions
                                                                ?.reactions +
                                                              " Like"
                                                            : ""}
                                                        </span>
                                                      )}
                                                      <span
                                                        className="comment-actions--button comment"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          if (
                                                            com?.id !=
                                                            CurrentComment?.parent_id
                                                          ) {
                                                            setCurrentComment(
                                                              (p) => ({
                                                                ...p,
                                                                child_Comment_List:
                                                                  [],
                                                              })
                                                            );
                                                          }
                                                          setCurrentComment(
                                                            (p) => ({
                                                              ...p,
                                                              parent_id:
                                                                com?.id,
                                                            })
                                                          );
                                                          setComment((p) => ({
                                                            ...p,
                                                            parent_id: com?.id,
                                                          }));
                                                          if (
                                                            com?.interactions
                                                              ?.child_comments >
                                                            0
                                                          ) {
                                                            if (
                                                              com?.id !=
                                                              CurrentComment?.parent_id
                                                            ) {
                                                              ChildCommentListing(
                                                                com?.id,
                                                                item?.id,
                                                                item?.type
                                                              );
                                                            }
                                                          }
                                                        }}
                                                      >
                                                        <span className="">
                                                          <CommentIconFilled
                                                            style={{
                                                              height: "12px",
                                                              width: "12px",
                                                            }}
                                                          />
                                                        </span>
                                                        <span className="comment-actions--text">
                                                          Reply
                                                        </span>
                                                      </span>
                                                      {com?.interactions
                                                        ?.child_comments > 1
                                                        ? com?.interactions
                                                            ?.child_comments +
                                                          " Comments"
                                                        : com?.interactions
                                                            ?.child_comments ===
                                                          1
                                                        ? com?.interactions
                                                            ?.child_comments +
                                                          " Comment"
                                                        : ""}
                                                    </div>
                                                  </div>
                                                </div>

                                                {com?.id ===
                                                  CurrentComment?.parent_id &&
                                                  CurrentComment
                                                    ?.child_Comment_List
                                                    .length > 0 &&
                                                  CurrentComment?.child_Comment_List.map(
                                                    (it, ig) => {
                                                      return (
                                                        <>
                                                          <div className="posts-comments ml-5 ">
                                                            <div className="comment-item">
                                                              <div className="user-profile">
                                                                <Link
                                                                  to={
                                                                    user?.slug ===
                                                                    it?.user
                                                                      ?.slug
                                                                      ? `/edit-profile-seller/${user.slug}`
                                                                      : `/edit-profile-seller/${it?.user?.slug}`
                                                                  }
                                                                >
                                                                  <div className="user-profile-image">
                                                                    <img
                                                                      src={
                                                                        it?.user
                                                                          ?.profile_image
                                                                      }
                                                                      className="profile-pic"
                                                                    />
                                                                  </div>
                                                                </Link>
                                                                <div className="comment-content">
                                                                  <div className="comment-meta">
                                                                    <div className="user-profile-content">
                                                                      <Link
                                                                        to={
                                                                          user?.slug ===
                                                                          it
                                                                            ?.user
                                                                            ?.slug
                                                                            ? `/edit-profile-seller/${user.slug}`
                                                                            : `/edit-profile-seller/${it?.user?.slug}`
                                                                        }
                                                                      >
                                                                        <div className="user-profile-name">
                                                                          <h6>
                                                                            {
                                                                              it
                                                                                ?.user
                                                                                ?.name
                                                                            }
                                                                            <span>
                                                                              (Profile
                                                                              ID:{" "}
                                                                              {
                                                                                it
                                                                                  ?.user
                                                                                  ?.id
                                                                              }
                                                                              )
                                                                            </span>
                                                                            <span>
                                                                              (
                                                                              {it
                                                                                ?.user
                                                                                ?.is_buyer ===
                                                                              0
                                                                                ? "Seller"
                                                                                : "Buyer"}
                                                                              )
                                                                            </span>
                                                                          </h6>
                                                                          <p>
                                                                            {
                                                                              it
                                                                                ?.user
                                                                                ?.i_am
                                                                            }{" "}
                                                                            at{" "}
                                                                            {
                                                                              it
                                                                                ?.user
                                                                                ?.company_details
                                                                                ?.name
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                      </Link>
                                                                    </div>
                                                                    <div className="posts-action">
                                                                      <div className="posts-time">
                                                                        <p>
                                                                          <HistoryIcon />
                                                                          {momentT(
                                                                            it?.created_at
                                                                          )}
                                                                        </p>
                                                                      </div>
                                                                      {user?.id ===
                                                                      it?.user
                                                                        ?.id ? (
                                                                        <div className="more-btn">
                                                                          <div className="nav-item dropdown account-dropdown">
                                                                            <Link
                                                                              to={
                                                                                ""
                                                                              }
                                                                              className="nav-link dropdown-toggle"
                                                                              href="#"
                                                                              id="navbarDropdown"
                                                                              role="button"
                                                                              data-toggle="dropdown"
                                                                              aria-haspopup="true"
                                                                              aria-expanded="false"
                                                                            >
                                                                              <MoreIcon />
                                                                            </Link>
                                                                            <div
                                                                              className="dropdown-menu dropdown-menu-right"
                                                                              aria-labelledby="navbarDropdown"
                                                                            >
                                                                              <Link
                                                                                className="dropdown-item"
                                                                                to={
                                                                                  "/"
                                                                                }
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.preventDefault();
                                                                                  DeleteComment(
                                                                                    it?.id,
                                                                                    item?.id,
                                                                                    item?.type,
                                                                                    true,
                                                                                    index
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Delete
                                                                              </Link>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <div className="more-btn">
                                                                          <div className="nav-item dropdown account-dropdown">
                                                                            <Link
                                                                              to={
                                                                                ""
                                                                              }
                                                                              className="nav-link dropdown-toggle"
                                                                              href="#"
                                                                              id="navbarDropdown"
                                                                              role="button"
                                                                              data-toggle="dropdown"
                                                                              aria-haspopup="true"
                                                                              aria-expanded="false"
                                                                            >
                                                                              <MoreIcon />
                                                                            </Link>
                                                                            <div
                                                                              className="dropdown-menu dropdown-menu-right"
                                                                              aria-labelledby="navbarDropdown"
                                                                            >
                                                                              <Link
                                                                                className="dropdown-item"
                                                                                to={
                                                                                  "/"
                                                                                }
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.preventDefault();

                                                                                  sessionStorage.setItem(
                                                                                    "reportid",
                                                                                    item?.id
                                                                                  );
                                                                                  sessionStorage.setItem(
                                                                                    "comid",
                                                                                    it?.id
                                                                                  );
                                                                                  sessionStorage.setItem(
                                                                                    "type",
                                                                                    item?.type
                                                                                  );
                                                                                  sessionStorage.setItem(
                                                                                    "parent_id",
                                                                                    CurrentComment?.parent_id
                                                                                  );

                                                                                  // ReportComment(item?.id,com?.id,item?.type )
                                                                                }}
                                                                                data-toggle="modal"
                                                                                data-target="#ReportModal"
                                                                              >
                                                                                Report
                                                                              </Link>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                  <div className="comment-text">
                                                                    <p>
                                                                      {
                                                                        it?.comment
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="comment-actions">
                                                                <span
                                                                  className="comment-actions--button reaction"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    if (
                                                                      user?.verified_at ===
                                                                        null &&
                                                                      user?.is_seller ===
                                                                        1
                                                                    ) {
                                                                      handleShowModal();
                                                                    } else {
                                                                      if (
                                                                        it
                                                                          ?.interactions
                                                                          ?.is_like >
                                                                        0
                                                                      ) {
                                                                        reactionChild(
                                                                          item?.id,
                                                                          item?.type,
                                                                          it?.id,
                                                                          "unlike",
                                                                          ig,
                                                                          "child"
                                                                        );
                                                                      } else {
                                                                        reactionChild(
                                                                          item?.id,
                                                                          item?.type,
                                                                          it?.id,
                                                                          "",
                                                                          ig,
                                                                          "child"
                                                                        );
                                                                      }
                                                                    }
                                                                  }}
                                                                >
                                                                  <span
                                                                    className={
                                                                      it
                                                                        ?.interactions
                                                                        ?.is_like >
                                                                      0
                                                                        ? "icon-filld"
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <LikeIconFilled />
                                                                  </span>
                                                                  <span className="comment-actions--text">
                                                                    Like
                                                                  </span>
                                                                </span>
                                                                {it
                                                                  ?.interactions
                                                                  ?.reactions >
                                                                  0 && (
                                                                  <span className="pr-3">
                                                                    {it
                                                                      ?.interactions
                                                                      ?.reactions >
                                                                    0
                                                                      ? it
                                                                          ?.interactions
                                                                          ?.reactions +
                                                                        " Like"
                                                                      : ""}
                                                                  </span>
                                                                )}

                                                                {user?.id ===
                                                                item?.user
                                                                  ?.id ? (
                                                                  <>
                                                                    <span
                                                                      className="comment-actions--button reaction"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();
                                                                        setReply(
                                                                          (
                                                                            p
                                                                          ) => ({
                                                                            ...p,
                                                                            CurrentReply:
                                                                              it?.id,
                                                                          })
                                                                        );
                                                                        ReplyListing(
                                                                          item?.type,
                                                                          item?.id,
                                                                          it?.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      <span
                                                                        className={
                                                                          "icon-filld"
                                                                        }
                                                                      >
                                                                        <CommentIconFilled
                                                                          style={{
                                                                            height:
                                                                              "12px",
                                                                            width:
                                                                              "12px",
                                                                          }}
                                                                        />
                                                                      </span>
                                                                      <span className="comment-actions--text">
                                                                        {user?.name !==
                                                                        replyUser[0]
                                                                          ?.name
                                                                          ? "View Reply"
                                                                          : "Reply"}
                                                                      </span>
                                                                    </span>
                                                                  </>
                                                                ) : it
                                                                    ?.interactions
                                                                    ?.child_comments >
                                                                  0 ? (
                                                                  <span
                                                                    className="comment-actions--button reaction"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      setReply(
                                                                        (
                                                                          p
                                                                        ) => ({
                                                                          ...p,
                                                                          CurrentReply:
                                                                            it?.id,
                                                                        })
                                                                      );
                                                                      ReplyListing(
                                                                        item?.type,
                                                                        item?.id,
                                                                        it?.id
                                                                      );
                                                                    }}
                                                                  >
                                                                    <span
                                                                      className={
                                                                        "icon-filld"
                                                                      }
                                                                    >
                                                                      <CommentIconFilled
                                                                        style={{
                                                                          height:
                                                                            "12px",
                                                                          width:
                                                                            "12px",
                                                                        }}
                                                                      />
                                                                    </span>
                                                                    <span className="comment-actions--text">
                                                                      {user?.name !==
                                                                      replyUser[0]
                                                                        ?.name
                                                                        ? "View Reply"
                                                                        : "Reply"}
                                                                    </span>
                                                                  </span>
                                                                ) : (
                                                                  ""
                                                                )}

                                                                {it
                                                                  ?.interactions
                                                                  ?.child_comments >
                                                                0 ? (
                                                                  <span>
                                                                    {it
                                                                      ?.interactions
                                                                      ?.child_comments ===
                                                                    1
                                                                      ? it
                                                                          ?.interactions
                                                                          ?.child_comments +
                                                                        " Reply "
                                                                      : it
                                                                          ?.interactions
                                                                          ?.child_comments +
                                                                        " Replies"}
                                                                  </span>
                                                                ) : (
                                                                  ""
                                                                )}

                                                                {it?.id ===
                                                                  Reply?.CurrentReply &&
                                                                  Reply
                                                                    ?.ReplyData
                                                                    .length >
                                                                    0 &&
                                                                  Reply?.ReplyData.map(
                                                                    (
                                                                      reply,
                                                                      reIndex
                                                                    ) => {
                                                                      return (
                                                                        <>
                                                                          <div className="posts-comments ml-5 ">
                                                                            <div className="comment-item">
                                                                              <div className="user-profile">
                                                                                <Link
                                                                                  to={`/edit-profile-seller/${reply?.user.slug}`}
                                                                                >
                                                                                  <div className="user-profile-image">
                                                                                    <img
                                                                                      alt=""
                                                                                      src={
                                                                                        reply
                                                                                          ?.user
                                                                                          ?.profile_image
                                                                                      }
                                                                                      className="profile-pic"
                                                                                    />
                                                                                  </div>
                                                                                </Link>
                                                                                <div className="comment-content">
                                                                                  <div className="comment-meta">
                                                                                    <div className="user-profile-content">
                                                                                      <Link
                                                                                        to={`/edit-profile-seller/${reply?.user.slug}`}
                                                                                      >
                                                                                        <div className="user-profile-name">
                                                                                          <h6>
                                                                                            {
                                                                                              reply
                                                                                                ?.user
                                                                                                ?.name
                                                                                            }
                                                                                            <span>
                                                                                              (Profile
                                                                                              ID:{" "}
                                                                                              {
                                                                                                reply
                                                                                                  ?.user
                                                                                                  ?.id
                                                                                              }

                                                                                              )
                                                                                            </span>
                                                                                            <span>
                                                                                              (
                                                                                              {reply
                                                                                                ?.user
                                                                                                ?.is_buyer ===
                                                                                              0
                                                                                                ? "Seller"
                                                                                                : "Buyer"}

                                                                                              )
                                                                                            </span>
                                                                                          </h6>
                                                                                        </div>
                                                                                      </Link>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="comment-text">
                                                                                    <p>
                                                                                      {
                                                                                        reply?.comment
                                                                                      }
                                                                                    </p>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </>
                                                                      );
                                                                    }
                                                                  )}

                                                                {it?.id ===
                                                                  Reply?.CurrentReply &&
                                                                  item?.user
                                                                    ?.id ===
                                                                    user?.id && (
                                                                    <>
                                                                      <div className="add-comments">
                                                                        <form>
                                                                          <div className="add-comments--form">
                                                                            <div className="profile-image">
                                                                              <Link
                                                                                to={`/edit-profile-seller/${user.slug}`}
                                                                              >
                                                                                <img
                                                                                  src={
                                                                                    user?.profile_image
                                                                                  }
                                                                                  className="profile-pic"
                                                                                />
                                                                              </Link>
                                                                            </div>
                                                                            <div className="comment-form">
                                                                              <div className="form-field">
                                                                                <input
                                                                                  type="text"
                                                                                  name="cname"
                                                                                  id="cname"
                                                                                  className="form-input"
                                                                                  placeholder="Add a Reply"
                                                                                  value={
                                                                                    Reply?.comment
                                                                                  }
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    setReply(
                                                                                      (
                                                                                        p
                                                                                      ) => ({
                                                                                        ...p,
                                                                                        comment:
                                                                                          e
                                                                                            .target
                                                                                            .value,
                                                                                      })
                                                                                    );
                                                                                    setComment(
                                                                                      (
                                                                                        p
                                                                                      ) => ({
                                                                                        ...p,
                                                                                        parent_id:
                                                                                          it?.id,
                                                                                      })
                                                                                    );
                                                                                  }}
                                                                                />
                                                                                <button
                                                                                  className="button button-primary"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    e.preventDefault();

                                                                                    if (
                                                                                      user?.verified_at ===
                                                                                        null &&
                                                                                      user?.is_seller ===
                                                                                        1
                                                                                    ) {
                                                                                      handleShowModal();
                                                                                    } else {
                                                                                      if (
                                                                                        Reply?.comment ===
                                                                                        ""
                                                                                      ) {
                                                                                      } else {
                                                                                        ReplyToMyPost(
                                                                                          item?.id,
                                                                                          "social_post",
                                                                                          it?.id
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  }}
                                                                                >
                                                                                  Post
                                                                                </button>
                                                                              </div>
                                                                              <div className="tags">
                                                                                {item?.type ===
                                                                                  "product" &&
                                                                                  ProductCommentTag?.items.map(
                                                                                    (
                                                                                      tag,
                                                                                      tagI
                                                                                    ) => {
                                                                                      return (
                                                                                        <>
                                                                                          <span
                                                                                            className="tag"
                                                                                            onClick={(
                                                                                              e
                                                                                            ) => {
                                                                                              e.preventDefault();
                                                                                              setReply(
                                                                                                (
                                                                                                  p
                                                                                                ) => ({
                                                                                                  ...p,
                                                                                                  comment:
                                                                                                    tag?.title,
                                                                                                })
                                                                                              );
                                                                                              setReply(
                                                                                                (
                                                                                                  p
                                                                                                ) => ({
                                                                                                  ...p,
                                                                                                  parent_id:
                                                                                                    item?.id,
                                                                                                })
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            {
                                                                                              tag?.title
                                                                                            }
                                                                                          </span>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                                {item?.type ===
                                                                                  "social_post" &&
                                                                                  SocialComTag?.items.map(
                                                                                    (
                                                                                      tag,
                                                                                      tagI
                                                                                    ) => {
                                                                                      return (
                                                                                        <>
                                                                                          <span
                                                                                            className="tag"
                                                                                            onClick={(
                                                                                              e
                                                                                            ) => {
                                                                                              e.preventDefault();
                                                                                              setReply(
                                                                                                (
                                                                                                  p
                                                                                                ) => ({
                                                                                                  ...p,
                                                                                                  comment:
                                                                                                    tag?.title,
                                                                                                })
                                                                                              );
                                                                                              setReply(
                                                                                                (
                                                                                                  p
                                                                                                ) => ({
                                                                                                  ...p,
                                                                                                  parent_id:
                                                                                                    item?.id,
                                                                                                })
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            {
                                                                                              tag?.title
                                                                                            }
                                                                                          </span>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </form>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}

                                                {com?.id ===
                                                CurrentComment?.parent_id ? (
                                                  <>
                                                    <div className="add-comments ml-5">
                                                      <form>
                                                        <div className="add-comments--form">
                                                          <div className="profile-image">
                                                            <Link
                                                              to={`/edit-profile-seller/${user.slug}`}
                                                            >
                                                              <img
                                                                src={
                                                                  user?.profile_image
                                                                }
                                                                className="profile-pic"
                                                              />
                                                            </Link>
                                                          </div>
                                                          <div className="comment-form">
                                                            <div className="form-field">
                                                              <input
                                                                type="text"
                                                                name="cname"
                                                                id="cname"
                                                                className="form-input"
                                                                placeholder="Add a Comment"
                                                                value={
                                                                  CommentV?.parent_id ===
                                                                  com?.id
                                                                    ? CommentV?.child_Comment
                                                                    : ""
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setComment(
                                                                    (p) => ({
                                                                      ...p,
                                                                      child_Comment:
                                                                        e.target
                                                                          .value,
                                                                    })
                                                                  );
                                                                  setComment(
                                                                    (p) => ({
                                                                      ...p,
                                                                      comment_Id:
                                                                        item?.id,
                                                                    })
                                                                  );
                                                                }}
                                                              />
                                                              <button
                                                                className="button button-primary"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();

                                                                  if (
                                                                    user?.verified_at ===
                                                                      null &&
                                                                    user?.is_seller ===
                                                                      1
                                                                  ) {
                                                                    handleShowModal();
                                                                  } else {
                                                                    if (
                                                                      CommentV?.child_Comment ===
                                                                      ""
                                                                    ) {
                                                                    } else {
                                                                      Comment(
                                                                        item?.id,
                                                                        "social_post",
                                                                        com?.id
                                                                      );
                                                                    }
                                                                  }
                                                                }}
                                                              >
                                                                Post
                                                              </button>
                                                            </div>
                                                            <div className="tags">
                                                              {SocialComTag?.items.map(
                                                                (tag, tagI) => {
                                                                  return (
                                                                    <>
                                                                      <span
                                                                        className="tag"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          setComment(
                                                                            (
                                                                              p
                                                                            ) => ({
                                                                              ...p,
                                                                              child_Comment:
                                                                                tag?.title,
                                                                            })
                                                                          );
                                                                          setComment(
                                                                            (
                                                                              p
                                                                            ) => ({
                                                                              ...p,
                                                                              comment_Id:
                                                                                item?.id,
                                                                            })
                                                                          );
                                                                        }}
                                                                      >
                                                                        {
                                                                          tag?.title
                                                                        }{" "}
                                                                      </span>
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                      {CurrentComment?.has_more === true ? (
                                        <div className="posts-comments-footer">
                                          <span
                                            href=""
                                            className="seemore-link"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setCurrentComment((p) => ({
                                                ...p,
                                                page: CurrentComment.page + 1,
                                              }));
                                            }}
                                          >
                                            Load more comments <CaretDownIcon />
                                          </span>
                                        </div>
                                      ) : (
                                        " "
                                      )}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : item?.type === "post_to_buy" ? (
                            <div
                              className="card post-buyer"
                              ref={lastBookElementRef}
                              key={item}
                            >
                              <div className="card-body">
                                <div
                                  className="user-profile"
                                  style={{
                                    marginBottom: "0px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <Link
                                    to={`/edit-profile-seller/${item?.user?.slug}`}
                                  >
                                    <div className="user-profile-image">
                                      <img
                                        alt=""
                                        src={item?.user?.profile_image}
                                        className="profile-pic"
                                      />
                                    </div>
                                  </Link>
                                  <div className="user-profile-content">
                                    <Link
                                      to={`/edit-profile-seller/${item?.user?.slug}`}
                                    >
                                      <div className="user-profile-name">
                                        <h6>
                                          {item?.user?.name}
                                          <span>
                                            (Profile ID: {item?.user?.id})
                                          </span>
                                        </h6>
                                        <p>
                                          {item?.user?.company_details
                                            ?.designation != null
                                            ? "Partner" +
                                              item?.user?.company_details
                                                ?.designation +
                                              "at"
                                            : " "}
                                          {item?.user?.company_details?.name}
                                        </p>
                                        <span className="post-tag">
                                          {item?.type === "post_to_buy"
                                            ? "Buyer"
                                            : "Seller"}
                                        </span>

                                        {item?.user?.id === user?.id ||
                                        item?.user?.has_connection === 1 ? (
                                          ""
                                        ) : item?.user
                                            ?.has_connection_requested === 1 ||
                                          sent?.items.filter(
                                            (word) => word === item?.user?.id
                                          ).length > 0 ? (
                                          <button
                                            className="btn btn-success button-connect"
                                            style={{ maxWidth: "fit-content" }}
                                          >
                                            Request Sent
                                          </button>
                                        ) : (
                                          <button
                                            className="button button-primary button-connect"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1 &&
                                                user?.is_seller === 1
                                              ) {
                                                // swal("error","Please verify your profile","error");
                                                // sentConnectionRequest(item?.id);
                                                handleShowModal();
                                              } else {
                                                sentConnectionRequest(
                                                  item?.user?.id
                                                );
                                              }
                                            }}
                                          >
                                            <ConnectIcon /> Connect
                                          </button>
                                        )}
                                      </div>
                                    </Link>
                                  </div>
                                  <div className="posts-action">
                                    <div className="posts-time">
                                      <p>
                                        <HistoryIcon />
                                        {""}
                                        {momentT(item?.created_at)}
                                        {""}
                                      </p>
                                      <p>
                                        <EditIcon />
                                        {""}
                                        {momentT(item?.updated_at)}
                                      </p>
                                    </div>
                                    {user?.id === item?.user?.id ? (
                                      <div className="more-btn">
                                        <div className="nav-item dropdown account-dropdown">
                                          <Link
                                            to={""}
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <MoreIcon />
                                          </Link>
                                          <div
                                            className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="navbarDropdown"
                                          >
                                            <button
                                              className="dropdown-item"
                                              onClick={() => {
                                                navigate("/edit-post-buy", {
                                                  state: item,
                                                });
                                              }}
                                              style={{ fontSize: 12 }}
                                            >
                                              Edit
                                            </button>
                                            <button
                                              className="dropdown-item"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                swal({
                                                  title: "Are you sure?",
                                                  text: "Are you sure to delete this product!",
                                                  icon: "warning",
                                                  dangerMode: true,
                                                  buttons: ["cancel", "ok"],
                                                }).then((willDelete) => {
                                                  if (willDelete) {
                                                    handleDeletePost(
                                                      item?.id,
                                                      index
                                                    );
                                                  } else {
                                                  }
                                                });
                                              }}
                                              style={{ fontSize: 12 }}
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="more-btn">
                                        <div className="nav-item dropdown account-dropdown">
                                          <Link
                                            to={""}
                                            className="nav-link dropdown-toggle"
                                            href="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                          >
                                            <MoreIcon />
                                          </Link>
                                          <div
                                            className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="navbarDropdown"
                                          >
                                            <Link
                                              className="dropdown-item"
                                              to={"/"}
                                              onClick={(e) => {
                                                e.preventDefault();

                                                sessionStorage.setItem(
                                                  "reportid",
                                                  item?.id
                                                );
                                                sessionStorage.setItem(
                                                  "post",
                                                  "Post"
                                                );
                                                sessionStorage.setItem(
                                                  "type",
                                                  item?.type
                                                );
                                              }}
                                              data-toggle="modal"
                                              data-target="#ReportModal"
                                            >
                                              Report
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <p
                                  style={{
                                    marginBottom: "10px",
                                    padding: "0px 10px",
                                    textAlign: "justify",
                                  }}
                                  className="posts-summery"
                                >
                                  {item?.post_template?.content}
                                </p>
                                <div className="posts-product-details flex-box">
                                  <div className="flex-item posts-image-wrap">
                                    <div className="posts-image">
                                      {item.media.length > 1 ? (
                                        <div className="posts-image">
                                          <Slider
                                            {...settingsPost}
                                            arrows={true}
                                          >
                                            {item.media.map((imagess) => (
                                              <img
                                                src={imagess.file}
                                                alt=""
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setImageModalId(index);
                                                  setImageModal(true);
                                                }}
                                              />
                                            ))}
                                          </Slider>
                                        </div>
                                      ) : (
                                        <div className="posts-image">
                                          <a href="">
                                            <img
                                              src={item?.media[0]?.file}
                                              alt=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setImageModalId(index);
                                                setImageModal(true);
                                              }}
                                            />
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-item posts-info-wrap">
                                    <div className="tags">
                                      <div className="tags--title">
                                        Product Details
                                      </div>
                                      <span className="tag">{item?.name}</span>
                                      {item?.product_code != null ? (
                                        <>
                                          <span className="tag">
                                            Item code: {item?.product_code}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.brand?.name != undefined ? (
                                        <>
                                          <span className="tag">
                                            Brand : {item?.brand?.name}
                                          </span>{" "}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="tags">
                                      {item?.amount === null &&
                                      item?.brand?.id === 0 &&
                                      item?.credit_amount === null &&
                                      item?.description === null &&
                                      item?.order_qty === null &&
                                      item?.shape === null &&
                                      item?.size === null &&
                                      item?.storage_capacity === null &&
                                      item?.unit?.id === 0 ? (
                                        ""
                                      ) : (
                                        <div className="tags--title">
                                          Other Product Details Provided By
                                          Buyer
                                        </div>
                                      )}

                                      {item?.storage_capacity != null ? (
                                        <>
                                          <span className="tag">
                                            Storage capacity:
                                            {item?.storage_capacity}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.size != null ? (
                                        <>
                                          <span className="tag">
                                            Size :{item?.size}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.shape != null ? (
                                        <>
                                          <span className="tag">
                                            Shape :{item?.shape}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.order_qty != null ? (
                                        <>
                                          <span className="tag">
                                            Required Qty :{item?.order_qty}{" "}
                                            {item?.unit?.id != 0 ? (
                                              <>{item?.unit?.name}</>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.amount != null ? (
                                        <>
                                          <span className="tag">
                                            Expected Price:{item?.amount}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.brand?.id != 0 ? (
                                        <>
                                          <span className="tag">
                                            brand :{item?.brand?.name}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.credit_amount != null ? (
                                        <>
                                          <span className="tag">
                                            brand :{item?.credit_amount}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {item?.hsn_code != null ? (
                                        <>
                                          <span className="tag">
                                            HSN Code:{item?.hsn_code}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {item?.gst_rate != null ? (
                                        <>
                                          <span className="tag">
                                            GST rate :{item?.gst_rate}
                                          </span>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {item?.credit_policy && (
                                      <span
                                        className="attachment"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setCredPolicyData(
                                            item?.credit_policy
                                          );
                                          handleShowModalCred();
                                        }}
                                      >
                                        <AttachmentIcon />
                                      </span>
                                    )}

                                    <div className="tags">
                                      <div className="tags--title">
                                        Price and Delivery Details
                                      </div>
                                      <span className="tag">
                                        Preferred Transport:{" "}
                                        {
                                          item?.delivery_details
                                            ?.preferred_transport
                                        }
                                      </span>
                                      <span className="tag">
                                        Required In city :
                                        {
                                          item?.delivery_details
                                            ?.required_in_city
                                        }
                                      </span>
                                    </div>
                                    {item?.catalogue.length > 0 && (
                                      <span>
                                        <a
                                          href={item?.catalogue[0].file}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <AttachmentIcon /> Product Catalogue{" "}
                                        </a>
                                      </span>
                                    )}
                                  </div>
                                  <div className="posts-data">
                                    <div className="posts-connections">
                                      {/*<span className='profiles'>
                            <img src={profilePic} className='profile-pic' />
                            <img src={profilePic} className='profile-pic' />
                            <img src={profilePic} className='profile-pic' />
                            <img src={profilePic} className='profile-pic' />
                          </span>*/}
                                      {item?.interactions?.reactions > 0 ||
                                      item?.interactions?.reaction > 0 ? (
                                        <span>
                                          {item?.interactions?.reaction ===
                                          "like" ? (
                                            <LikeIconFilled />
                                          ) : (
                                            <>
                                              {item.interactions.reaction ===
                                              "celebrate" ? (
                                                <CelebrateIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "love" ? (
                                                <LoveIcon />
                                              ) : item?.interactions
                                                  ?.reaction ===
                                                "insightful" ? (
                                                <InsightfulIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "support" ? (
                                                <CareIcon />
                                              ) : item?.interactions
                                                  ?.reaction === "curious" ? (
                                                <CuriousIcon />
                                              ) : (
                                                <LikeIconFilled />
                                              )}
                                            </>
                                          )}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <span>
                                        {item?.interactions?.reaction ===
                                          null &&
                                          item.interactions.reactions === 1 &&
                                          `${item.interactions.reactions}`}
                                        {item?.interactions?.reaction !==
                                          null &&
                                          item.interactions.reactions === 1 &&
                                          `${item.interactions.reactions}`}
                                        {item?.interactions?.reaction ===
                                          null &&
                                          item.interactions.reactions > 1 &&
                                          `${item.interactions.reactions} Likes`}
                                        {item?.interactions?.reaction !==
                                          null &&
                                          item.interactions.reactions === 2 &&
                                          `You and ${
                                            item.interactions.reactions - 1
                                          } other`}
                                        {item?.interactions?.reaction !==
                                          null &&
                                          item.interactions.reactions > 2 &&
                                          `You and ${
                                            item.interactions.reactions - 1
                                          } others`}
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                      }}
                                      className="comments-deals"
                                      onClick={(e) => {
                                        if (
                                          user?.verified_at === null &&
                                          user?.is_seller === 1 &&
                                          user?.is_seller === 1
                                        ) {
                                          handleShowModal();
                                        } else {
                                          // if (
                                          //   e.target.style.fill === ""
                                          // ) {
                                          //   e.target.style.fill =
                                          //     "#0a95ff";
                                          //   reaction(item?.id, "product");
                                          // } else {
                                          //   e.target.style.fill = "";
                                          // }
                                          if (item?.type === "product") {
                                            if (
                                              ProductCommentTag?.items.length <=
                                              0
                                            ) {
                                              CommentTag(item?.type);
                                            }
                                          }
                                          if (item?.type === "post_to_sell") {
                                            if (
                                              PostSellComTag?.items.length <= 0
                                            ) {
                                              CommentTag(item?.type);
                                            }
                                          }
                                          if (
                                            item?.interactions?.comments === 0
                                          ) {
                                            // alert("test ")
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_id: item?.id,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_type: item?.type,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              commentArray: [],
                                            }));
                                          } else {
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_id: item?.id,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_type: item?.type,
                                            }));
                                            if (
                                              item?.id !=
                                              CurrentComment?.post_id
                                            ) {
                                              CommentListing(
                                                item?.id,
                                                item?.type
                                              );
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      {Number(item?.interactions?.comments) >
                                        0 && (
                                        <CommentIconFilled
                                          style={{
                                            height: "12px",
                                            width: "12px",
                                          }}
                                        />
                                      )}
                                      <span style={{ paddingLeft: "5px" }}>
                                        {Number(item?.interactions?.comments) >
                                        0
                                          ? `${item.interactions.comments} Comments`
                                          : ""}
                                      </span>

                                      {/* <span>7 Cards Received</span> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="posts-activity">
                                  <div
                                    className="posts-activity-actions"
                                    style={{
                                      fontSize: "12px",
                                      padding: "7px 0px 0px 0px",
                                    }}
                                  >
                                    <span className="posts-activity-actions--button reaction">
                                      <span
                                        className={
                                          item?.interactions?.reaction ===
                                            "like" ||
                                          item?.interactions?.reaction ===
                                            "support" ||
                                          item?.interactions?.reaction ===
                                            "love" ||
                                          item?.interactions?.reaction ===
                                            "celebrate" ||
                                          item?.interactions?.reaction ===
                                            "insightful" ||
                                          item?.interactions?.reaction ===
                                            "curious"
                                            ? "icon-reacted"
                                            : ""
                                        }
                                        onClick={(e) => {
                                          if (
                                            user?.verified_at === null &&
                                            user?.is_seller === 1
                                          ) {
                                            handleShowModal();
                                          } else {
                                            if (
                                              item?.interactions?.reaction ===
                                                "like" ||
                                              item?.interactions?.reaction ===
                                                "celebrate" ||
                                              item?.interactions?.reaction ===
                                                "love" ||
                                              item?.interactions?.reaction ===
                                                "insightful" ||
                                              item?.interactions?.reaction ===
                                                "support" ||
                                              item?.interactions?.reaction ===
                                                "curious"
                                            ) {
                                              reaction(
                                                item?.id,
                                                "product",
                                                "",
                                                "unlike",
                                                index
                                              );
                                            } else {
                                              reaction(
                                                item?.id,
                                                "product",
                                                "",
                                                "",
                                                index,
                                                "like"
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        {item?.interactions?.reaction ===
                                        "like" ? (
                                          <LikeIconFilled />
                                        ) : (
                                          <>
                                            {item.interactions.reaction ===
                                            "celebrate" ? (
                                              <CelebrateIcon />
                                            ) : item?.interactions?.reaction ===
                                              "love" ? (
                                              <LoveIcon />
                                            ) : item?.interactions?.reaction ===
                                              "insightful" ? (
                                              <InsightfulIcon />
                                            ) : item?.interactions?.reaction ===
                                              "support" ? (
                                              <CareIcon />
                                            ) : item?.interactions?.reaction ===
                                              "curious" ? (
                                              <CuriousIcon />
                                            ) : (
                                              <LikeIconFilled />
                                            )}
                                          </>
                                        )}
                                      </span>
                                      <span className="posts-activity-actions--text">
                                        {item?.interactions?.reaction === "like"
                                          ? "Like"
                                          : item?.interactions?.reaction ===
                                            "celebrate"
                                          ? "Celebrate"
                                          : item?.interactions?.reaction ===
                                            "support"
                                          ? "Support"
                                          : item?.interactions?.reaction ===
                                            "insightful"
                                          ? "Insightful"
                                          : item?.interactions?.reaction ===
                                            "love"
                                          ? "Love"
                                          : item?.interactions?.reaction ===
                                            "curious"
                                          ? "Curious"
                                          : "Like"}
                                      </span>
                                      <div className="reaction-hover">
                                        <span>
                                          <ReactLikeIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "like"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "",
                                                    index,
                                                    "like"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <CelebrateIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "celebrate"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "",
                                                    index,
                                                    "celebrate"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <CareIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "support"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "",
                                                    index,
                                                    "support"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <LoveIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "love"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "",
                                                    index,
                                                    "love"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <InsightfulIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "insightful"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "",
                                                    index,
                                                    "insightful"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                        <span>
                                          <CuriousIcon
                                            onClick={(e) => {
                                              if (
                                                user?.verified_at === null &&
                                                user?.is_seller === 1
                                              ) {
                                                handleShowModal();
                                              } else {
                                                if (
                                                  item?.interactions
                                                    ?.reaction === "curious"
                                                ) {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "unlike",
                                                    index
                                                  );
                                                } else {
                                                  reaction(
                                                    item?.id,
                                                    "product",
                                                    "",
                                                    "",
                                                    index,
                                                    "curious"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </span>
                                    <span
                                      className="posts-activity-actions--button comment"
                                      onClick={(e) => {
                                        if (
                                          user?.verified_at === null &&
                                          user?.is_seller === 1
                                        ) {
                                          handleShowModal();
                                        } else {
                                          // if (
                                          //   e.target.style.fill === ""
                                          // ) {
                                          //   e.target.style.fill =
                                          //     "#0a95ff";
                                          //   reaction(item?.id, "product");
                                          // } else {
                                          //   e.target.style.fill = "";
                                          // }

                                          if (
                                            PostBuyComTag?.items.length <= 0
                                          ) {
                                            CommentTag(item?.type);
                                          }
                                          if (
                                            item?.interactions?.comments <= 0
                                          ) {
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_id: item?.id,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_type: item?.type,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              commentArray: [],
                                            }));
                                          } else {
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_id: item?.id,
                                            }));
                                            setCurrentComment((p) => ({
                                              ...p,
                                              post_type: item?.type,
                                            }));
                                            if (
                                              item?.id !=
                                              CurrentComment?.post_id
                                            ) {
                                              CommentListing(
                                                item?.id,
                                                item?.type
                                              );
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      <span className="icon">
                                        <CommentIconFilled />
                                      </span>
                                      <span className="icon-filled">
                                        <CommentIconFilled />
                                      </span>
                                      <span className="posts-activity-actions--text">
                                        Comment
                                      </span>
                                    </span>
                                    {!accountFor.buyer &&
                                      item.user.id !== user.id && (
                                        <>
                                          <Link
                                            to=""
                                            onClick={() =>
                                              setProductDetail(item)
                                            }
                                            style={{ color: "inherit" }}
                                            data-toggle="modal"
                                            data-target="#SendQuote"
                                            className="posts-activity-actions--button comment"
                                          >
                                            <span className="icon">
                                              <SendQuoteIcon />
                                            </span>
                                            <span className="icon-filled">
                                              <SendQuoteIcon />
                                            </span>

                                            <span className="posts-activity-actions--text">
                                              Send Quote
                                            </span>
                                          </Link>
                                        </>
                                      )}
                                    {item.user.id !== user.id && (
                                      <span className="posts-activity-actions--button meeting-room">
                                        <Link
                                          to={"/"}
                                          onClick={() => createThread(item)}
                                        >
                                          <span className="icon">
                                            <MeetingRoomIcon />
                                          </span>
                                          <span className="icon-filled">
                                            <MeetingRoomIconFilled />
                                          </span>
                                          <span className="posts-activity-actions--text">
                                            Meeting Room
                                          </span>
                                        </Link>
                                      </span>
                                    )}
                                    <CopyToClipboard
                                      text={`${window.location.origin}/post-detail/${item?.id}/${item?.type}`}
                                      // text={`https://busimeet.com/post-detail/${item?.id}/${item?.type}`}
                                      onCopy={() => {
                                        setShareLink(true);
                                        toast.success("copied to clipboard!");
                                      }}
                                    >
                                      <span className="posts-activity-actions--button share">
                                        <span className="icon">
                                          <CopyIcon />
                                        </span>
                                        <span className="icon-filled">
                                          <CopyIconFilled />
                                        </span>
                                        <span className="posts-activity-actions--text">
                                          Copy Link
                                        </span>
                                      </span>
                                    </CopyToClipboard>
                                  </div>
                                  {CurrentComment?.post_id === item?.id ? (
                                    <>
                                      <div className="add-comments">
                                        <form>
                                          <div className="add-comments--form">
                                            <div className="profile-image">
                                              <Link
                                                to={`/edit-profile-seller/${user.slug}`}
                                              >
                                                <img
                                                  src={user?.profile_image}
                                                  alt=""
                                                  className="profile-pic"
                                                />
                                              </Link>
                                            </div>
                                            <div className="comment-form">
                                              <div className="form-field">
                                                <input
                                                  type="text"
                                                  name="cname"
                                                  id="cname"
                                                  className="form-input"
                                                  placeholder="Add a Comment"
                                                  value={
                                                    CommentV?.comment_Id ===
                                                    item?.id
                                                      ? CommentV?.comment
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    setComment((p) => ({
                                                      ...p,
                                                      comment: e.target.value,
                                                    }));
                                                    setComment((p) => ({
                                                      ...p,
                                                      comment_Id: item?.id,
                                                    }));
                                                  }}
                                                />
                                                <button
                                                  className="button button-primary"
                                                  onClick={(e) => {
                                                    e.preventDefault();

                                                    if (
                                                      user?.verified_at ===
                                                        null &&
                                                      user?.is_seller === 1
                                                    ) {
                                                      handleShowModal();
                                                    } else {
                                                      if (
                                                        CommentV?.comment === ""
                                                      ) {
                                                      } else {
                                                        Comment(
                                                          item?.id,
                                                          "product",
                                                          null,
                                                          index
                                                        );
                                                      }
                                                    }
                                                  }}
                                                >
                                                  Post
                                                </button>
                                              </div>
                                              <div className="tags">
                                                {PostBuyComTag?.items.map(
                                                  (tag, tagI) => {
                                                    return (
                                                      <>
                                                        <span
                                                          className="tag"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            setComment((p) => ({
                                                              ...p,
                                                              comment:
                                                                tag?.title,
                                                            }));
                                                            setComment((p) => ({
                                                              ...p,
                                                              comment_Id:
                                                                item?.id,
                                                            }));
                                                          }}
                                                        >
                                                          {tag?.title}
                                                        </span>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      {CurrentComment?.commentArray.length >
                                        0 &&
                                        CurrentComment?.commentArray.map(
                                          (com, ind) => {
                                            return (
                                              <>
                                                <div className="posts-comments">
                                                  <div className="comment-item">
                                                    <div className="user-profile">
                                                      <Link
                                                        to={
                                                          user?.slug ===
                                                          com?.user?.clug
                                                            ? `/edit-profile-seller/${user.slug}`
                                                            : `/edit-profile-seller/${com?.user?.slug}`
                                                        }
                                                      >
                                                        <div className="user-profile-image">
                                                          <img
                                                            alt=""
                                                            src={
                                                              com?.user
                                                                ?.profile_image
                                                            }
                                                            className="profile-pic"
                                                          />
                                                        </div>
                                                      </Link>
                                                      <div className="comment-content">
                                                        <div className="comment-meta">
                                                          <div className="user-profile-content">
                                                            <Link
                                                              to={
                                                                user?.slug ===
                                                                com?.user?.clug
                                                                  ? `/edit-profile-seller/${user.slug}`
                                                                  : `/edit-profile-seller/${com?.user?.slug}`
                                                              }
                                                            >
                                                              <div className="user-profile-name">
                                                                <h6>
                                                                  {
                                                                    com?.user
                                                                      ?.name
                                                                  }
                                                                  <span>
                                                                    (Profile ID:{" "}
                                                                    {
                                                                      com?.user
                                                                        ?.id
                                                                    }
                                                                    )
                                                                  </span>
                                                                  <span>
                                                                    (
                                                                    {com?.user
                                                                      ?.is_buyer ===
                                                                    0
                                                                      ? "Seller"
                                                                      : "Buyer"}
                                                                    )
                                                                  </span>
                                                                </h6>
                                                                <p>
                                                                  {
                                                                    com?.user
                                                                      ?.i_am
                                                                  }{" "}
                                                                  at{" "}
                                                                  {
                                                                    com?.user
                                                                      ?.company_details
                                                                      ?.name
                                                                  }
                                                                </p>
                                                              </div>
                                                            </Link>
                                                          </div>
                                                          <div className="posts-action">
                                                            <div className="posts-time">
                                                              <p>
                                                                <HistoryIcon />
                                                                {momentT(
                                                                  com?.created_at
                                                                )}
                                                              </p>
                                                            </div>
                                                            {user?.id ===
                                                            com?.user?.id ? (
                                                              <div className="more-btn">
                                                                <div className="nav-item dropdown account-dropdown">
                                                                  <Link
                                                                    to={""}
                                                                    className="nav-link dropdown-toggle"
                                                                    href="#"
                                                                    id="navbarDropdown"
                                                                    role="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                  >
                                                                    <MoreIcon />
                                                                  </Link>
                                                                  <div
                                                                    className="dropdown-menu dropdown-menu-right"
                                                                    aria-labelledby="navbarDropdown"
                                                                  >
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to={"/"}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();

                                                                        DeleteComment(
                                                                          com?.id,
                                                                          item?.id,
                                                                          item?.type,
                                                                          "",
                                                                          index
                                                                        );
                                                                      }}
                                                                    >
                                                                      Delete
                                                                    </Link>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              <div className="more-btn">
                                                                <div className="nav-item dropdown account-dropdown">
                                                                  <Link
                                                                    to={""}
                                                                    className="nav-link dropdown-toggle"
                                                                    href="#"
                                                                    id="navbarDropdown"
                                                                    role="button"
                                                                    data-toggle="dropdown"
                                                                    aria-haspopup="true"
                                                                    aria-expanded="false"
                                                                  >
                                                                    <MoreIcon />
                                                                  </Link>
                                                                  <div
                                                                    className="dropdown-menu dropdown-menu-right"
                                                                    aria-labelledby="navbarDropdown"
                                                                  >
                                                                    <Link
                                                                      className="dropdown-item"
                                                                      to={"/"}
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();

                                                                        sessionStorage.setItem(
                                                                          "reportid",
                                                                          item?.id
                                                                        );
                                                                        sessionStorage.setItem(
                                                                          "comid",
                                                                          com?.id
                                                                        );
                                                                        sessionStorage.setItem(
                                                                          "type",
                                                                          item?.type
                                                                        );

                                                                        // ReportComment(item?.id,com?.id,item?.type )
                                                                      }}
                                                                      data-toggle="modal"
                                                                      data-target="#ReportModal"
                                                                    >
                                                                      Report
                                                                    </Link>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                        <div className="comment-text">
                                                          <p>{com?.comment}</p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="comment-actions">
                                                      <span
                                                        className="comment-actions--button reaction"
                                                        onClick={(e) => {
                                                          if (
                                                            user?.verified_at ===
                                                              null &&
                                                            user?.is_seller ===
                                                              1
                                                          ) {
                                                            handleShowModal();
                                                          } else {
                                                            if (
                                                              com?.interactions
                                                                ?.is_like > 0
                                                            ) {
                                                              reactionChild(
                                                                item?.id,
                                                                "product",
                                                                com?.id,
                                                                "unlike",
                                                                ind
                                                              );
                                                            } else {
                                                              reactionChild(
                                                                item?.id,
                                                                "product",
                                                                com?.id,
                                                                "",
                                                                ind
                                                              );
                                                            }
                                                          }
                                                        }}
                                                      >
                                                        <span
                                                          className={
                                                            com?.interactions
                                                              ?.is_like > 0
                                                              ? "icon-filld"
                                                              : ""
                                                          }
                                                        >
                                                          <LikeIconFilled
                                                            style={{
                                                              width: "12px",
                                                              height: "12px",
                                                            }}
                                                          />
                                                        </span>
                                                        <span className="comment-actions--text">
                                                          Like
                                                        </span>
                                                      </span>
                                                      {com?.interactions
                                                        ?.reactions > 0 && (
                                                        <span className="pr-3">
                                                          {com?.interactions
                                                            ?.reactions > 1
                                                            ? com?.interactions
                                                                ?.reactions +
                                                              " Likes"
                                                            : com?.interactions
                                                                ?.reactions ===
                                                              1
                                                            ? com?.interactions
                                                                ?.reactions +
                                                              " Like"
                                                            : ""}
                                                        </span>
                                                      )}
                                                      <span
                                                        className="comment-actions--button comment"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          if (
                                                            com?.id !=
                                                            CurrentComment?.parent_id
                                                          ) {
                                                            setCurrentComment(
                                                              (p) => ({
                                                                ...p,
                                                                child_Comment_List:
                                                                  [],
                                                              })
                                                            );
                                                          }
                                                          setCurrentComment(
                                                            (p) => ({
                                                              ...p,
                                                              parent_id:
                                                                com?.id,
                                                            })
                                                          );
                                                          setComment((p) => ({
                                                            ...p,
                                                            parent_id: com?.id,
                                                          }));
                                                          if (
                                                            com?.interactions
                                                              ?.child_comments >
                                                            0
                                                          ) {
                                                            if (
                                                              com?.id !=
                                                              CurrentComment?.parent_id
                                                            ) {
                                                              ChildCommentListing(
                                                                com?.id,
                                                                item?.id,
                                                                item?.type
                                                              );
                                                            }
                                                          }
                                                        }}
                                                      >
                                                        <span className="">
                                                          <CommentIconFilled
                                                            style={{
                                                              height: "12px",
                                                              width: "12px",
                                                            }}
                                                          />
                                                        </span>

                                                        <span className="comment-actions--text">
                                                          Reply
                                                        </span>
                                                      </span>

                                                      {com?.interactions
                                                        ?.child_comments > 1
                                                        ? com?.interactions
                                                            ?.child_comments +
                                                          " Comments"
                                                        : com?.interactions
                                                            ?.child_comments ===
                                                          1
                                                        ? com?.interactions
                                                            ?.child_comments +
                                                          " Comment"
                                                        : ""}
                                                    </div>
                                                  </div>
                                                </div>

                                                {com?.id ===
                                                  CurrentComment?.parent_id &&
                                                  CurrentComment
                                                    ?.child_Comment_List
                                                    .length > 0 &&
                                                  CurrentComment?.child_Comment_List.map(
                                                    (it, ig) => {
                                                      return (
                                                        <>
                                                          <div className="posts-comments ml-5 ">
                                                            <div className="comment-item">
                                                              <div className="user-profile">
                                                                <Link
                                                                  to={
                                                                    user?.slug ===
                                                                    it?.user
                                                                      ?.clug
                                                                      ? `/edit-profile-seller/${user.slug}`
                                                                      : `/edit-profile-seller/${it?.user?.slug}`
                                                                  }
                                                                >
                                                                  <div className="user-profile-image">
                                                                    <img
                                                                      src={
                                                                        it?.user
                                                                          ?.profile_image
                                                                      }
                                                                      className="profile-pic"
                                                                    />
                                                                  </div>
                                                                </Link>
                                                                <div className="comment-content">
                                                                  <div className="comment-meta">
                                                                    <div className="user-profile-content">
                                                                      <Link
                                                                        to={
                                                                          user?.slug ===
                                                                          it
                                                                            ?.user
                                                                            ?.clug
                                                                            ? `/edit-profile-seller/${user.slug}`
                                                                            : `/edit-profile-seller/${it?.user?.slug}`
                                                                        }
                                                                      >
                                                                        <div className="user-profile-name">
                                                                          <h6>
                                                                            {
                                                                              it
                                                                                ?.user
                                                                                ?.name
                                                                            }
                                                                            <span>
                                                                              (Profile
                                                                              ID:{" "}
                                                                              {
                                                                                it
                                                                                  ?.user
                                                                                  ?.id
                                                                              }
                                                                              )
                                                                            </span>
                                                                            <span>
                                                                              (
                                                                              {it
                                                                                ?.user
                                                                                ?.is_buyer ===
                                                                              0
                                                                                ? "Seller"
                                                                                : "Buyer"}
                                                                              )
                                                                            </span>
                                                                          </h6>
                                                                          <p>
                                                                            {
                                                                              it
                                                                                ?.user
                                                                                ?.i_am
                                                                            }{" "}
                                                                            at{" "}
                                                                            {
                                                                              it
                                                                                ?.user
                                                                                ?.company_details
                                                                                ?.name
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                      </Link>
                                                                    </div>
                                                                    <div className="posts-action">
                                                                      <div className="posts-time">
                                                                        <p>
                                                                          <HistoryIcon />
                                                                          {momentT(
                                                                            it?.created_at
                                                                          )}
                                                                        </p>
                                                                      </div>
                                                                      {user?.id ===
                                                                      it?.user
                                                                        ?.id ? (
                                                                        <div className="more-btn">
                                                                          <div className="nav-item dropdown account-dropdown">
                                                                            <Link
                                                                              to={
                                                                                ""
                                                                              }
                                                                              className="nav-link dropdown-toggle"
                                                                              href="#"
                                                                              id="navbarDropdown"
                                                                              role="button"
                                                                              data-toggle="dropdown"
                                                                              aria-haspopup="true"
                                                                              aria-expanded="false"
                                                                            >
                                                                              <MoreIcon />
                                                                            </Link>
                                                                            <div
                                                                              className="dropdown-menu dropdown-menu-right"
                                                                              aria-labelledby="navbarDropdown"
                                                                            >
                                                                              <Link
                                                                                className="dropdown-item"
                                                                                to={
                                                                                  "/"
                                                                                }
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.preventDefault();
                                                                                  DeleteComment(
                                                                                    it?.id,
                                                                                    item?.id,
                                                                                    item?.type,
                                                                                    true,
                                                                                    index
                                                                                  );
                                                                                }}
                                                                              >
                                                                                Delete
                                                                              </Link>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <div className="more-btn">
                                                                          <div className="nav-item dropdown account-dropdown">
                                                                            <Link
                                                                              to={
                                                                                ""
                                                                              }
                                                                              className="nav-link dropdown-toggle"
                                                                              href="#"
                                                                              id="navbarDropdown"
                                                                              role="button"
                                                                              data-toggle="dropdown"
                                                                              aria-haspopup="true"
                                                                              aria-expanded="false"
                                                                            >
                                                                              <MoreIcon />
                                                                            </Link>
                                                                            <div
                                                                              className="dropdown-menu dropdown-menu-right"
                                                                              aria-labelledby="navbarDropdown"
                                                                            >
                                                                              <Link
                                                                                className="dropdown-item"
                                                                                to={
                                                                                  "/"
                                                                                }
                                                                                onClick={(
                                                                                  e
                                                                                ) => {
                                                                                  e.preventDefault();

                                                                                  sessionStorage.setItem(
                                                                                    "reportid",
                                                                                    item?.id
                                                                                  );
                                                                                  sessionStorage.setItem(
                                                                                    "comid",
                                                                                    it?.id
                                                                                  );
                                                                                  sessionStorage.setItem(
                                                                                    "type",
                                                                                    item?.type
                                                                                  );
                                                                                  sessionStorage.setItem(
                                                                                    "parent_id",
                                                                                    CurrentComment?.parent_id
                                                                                  );

                                                                                  // ReportComment(item?.id,com?.id,item?.type )
                                                                                }}
                                                                                data-toggle="modal"
                                                                                data-target="#ReportModal"
                                                                              >
                                                                                Report
                                                                              </Link>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                  <div className="comment-text">
                                                                    <p>
                                                                      {
                                                                        it?.comment
                                                                      }
                                                                    </p>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="comment-actions">
                                                                <span
                                                                  className="comment-actions--button reaction"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    if (
                                                                      user?.verified_at ===
                                                                        null &&
                                                                      user?.is_seller ===
                                                                        1
                                                                    ) {
                                                                      handleShowModal();
                                                                    } else {
                                                                      if (
                                                                        it
                                                                          ?.interactions
                                                                          ?.is_like >
                                                                        0
                                                                      ) {
                                                                        reactionChild(
                                                                          item?.id,
                                                                          "product",
                                                                          it?.id,
                                                                          "unlike",
                                                                          ig,
                                                                          "child"
                                                                        );
                                                                      } else {
                                                                        reactionChild(
                                                                          item?.id,
                                                                          "product",
                                                                          it?.id,
                                                                          "",
                                                                          ig,
                                                                          "child"
                                                                        );
                                                                      }
                                                                    }
                                                                  }}
                                                                >
                                                                  <span
                                                                    className={
                                                                      it
                                                                        ?.interactions
                                                                        ?.is_like >
                                                                      0
                                                                        ? "icon-filld"
                                                                        : ""
                                                                    }
                                                                  >
                                                                    <LikeIconFilled
                                                                      style={{
                                                                        width:
                                                                          "12px",
                                                                        height:
                                                                          "12px",
                                                                      }}
                                                                    />
                                                                  </span>
                                                                  <span className="comment-actions--text">
                                                                    Like
                                                                  </span>
                                                                </span>
                                                                {it
                                                                  ?.interactions
                                                                  ?.reactions >
                                                                  0 && (
                                                                  <span className="pr-3">
                                                                    {it
                                                                      ?.interactions
                                                                      ?.reactions >
                                                                    0
                                                                      ? com
                                                                          ?.interactions
                                                                          ?.reactions +
                                                                        " Like"
                                                                      : ""}
                                                                  </span>
                                                                )}

                                                                {user?.id ===
                                                                item?.user
                                                                  ?.id ? (
                                                                  <>
                                                                    <span
                                                                      className="comment-actions--button reaction"
                                                                      onClick={(
                                                                        e
                                                                      ) => {
                                                                        e.preventDefault();
                                                                        setReply(
                                                                          (
                                                                            p
                                                                          ) => ({
                                                                            ...p,
                                                                            CurrentReply:
                                                                              it?.id,
                                                                          })
                                                                        );
                                                                        ReplyListing(
                                                                          item?.type,
                                                                          item?.id,
                                                                          it?.id
                                                                        );
                                                                      }}
                                                                    >
                                                                      <span
                                                                        className={
                                                                          "icon-filld"
                                                                        }
                                                                      >
                                                                        <CommentIconFilled
                                                                          style={{
                                                                            height:
                                                                              "12px",
                                                                            width:
                                                                              "12px",
                                                                          }}
                                                                        />
                                                                      </span>
                                                                      <span className="comment-actions--text">
                                                                        {user?.name !==
                                                                        replyUser[0]
                                                                          ?.name
                                                                          ? "View Reply"
                                                                          : "Reply"}
                                                                      </span>
                                                                    </span>
                                                                  </>
                                                                ) : it
                                                                    ?.interactions
                                                                    ?.child_comments >
                                                                  0 ? (
                                                                  <span
                                                                    className="comment-actions--button reaction"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.preventDefault();
                                                                      setReply(
                                                                        (
                                                                          p
                                                                        ) => ({
                                                                          ...p,
                                                                          CurrentReply:
                                                                            it?.id,
                                                                        })
                                                                      );
                                                                      ReplyListing(
                                                                        item?.type,
                                                                        item?.id,
                                                                        it?.id
                                                                      );
                                                                    }}
                                                                  >
                                                                    <span
                                                                      className={
                                                                        "icon-filld"
                                                                      }
                                                                    >
                                                                      <CommentIconFilled
                                                                        style={{
                                                                          height:
                                                                            "12px",
                                                                          width:
                                                                            "12px",
                                                                        }}
                                                                      />
                                                                    </span>
                                                                    <span className="comment-actions--text">
                                                                      {user?.name !==
                                                                      replyUser[0]
                                                                        ?.name
                                                                        ? "View Reply"
                                                                        : "Reply"}
                                                                    </span>
                                                                  </span>
                                                                ) : (
                                                                  ""
                                                                )}

                                                                {it
                                                                  ?.interactions
                                                                  ?.child_comments >
                                                                0 ? (
                                                                  <span>
                                                                    {it
                                                                      ?.interactions
                                                                      ?.child_comments ===
                                                                    1
                                                                      ? it
                                                                          ?.interactions
                                                                          ?.child_comments +
                                                                        " Reply "
                                                                      : it
                                                                          ?.interactions
                                                                          ?.child_comments +
                                                                        " Replies"}
                                                                  </span>
                                                                ) : (
                                                                  ""
                                                                )}

                                                                {it?.id ===
                                                                  Reply?.CurrentReply &&
                                                                  Reply
                                                                    ?.ReplyData
                                                                    .length >
                                                                    0 &&
                                                                  Reply?.ReplyData.map(
                                                                    (
                                                                      reply,
                                                                      reIndex
                                                                    ) => {
                                                                      return (
                                                                        <>
                                                                          <div className="posts-comments ml-5 ">
                                                                            <div className="comment-item">
                                                                              <div className="user-profile">
                                                                                <Link
                                                                                  to={`/edit-profile-seller/${user.slug}`}
                                                                                >
                                                                                  <div className="user-profile-image">
                                                                                    <img
                                                                                      alt=""
                                                                                      src={
                                                                                        reply
                                                                                          ?.user
                                                                                          ?.profile_image
                                                                                      }
                                                                                      className="profile-pic"
                                                                                    />
                                                                                  </div>
                                                                                </Link>
                                                                                <div className="comment-content">
                                                                                  <div className="comment-meta">
                                                                                    <div className="user-profile-content">
                                                                                      <Link
                                                                                        to={`/edit-profile-seller/${reply?.user.slug}`}
                                                                                      >
                                                                                        <div className="user-profile-name">
                                                                                          <h6>
                                                                                            {
                                                                                              user?.name
                                                                                            }
                                                                                            <span>
                                                                                              (Profile
                                                                                              ID:{" "}
                                                                                              {
                                                                                                reply
                                                                                                  ?.user
                                                                                                  ?.id
                                                                                              }

                                                                                              )
                                                                                            </span>
                                                                                            <span>
                                                                                              (
                                                                                              {reply
                                                                                                ?.user
                                                                                                ?.is_buyer ===
                                                                                              0
                                                                                                ? "Seller"
                                                                                                : "Buyer"}

                                                                                              )
                                                                                            </span>
                                                                                          </h6>
                                                                                        </div>
                                                                                      </Link>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="comment-text">
                                                                                    <p>
                                                                                      {
                                                                                        reply?.comment
                                                                                      }
                                                                                    </p>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </>
                                                                      );
                                                                    }
                                                                  )}

                                                                {it?.id ===
                                                                  Reply?.CurrentReply &&
                                                                  item?.user
                                                                    ?.id ===
                                                                    user?.id && (
                                                                    <>
                                                                      <div className="add-comments">
                                                                        <form>
                                                                          <div className="add-comments--form">
                                                                            <div className="profile-image">
                                                                              <Link
                                                                                to={`/edit-profile-seller/${user.slug}`}
                                                                              >
                                                                                <img
                                                                                  src={
                                                                                    user?.profile_image
                                                                                  }
                                                                                  alt=""
                                                                                  className="profile-pic"
                                                                                />
                                                                              </Link>
                                                                            </div>
                                                                            <div className="comment-form">
                                                                              <div className="form-field">
                                                                                <input
                                                                                  type="text"
                                                                                  name="cname"
                                                                                  id="cname"
                                                                                  className="form-input"
                                                                                  placeholder="Add a Reply"
                                                                                  value={
                                                                                    Reply?.comment
                                                                                  }
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    setReply(
                                                                                      (
                                                                                        p
                                                                                      ) => ({
                                                                                        ...p,
                                                                                        comment:
                                                                                          e
                                                                                            .target
                                                                                            .value,
                                                                                      })
                                                                                    );
                                                                                    setComment(
                                                                                      (
                                                                                        p
                                                                                      ) => ({
                                                                                        ...p,
                                                                                        parent_id:
                                                                                          it?.id,
                                                                                      })
                                                                                    );
                                                                                  }}
                                                                                />
                                                                                <button
                                                                                  className="button button-primary"
                                                                                  onClick={(
                                                                                    e
                                                                                  ) => {
                                                                                    e.preventDefault();

                                                                                    if (
                                                                                      user?.verified_at ===
                                                                                        null &&
                                                                                      user?.is_seller ===
                                                                                        1
                                                                                    ) {
                                                                                      handleShowModal();
                                                                                    } else {
                                                                                      if (
                                                                                        Reply?.comment ===
                                                                                        ""
                                                                                      ) {
                                                                                      } else {
                                                                                        ReplyToMyPost(
                                                                                          item?.id,
                                                                                          "product",
                                                                                          it?.id
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  }}
                                                                                >
                                                                                  Post
                                                                                </button>
                                                                              </div>
                                                                              <div className="tags">
                                                                                {item?.type ===
                                                                                  "product" &&
                                                                                  ProductCommentTag?.items.map(
                                                                                    (
                                                                                      tag,
                                                                                      tagI
                                                                                    ) => {
                                                                                      return (
                                                                                        <>
                                                                                          <span
                                                                                            className="tag"
                                                                                            onClick={(
                                                                                              e
                                                                                            ) => {
                                                                                              e.preventDefault();
                                                                                              setReply(
                                                                                                (
                                                                                                  p
                                                                                                ) => ({
                                                                                                  ...p,
                                                                                                  comment:
                                                                                                    tag?.title,
                                                                                                })
                                                                                              );
                                                                                              setReply(
                                                                                                (
                                                                                                  p
                                                                                                ) => ({
                                                                                                  ...p,
                                                                                                  parent_id:
                                                                                                    item?.id,
                                                                                                })
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            {
                                                                                              tag?.title
                                                                                            }
                                                                                          </span>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                                {item?.type ===
                                                                                  "post_to_buy" &&
                                                                                  PostBuyComTag?.items.map(
                                                                                    (
                                                                                      tag,
                                                                                      tagI
                                                                                    ) => {
                                                                                      return (
                                                                                        <>
                                                                                          <span
                                                                                            className="tag"
                                                                                            onClick={(
                                                                                              e
                                                                                            ) => {
                                                                                              e.preventDefault();
                                                                                              setReply(
                                                                                                (
                                                                                                  p
                                                                                                ) => ({
                                                                                                  ...p,
                                                                                                  comment:
                                                                                                    tag?.title,
                                                                                                })
                                                                                              );
                                                                                              setReply(
                                                                                                (
                                                                                                  p
                                                                                                ) => ({
                                                                                                  ...p,
                                                                                                  parent_id:
                                                                                                    item?.id,
                                                                                                })
                                                                                              );
                                                                                            }}
                                                                                          >
                                                                                            {
                                                                                              tag?.title
                                                                                            }
                                                                                          </span>
                                                                                        </>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </form>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}

                                                {com?.id ===
                                                CurrentComment?.parent_id ? (
                                                  <>
                                                    <div className="add-comments ml-5">
                                                      <form>
                                                        <div className="add-comments--form">
                                                          <Link
                                                            to={`/edit-profile-seller/${user.slug}`}
                                                          >
                                                            <div className="profile-image">
                                                              <img
                                                                src={
                                                                  user?.profile_image
                                                                }
                                                                alt=""
                                                                className="profile-pic"
                                                              />
                                                            </div>
                                                          </Link>
                                                          <div className="comment-form">
                                                            <div className="form-field">
                                                              <input
                                                                type="text"
                                                                name="cname"
                                                                id="cname"
                                                                className="form-input"
                                                                placeholder="Add a Comment"
                                                                value={
                                                                  CommentV?.parent_id ===
                                                                  com?.id
                                                                    ? CommentV?.child_Comment
                                                                    : ""
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setComment(
                                                                    (p) => ({
                                                                      ...p,
                                                                      child_Comment:
                                                                        e.target
                                                                          .value,
                                                                    })
                                                                  );
                                                                  setComment(
                                                                    (p) => ({
                                                                      ...p,
                                                                      comment_Id:
                                                                        item?.id,
                                                                    })
                                                                  );
                                                                }}
                                                              />
                                                              <button
                                                                className="button button-primary"
                                                                onClick={(
                                                                  e
                                                                ) => {
                                                                  e.preventDefault();

                                                                  if (
                                                                    user?.verified_at ===
                                                                      null &&
                                                                    user?.is_seller ===
                                                                      1
                                                                  ) {
                                                                    handleShowModal();
                                                                  } else {
                                                                    if (
                                                                      CommentV?.child_Comment ===
                                                                      ""
                                                                    ) {
                                                                    } else {
                                                                      Comment(
                                                                        item?.id,
                                                                        "product",
                                                                        com?.id
                                                                      );
                                                                    }
                                                                  }
                                                                }}
                                                              >
                                                                Post
                                                              </button>
                                                            </div>
                                                            <div className="tags">
                                                              {PostBuyComTag?.items.map(
                                                                (tag, tagI) => {
                                                                  return (
                                                                    <>
                                                                      <span
                                                                        className="tag"
                                                                        onClick={(
                                                                          e
                                                                        ) => {
                                                                          e.preventDefault();
                                                                          setComment(
                                                                            (
                                                                              p
                                                                            ) => ({
                                                                              ...p,
                                                                              child_Comment:
                                                                                tag?.title,
                                                                            })
                                                                          );
                                                                          setComment(
                                                                            (
                                                                              p
                                                                            ) => ({
                                                                              ...p,
                                                                              comment_Id:
                                                                                item?.id,
                                                                            })
                                                                          );
                                                                        }}
                                                                      >
                                                                        {
                                                                          tag?.title
                                                                        }
                                                                      </span>
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </form>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                      {CurrentComment?.has_more === true ? (
                                        <div className="posts-comments-footer">
                                          <span
                                            href=""
                                            className="seemore-link"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setCurrentComment((p) => ({
                                                ...p,
                                                page: CurrentComment.page + 1,
                                              }));
                                            }}
                                          >
                                            Load more comments <CaretDownIcon />
                                          </span>
                                        </div>
                                      ) : (
                                        " "
                                      )}{" "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
            {HomeDatas?.suggested_users ? (
              <>
                <RightSideBar Suggested={HomeDatas?.suggested_users} />
              </>
            ) : (
              <Skeleton height={500} />
            )}
          </div>
        </div>
      </div>
      <PostSocialModal
        socialData={socialdata}
        data={data?.dataa?.data}
        UpdateTrue={UpdateTrue}
      />
      <SellerModal />
      <VerifyModal />
      <VerifyGSTModal />
      <SendQuote productDetail={productDetail} />
      <NegotiationModal productDetail={productDetail} buyerRole={roleData} />
      <AcceptDeal productDetail={productDetail} buyerRole={roleData} />
      <RecievedCard
        showModal={showRecievedCardModal}
        selectedSocialPost={selectedSocialPost}
        handleCloseModal={handleCloseRecievedCardModal}
        removeCarditem={removeCarditem}
      />
      <Modal
        size="lg"
        show={showModal}
        onHide={handleCloseModal}
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          height: "fit-content",
        }}
      >
        <Modal.Body>
          <div className="">
            <div className="alert alert--highlight text-center">
              <p>
                For authenticate users we take the correct company details and
                payment details.
              </p>
            </div>
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="img-block">
                  <img src={GSTImage} alt="" />
                </div>

                <Link
                  ref={gstRef}
                  className="button button-primary"
                  to={"/settings"}
                  state={{ gst: "true", PreFilled: "true" }}
                >
                  Verify GST Number
                </Link>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div className="img-block">
                  <img src={BankImage} alt="" />
                </div>
                <Link
                  className="button button-primary"
                  to={"/settings"}
                  state={{ pay: "true", PreFilled: "true" }}
                >
                  Verify Bank Details
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={ImageModal}
        onHide={handleCloseModalImage}
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          height: "100vh",
        }}
        centered
      >
        <Modal.Body style={{ "text-align": "end" }}>
          <Button className="modal-close mb-1" onClick={handleCloseModalImage}>
            <CloseIcon />
          </Button>

          <div className="container">
            <Slider {...settingsPost} arrows={true}>
              {timeline?.items[ImageModalId]?.media.map((imagess) => (
                <img
                  src={imagess.file}
                  className=""
                  alt="productImage"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                />
              ))}
            </Slider>
          </div>
        </Modal.Body>
      </Modal>

      <ReportModal />

      <Modal show={showModalCred} onHide={handleCloseModalCred}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Title>
            <h3>Credit Policy Data</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="policy-info">
            <form>
              <div className="info-box row justify-content-center">
                <div className="col-lg-1"></div>
                <div className="info-item col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Nos of Advance Transactions for Credit</label>

                  <span>{CredPolicyData?.advance_transaction}</span>
                </div>
                <div className="col-lg-3"></div>
                <div className="info-item  col-lg-4 col-md-6 col-sm-12 col-12 ">
                  <label>Credit Period (Days)</label>

                  <span className="info-days">
                    {CredPolicyData?.credit_period}
                  </span>
                </div>

                <div className="col-lg-1"></div>

                <div className="info-item col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Interest Rate</label>

                  <span className="info-per">
                    {CredPolicyData?.delay_interest}
                  </span>
                </div>
                <div className="col-lg-3"></div>
                <div className="info-item col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Credit Period Starts from</label>

                  <span className="info-item">
                    {CredPolicyData?.credit_period_start}
                  </span>
                </div>
                <div className="col-lg-1"></div>
                <div className="info-item col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Interest Period</label>

                  <span>{CredPolicyData?.interest_period}</span>
                </div>
                <div className="col-lg-3"></div>

                <div className="info-item  col-lg-4 col-md-6 col-sm-12 col-12">
                  <label>Other Terms</label>

                  <span>{CredPolicyData?.other_terms}</span>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalCred}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal centered size="lg" show={showModalCard} onHide={handleclosecard}>
        <Modal.Header>
          <Modal.Title className="w-100 d-flex justify-content-between">
            <h6 className="mb-0">Send Card</h6>
            <button
              className="button button-primary button-connect"
              style={{
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "10px 20px",
              }}
              disabled={!SelectedCard && sendCard === true}
              onClick={(e) => {
                e.preventDefault();
                sendSocialCard();
              }}
            >
              {" "}
              {sendCard === true ? "Sending" : "Submit Card"}{" "}
            </button>
          </Modal.Title>
        </Modal.Header>

        <ModalBody>
          <div className="row">
            {socialCard?.items &&
              socialCard?.items.length > 0 &&
              socialCard?.items.map((item, index) => {
                let result = item?.html.replace("{{NAME}}", cardSelectedUser);
                return (
                  <>
                    <div className="col-sm-4 mb-4">
                      <label class="block-check send-card-item">
                        <div
                          id={`card-container${index}`}
                          dangerouslySetInnerHTML={{ __html: result }}
                        ></div>
                        <input
                          type="radio"
                          name="test"
                          id="re"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedCard(result);
                              if (index) {
                                setsocialCardIndex(index);
                              }
                            } else {
                              setSelectedCard("");
                              if (index) {
                                setsocialCardIndex(0);
                              }
                            }
                          }}
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </>
                );
              })}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Home;
