import { OverlayTrigger, Tooltip } from "react-bootstrap";
export const isEmoji = (value) => {
  const emojiRegex =
    /[\u231A\u231B\u23E9-\u23EF\u23F0\u23F3\u25FD-\u25FE\u2600-\u27BF\u2B05-\u2B07\u2B1B\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299\uD83C\uD000-\uDFFF\uD83D\uDC00-\uDFFF\uD83E\uDD00-\uDDFF]/g;
  return emojiRegex.test(value);
};

export function CustomTooltip({ title, children, placement = "right" }) {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={<Tooltip id={`tooltip-${placement}`}>{title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}
