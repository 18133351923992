import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import GSTImage from "../assets/images/gst.png";
import BankImage from "../assets/images/bank.png";

const VerifyModal = ({ gstRef, showModal, handleCloseModal }) => {
  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={handleCloseModal}
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        height: "fit-content",
      }}
    >
      <Modal.Body>
        <div className="">
          <div className="alert alert--highlight text-center">
            <p>
              Please verify your GST and Bank Details to authenticate your account!
            </p>
          </div>
          <div className="row ">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <div className="img-block">
                <img alt="" src={GSTImage} />
              </div>

              <Link
                ref={gstRef}
                className="button button-primary"
                to={"/settings"}
                state={{ gst: "true", PreFilled: "true" }}
              >
                Verify GST Number
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <div className="img-block">
                <img alt="" src={BankImage} />
              </div>
              <Link
                className="button button-primary"
                to={"/settings"}
                state={{ pay: "true", PreFilled: "true" }}
              >
                Verify Bank Details
              </Link>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyModal;
